import React from 'react';

import AdminPanelBlock from '../../features/auth/components/AdminPanelBlock';
import SiteBlock from '../../features/auth/components/SiteBlock';

const AuthPage: React.FC = () => {
  return (
    <div className="grid grid-cols-2 items-stretch gap-[24px] h-full">
      <AdminPanelBlock />
      <SiteBlock />
    </div>
  );
};

export default AuthPage;
