import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const PhoneIcon: FC<ISvgProps> = ({
  height = 19,
  width = 18,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1389 17.0158L11.1476 17.0219C11.8953 17.4979 12.783 17.7047 13.6641 17.6079C14.5452 17.5111 15.3669 17.1167 15.9935 16.4897L16.5377 15.9454C16.6584 15.8248 16.7541 15.6817 16.8193 15.5242C16.8846 15.3666 16.9182 15.1978 16.9182 15.0273C16.9182 14.8567 16.8846 14.6879 16.8193 14.5303C16.7541 14.3728 16.6584 14.2297 16.5377 14.1091L14.242 11.8151C14.1215 11.6945 13.9783 11.5988 13.8208 11.5336C13.6633 11.4683 13.4944 11.4347 13.3239 11.4347C13.1534 11.4347 12.9845 11.4683 12.827 11.5336C12.6694 11.5988 12.5263 11.6945 12.4058 11.8151C12.1624 12.0585 11.8323 12.1952 11.4881 12.1952C11.1439 12.1952 10.8138 12.0585 10.5704 11.8151L6.89964 8.14352C6.6563 7.9001 6.5196 7.57001 6.5196 7.22583C6.5196 6.88164 6.6563 6.55155 6.89964 6.30814C7.02026 6.1876 7.11594 6.04447 7.18122 5.88693C7.24651 5.7294 7.28011 5.56054 7.28011 5.39002C7.28011 5.21949 7.24651 5.05064 7.18122 4.8931C7.11594 4.73557 7.02026 4.59244 6.89964 4.4719L4.60477 2.17789C4.36135 1.93455 4.03126 1.79785 3.68708 1.79785C3.34289 1.79785 3.0128 1.93455 2.76939 2.17789L2.22423 2.72219C1.59737 3.34884 1.20306 4.17061 1.10646 5.0517C1.00985 5.93279 1.21673 6.82047 1.69291 7.56807L1.6981 7.57672C4.21293 11.2975 7.41769 14.5017 11.1389 17.0158V17.0158Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
