import React from 'react';

interface ProfileTipProps {
  icon: React.ReactNode;
  text: string;
}

const ProfileTip: React.FC<ProfileTipProps> = ({ icon, text }) => {
  return (
    <div className="flex flex-col gap-[10px] p-[15px] border border-[#D9D9D9] rounded-[15px]">
      <div>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default ProfileTip;
