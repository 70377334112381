import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  allowedLanguages,
  allowedLanguagesWithLabels,
} from '../../constants/language';

import DropdownSimple from '../Dropdown/DropdownSimple';

import { ReactComponent as GlobusIcon } from '../../assets/icons/globusIcon.svg';
import { useRef } from 'react';

function replaceLanguage(
  url: string,
  newLang: string,
  allowedLanguages: string[],
): string {
  const langRegex = new RegExp(`^/(${allowedLanguages.join('|')})(/|$)`);
  return url.replace(langRegex, `/${newLang}/`);
}

const LangSwitcher: React.FC<{ isBurger?: boolean }> = ({
  isBurger = false,
}) => {
  const { lang } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const dropdownRef = useRef<any>(null);

  return (
    <DropdownSimple
      ref={dropdownRef}
      title={
        <GlobusIcon className={`${isBurger ? 'text-heading' : 'text-white'}`} />
      }
      menuClass={isBurger ? 'left-0' : 'left-1/2 transform -translate-x-1/2'}
    >
      <div className="flex flex-col gap-[15px] px-5 py-2.5">
        {allowedLanguagesWithLabels.map(({ label, language }) => {
          const newPathname = replaceLanguage(
            pathname,
            language,
            allowedLanguages,
          );
          return lang === language ? (
            <div key={label} className="text-[12px] text-[#006CFB]">
              {t(label)}
            </div>
          ) : (
            <Link
              key={label}
              to={newPathname}
              onClick={dropdownRef.current?.closeDropdown}
            >
              <div className="text-[12px] text-[#666666]">{t(label)}</div>
            </Link>
          );
        })}
      </div>
    </DropdownSimple>
  );
};

export default LangSwitcher;
