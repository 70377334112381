import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ComplaintIcon: FC<ISvgProps> = ({
  height = 24,
  width = 20,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15.0002C9.73479 15.0002 9.48043 14.8949 9.2929 14.7073C9.10536 14.5198 9 14.2655 9 14.0002V6.00024C9 5.73502 9.10536 5.48067 9.2929 5.29313C9.48043 5.1056 9.73479 5.00024 10 5.00024C10.2652 5.00024 10.5196 5.1056 10.7071 5.29313C10.8946 5.48067 11 5.73502 11 6.00024V14.0002C11 14.2655 10.8946 14.5198 10.7071 14.7073C10.5196 14.8949 10.2652 15.0002 10 15.0002ZM10.793 23.7602C12.953 22.8922 20 19.4792 20 12.0432V6.87224C20.0011 5.82125 19.6706 4.79671 19.0554 3.94455C18.4403 3.0924 17.5719 2.45608 16.574 2.12624L10.315 0.0512379C10.1107 -0.0177303 9.88934 -0.0177303 9.685 0.0512379L3.426 2.12624C2.42811 2.45608 1.55975 3.0924 0.944604 3.94455C0.329456 4.79671 -0.00110546 5.82125 2.7775e-06 6.87224V12.0432C2.7775e-06 18.6052 7.005 22.6192 9.153 23.6932C9.42468 23.8229 9.70836 23.9257 10 24.0002C10.2712 23.9448 10.5366 23.8645 10.793 23.7602ZM15.944 4.02424C16.5425 4.22265 17.0633 4.60462 17.4324 5.11587C17.8015 5.62713 18.0001 6.24168 18 6.87224V12.0432C18 18.2262 11.913 21.1542 10.047 21.9042C8.159 20.9602 2 17.4592 2 12.0432V6.87224C1.99992 6.24168 2.19852 5.62713 2.56761 5.11587C2.93669 4.60462 3.45748 4.22265 4.056 4.02424L10 2.05424L15.944 4.02424ZM10 17.0002C9.80222 17.0002 9.60888 17.0589 9.44443 17.1688C9.27998 17.2787 9.15181 17.4348 9.07612 17.6176C9.00044 17.8003 8.98063 18.0013 9.01922 18.1953C9.0578 18.3893 9.15304 18.5675 9.2929 18.7073C9.43275 18.8472 9.61093 18.9424 9.80491 18.981C9.99889 19.0196 10.2 18.9998 10.3827 18.9241C10.5654 18.8484 10.7216 18.7203 10.8315 18.5558C10.9414 18.3914 11 18.198 11 18.0002C11 17.735 10.8946 17.4807 10.7071 17.2931C10.5196 17.1056 10.2652 17.0002 10 17.0002Z"
        fill={color}
      />
    </svg>
  );
};

export default ComplaintIcon;
