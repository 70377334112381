import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../../common/components/Container/Container';

import FavoritePropertiesList from '../../features/favorites/components/FavoritePropertiesList';
import PropertySearchesList from '../../features/favorites/components/PropertySearchesList';

const SelectorIcon = ({
  title,
  onClick,
  isActive,
}: {
  title: string;
  onClick: () => void;
  isActive?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={`text-400 text-center pb-[38px] border-b-4 cursor-pointer ${isActive ? 'border-primary' : 'border-transparent'}`}
    >
      {title}
    </div>
  );
};

const FavoritesPage = () => {
  const [subpage, setSubpage] = useState('properties');

  const { t } = useTranslation();

  const handleClick = (sp: string) => {
    if (sp === subpage) return;

    setSubpage(sp);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container>
        <div className="flex flex-col items-center gap-[45px] py-[50px]">
          <div className="flex flex-col items-center gap-[32px]">
            <h2 className="text-display-3 text-heading fade-in">
              {t('favorites_title')}
            </h2>
            <div className="max-w-[550px] text-center">
              {t('favorites_description')}
            </div>
          </div>
          <div className="grid grid-cols-2 w-[566px] border-b border-[#D9D9D9]">
            <SelectorIcon
              title={t('properties')}
              onClick={() => handleClick('properties')}
              isActive={subpage === 'properties'}
            />
            <SelectorIcon
              title={t('searches')}
              onClick={() => handleClick('searches')}
              isActive={subpage === 'searches'}
            />
          </div>
          {subpage === 'properties' ? (
            <FavoritePropertiesList />
          ) : (
            <PropertySearchesList />
          )}
        </div>
      </Container>
    </div>
  );
};

export default FavoritesPage;
