import React, { useEffect, useRef } from 'react';

import ImageWithFallback from '../../../common/components/Image/ImageWithFallback';

import CheckIcon from '../../../common/assets/icons/CheckIcon';

interface ChatMessageProps {
  avatar: any;
  name: string;
  body: string;
  createdAt: string;
  read: boolean;
  isOwner?: boolean;
  markRead: () => void;
}

const MESSAGE_READ_TIME = 3000;

const ChatMessage: React.FC<ChatMessageProps> = ({
  avatar,
  name,
  body,
  createdAt,
  read,
  isOwner,
  markRead,
}) => {
  const messageRef = useRef<HTMLDivElement | null>(null);

  const avatarElem = avatar?.url ? (
    <ImageWithFallback
      src={avatar.url}
      width={40}
      height={40}
      alt="user icon"
      className="h-[40px] w-[40px] rounded-full object-cover"
    />
  ) : (
    <div className="flex-center rounded-full w-[40px] h-[40px] bg-primary text-white">
      {name?.charAt(0)}
    </div>
  );

  const time = new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(createdAt));

  useEffect(() => {
    if (read || isOwner) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          const timer = setTimeout(() => {
            markRead();
          }, MESSAGE_READ_TIME);

          return () => clearTimeout(timer);
        }
      },
      { threshold: 1.0 },
    );

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, [read, markRead]);

  return isOwner ? (
    <div ref={messageRef} className="flex justify-end">
      <div className="relative px-[15px] pt-[15px] pb-[31px] rounded-[15px_15px_0_15px] bg-primary min-w-[80px]">
        <span className="text-body-smaller text-white">{body}</span>
        <div className="absolute bottom-[15px] right-[15px] flex items-center gap-[5px] text-[12px] leading-[1] text-white">
          {time}
          {read && <CheckIcon width={10} height={10} color="white" />}
        </div>
      </div>
    </div>
  ) : (
    <div ref={messageRef} className="flex justify-start items-end gap-[16px]">
      <div>{avatarElem}</div>
      <div
        className={`relative px-[15px] pt-[15px] pb-[31px] rounded-[15px_15px_15px_0] min-w-[80px] ${read ? 'bg-secondary' : 'bg-[#F2F8FF0D]'}`}
      >
        <span className="text-body-smaller">{body}</span>
        <div className="absolute bottom-[15px] right-[15px] text-[12px] leading-[1]">
          {time}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
