import { useQuery } from 'react-query';

import { getCategorySchemaById } from '../../api/services/category/requests';

import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';
import usePropertySearchStore from '../stores/usePropertySearchStore';

const useSelectedCategorySchema = () => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { propertyTypes } = usePropertySearchStore();

  const id = propertyTypes.entities[0]?.id;

  const { data: schema = {} as any } = useQuery(
    ['category_schema', id],
    async () => {
      setLoader(true);

      const data = await getCategorySchemaById(id);
      setLoader(false);
      return data;
    },
    {
      enabled: !!id,
      staleTime: Infinity,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const roomsOptions =
    schema.groups
      ?.find((g: { name: string }) => g.name === 'parameters')
      ?.fields?.find((f: { name: string }) => f.name === 'roomsCountFlat')
      ?.tags?.sort((o1: any, o2: any) => o1.order - o2.order) || [];

  const bedRoomsOptions =
    schema.groups
      ?.find((g: { name: string }) => g.name === 'parameters')
      ?.fields?.find((f: { name: string }) => f.name === 'roomsCountHouse')
      ?.tags.sort((o1: any, o2: any) => o1.order - o2.order) || [];

  return {
    schema,
    roomsOptions,
    bedRoomsOptions,
  };
};

export default useSelectedCategorySchema;
