import { useMemo, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getNamesByPath, getSubTree } from '../../utils/helpers';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import usePropertyCategories, {
  IConvertedCategory,
} from '../../hooks/usePropertyCategories';

import Dropdown from '../Dropdown/Dropdown';
import TabSwitch, { ITabOption } from '../Switch/TabSwitch';

import CheckIcon from '../../assets/icons/CheckIcon';

const PropertyTypeSelector = ({
  withTopType = false,
}: {
  withTopType?: boolean;
}) => {
  const { type } = useParams();

  const { t } = useTranslation();

  const dropdownRef = useRef<any>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const { propertyTypes, setPropertyTypes } = usePropertySearchStore();

  const { categories, flatCategories } = usePropertyCategories();

  const searchValueString = useMemo(() => {
    const pathNames = getNamesByPath(categories, propertyTypes.path);
    if (!pathNames?.length) return `${t('loading')}...`;

    const normalizedPath = propertyTypes.path[0] === 'rent' ? pathNames[1] : '';

    if (propertyTypes.entities?.length === 1) {
      const entityId = propertyTypes.entities[0].id;
      const entities =
        getSubTree(categories, propertyTypes.path)?.entities || [];
      const entityName =
        entities.find((ent) => ent.id === entityId)?.title || '';

      return normalizedPath ? `${normalizedPath}, ${entityName}` : entityName;
    }

    return normalizedPath;
  }, [categories, propertyTypes.path, propertyTypes.entities, t]);

  const handleRadioChange = useCallback(
    (level: number, selectedValue: string, path = [...propertyTypes.path]) => {
      path[level] = selectedValue;
      path.splice(level + 1);

      const currentSubTree = getSubTree(categories, path);

      const subTreeChildren = currentSubTree?.children;
      if (subTreeChildren?.length) {
        const firstChildKey = subTreeChildren[0].name;

        if (firstChildKey) {
          path.push(firstChildKey);
          handleRadioChange(level + 1, firstChildKey, path);
        }
      }

      const newSubTree = getSubTree(categories, path);

      const finalEntities = newSubTree?.entities || [];

      const newValue = {
        path: path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      setPropertyTypes(newValue);
    },
    [propertyTypes, categories, setPropertyTypes],
  );

  const handleCheckboxChange = useCallback(
    (entity: any) => {
      dropdownRef.current?.closeDropdown();
      if (
        propertyTypes.entities.find((ent) => ent.id === entity.id) &&
        propertyTypes.entities.length === 1
      )
        return;

      const pathString = propertyTypes.path.join('.');
      const isRightPath = entity?.name?.includes(pathString);
      const updatedPath = !isRightPath && entity?.name.split('.').slice(0, -1);
      const updatedEntities = [entity];
      const updatedValue = {
        ...propertyTypes,
        ...(updatedPath && {
          path: updatedPath,
        }),
        entities: updatedEntities,
      };

      setPropertyTypes(updatedValue);
    },
    [propertyTypes, setPropertyTypes],
  );

  const forceHardcodedPath = (code: string) => {
    const path =
      code === 'rent'
        ? ['rent', 'longTerm', 'residental']
        : ['sale', 'residental'];

    const currentSubTree = getSubTree(categories, path);

    const finalEntities = currentSubTree?.entities || [];
    const neededEntity = finalEntities.find((e: any) =>
      code === 'rent'
        ? e.name === 'rent.longTerm.residental.apartment'
        : e.name === 'sale.residental.apartment',
    );

    const updatedValue = {
      path,
      entities: neededEntity ? [neededEntity] : [],
    };

    if (JSON.stringify(propertyTypes.path) !== JSON.stringify(path)) {
      setPropertyTypes(updatedValue);
    }
  };

  const renderEntities = (entities: IConvertedCategory[]) => (
    <div className="flex flex-col gap-[20px]">
      {entities?.map((entity) => (
        <label
          key={entity.id}
          className="flex items-center justify-between w-full h-[25px] fade-in animation-fast"
        >
          <div className="">{entity.title}</div>
          <div className="relative flex-center w-[24px] h-[24px]">
            <input
              type="checkbox"
              className="appearance-none w-[24px] h-[24px] border-2 border-[#006CFB] rounded-full bg-transparent shrink-0 cursor-pointer checked:bg-[#006CFB]"
              checked={
                !!propertyTypes.entities.find((ent) => ent.id === entity.id)
              }
              onChange={() => handleCheckboxChange(entity)}
            />
            <CheckIcon
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              height={10}
              width={10}
              color="white"
              strokeWidth={2.5}
            />
          </div>
        </label>
      ))}
    </div>
  );
  const renderTree = () => {
    if (propertyTypes.path[1] === 'daily') {
      const entities = flatCategories.filter((c) =>
        c.name.startsWith('rent.daily'),
      );
      return renderEntities(entities);
    }

    if (propertyTypes.path[1] === 'longTerm') {
      const entitiesR = flatCategories.filter((c) =>
        c.name.startsWith('rent.longTerm.residental'),
      );
      const entitiesC = flatCategories.filter((c) =>
        c.name.startsWith('rent.longTerm.commercial'),
      );
      return (
        <>
          <div className="flex flex-col gap-[10px]">
            <div className="text-100 font-bold capitalize">
              {t('residential')}
            </div>
            {renderEntities(entitiesR)}
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="text-100 font-bold capitalize">
              {t('commercial')}
            </div>
            {renderEntities(entitiesC)}
          </div>
        </>
      );
    }

    if (propertyTypes.path[0] === 'sale') {
      const entitiesR = flatCategories.filter((c) =>
        c.name.startsWith('sale.residental'),
      );
      const entitiesC = flatCategories.filter((c) =>
        c.name.startsWith('sale.commercial'),
      );
      return (
        <>
          <div className="flex flex-col gap-[10px]">
            <div className="text-100 font-bold capitalize">
              {t('residential')}
            </div>
            {renderEntities(entitiesR)}
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className="text-100 font-bold capitalize">
              {t('commercial')}
            </div>
            {renderEntities(entitiesC)}
          </div>
        </>
      );
    }
  };

  const tabs: ITabOption[] = [
    { title: t('long-term'), action: () => handleRadioChange(1, 'longTerm') },
    { title: t('daily'), action: () => handleRadioChange(1, 'daily') },
  ];

  useEffect(() => {
    if (!propertyTypes.path.length && categories.length) {
      const path =
        !type || type === 'rent'
          ? ['rent', 'longTerm', 'residental']
          : ['sale', 'residental'];

      const currentSubTree = getSubTree(categories, path);

      const finalEntities = currentSubTree?.entities || [];

      const updatedValue = {
        path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      if (JSON.stringify(propertyTypes.path) !== JSON.stringify(path)) {
        setPropertyTypes(updatedValue);
      }
    }
  }, [categories, type, propertyTypes, setPropertyTypes]);

  return (
    <div
      className="cursor-pointer"
      ref={parentRef}
      onClick={() => dropdownRef.current?.toggleDropdown()}
    >
      <h5 className="text-heading-5 text-heading capitalize">
        {t('category')}
      </h5>
      <Dropdown
        ref={dropdownRef}
        className="w-[176px] h-[19px]"
        buttonClass="text-body-smaller text-body pl-0"
        menuClass="-ml-[25px] mt-[38px]"
        title={searchValueString}
        parentRef={parentRef}
      >
        <div className="flex flex-col gap-[25px] min-w-[350px] p-[25px]">
          {withTopType && (
            <div className="grid grid-cols-2 border-b border-[#D9D9D9]">
              <div
                onClick={() => forceHardcodedPath('rent')}
                className={`flex-center w-full h-[34px] pb-5 border-b-4 cursor-pointer ${propertyTypes.path[0] === 'rent' ? 'border-primary' : 'border-transparent opacity-60'}`}
              >
                {t('rent')}
              </div>
              <div
                onClick={() => forceHardcodedPath('sale')}
                className={`flex-center w-full h-[34px] pb-5 border-b-4 cursor-pointer ${propertyTypes.path[0] === 'sale' ? 'border-primary' : 'border-transparent opacity-60'}`}
              >
                {t('buy')}
              </div>
            </div>
          )}
          {propertyTypes.path[0] === 'rent' && <TabSwitch options={tabs} />}
          {renderTree()}
        </div>
      </Dropdown>
    </div>
  );
};

export default PropertyTypeSelector;
