import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';

export const postStatisticEventPropertyCall = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    `/statistic/event/property/${id}/call`,
  );
  return response.data.data;
};
