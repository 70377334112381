import React, { useRef } from 'react';

import { ReactComponent as CopyIcon } from '../../../../common/assets/icons/copyIcon.svg';

interface ILinkModalProps {
  link: string;
}

const LinkModal: React.FC<ILinkModalProps> = ({ link }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    navigator.clipboard
      .writeText(link)
      .then(() => {
        if (inputRef.current) {
          inputRef.current.select();
        }
      })
      .catch((err) => {
        console.error('Error: ', err);
      });
  };

  return (
    <div className="grid grid-cols-[1fr_auto] gap-2.5 w-[395px] p-[25px]">
      <input
        ref={inputRef}
        type="text"
        value={link}
        readOnly
        className="w-full h-[40px] px-[6px] outline-none border border-secondary rounded-[5px] text-[12px] text-[#5D5D5D] selection:bg-[#006CFB] selection:text-white"
      />
      <div className="flex-center">
        <button
          onClick={copyToClipboard}
          disabled={!link}
          className="flex-center w-[40px] h-[40px] bg-[#006CFB] rounded-[8px]"
        >
          <CopyIcon />
        </button>
      </div>
    </div>
  );
};

export default LinkModal;
