import { FC, ReactNode } from 'react';

import { capitalizeFirstLetter } from '../../../common/utils/helpers';

interface IPropertyWrapperProps {
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
}

const PropertyFieldWrapper: FC<IPropertyWrapperProps> = ({
  children,
  label,
  icon,
}) => {
  return (
    <div>
      <div className="flex items-center gap-[5px] mb-[16px]">
        <div className="flex-center w-[24px] h-[24px]">{icon}</div>
        <h4 className="text-heading-4 text-heading">
          {capitalizeFirstLetter(label)}
        </h4>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PropertyFieldWrapper;
