import { useRef, useCallback } from 'react';
import io, { Socket } from 'socket.io-client';

import { API_URL } from '../../../common/constants/env';

import { IMessage } from '../types/chat';

import useAuthStore from '../../../common/stores/useAuthStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

interface UseWebSocketChatReturn {
  connect: () => void;
  disconnect: () => void;
  sendMessage: (message: string) => void;
  handleMessageRead: (messageId: string) => void;
}

const useWebSocketChat = (): UseWebSocketChatReturn => {
  const socketRef = useRef<Socket | null>(null);

  const { roomId, markMessageRead } = useWebsocketChatStore();
  const { accessToken: token } = useAuthStore((state) => state);

  const connect = useCallback(() => {
    if (socketRef.current?.connected || !token) return;

    const socket = io(`${API_URL}/chat`, { auth: { token } });
    socketRef.current = socket;

    socket.on('connect_error', (err: any) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on('connect', () => {
      console.log('Connected');
      socket.emit('auth');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected');
    });

    socket.on('error', (error: IMessage) => {
      console.error('Error:', error);
    });

    socket.on('message', (payload: any) => {
      const { roomId, setMessages, updateUnreadMessages, setRefresh } =
        useWebsocketChatStore.getState();
      const messageRoomId = payload?.room?.id;

      if (messageRoomId === roomId) {
        setMessages([payload]);
      }

      const room = payload?.room;
      if (room) {
        updateUnreadMessages(room.id, room.unreadMessages);
      }

      setRefresh(true);
    });

    socket.on(
      'readMessage',
      (
        payload:
          | { messageId: string; readBy: string }[]
          | { messageId: string; readBy: string },
      ) => {
        const { messages, markMessageRead, setRefresh } =
          useWebsocketChatStore.getState();

        const payloadArray = Array.isArray(payload) ? payload : [payload];

        payloadArray.forEach((readMsg) => {
          const message = messages.find((msg) => msg.id === readMsg.messageId);

          if (message) {
            markMessageRead(readMsg.messageId);
          }
        });

        setRefresh(true);
      },
    );
  }, [token]);

  const disconnect = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      console.log('WebSocket disconnected');
    }
  }, []);

  const sendMessage = useCallback(
    (message: string) => {
      const socket = socketRef.current;
      if (socket && socket.connected && message) {
        socket.emit('message', { roomId, body: message });
      }
    },
    [socketRef, roomId],
  );

  const handleMessageRead = useCallback(
    (messageId: string) => {
      if (socketRef.current && messageId) {
        socketRef.current.emit('readMessages', {
          messageIds: [messageId],
          roomId,
        });
        markMessageRead(messageId);
      }
    },
    [socketRef, markMessageRead, roomId],
  );

  return {
    connect,
    disconnect,
    sendMessage,
    handleMessageRead,
  };
};

export default useWebSocketChat;
