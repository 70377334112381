import { useParams } from 'react-router-dom';

import {
  addPriceDelimiters,
  capitalizeFirstLetter,
} from '../../../common/utils/helpers';

interface IPropertyFieldsColumnProps {
  items?:
    | {
        label?: string;
        value: any;
        unit?: string;
      }[]
    | null;
  columnCount?: number;
  valuePosition?: string;
}

const PropertyFieldsColumn = ({
  items,
  columnCount = 1,
  valuePosition = 'left',
}: IPropertyFieldsColumnProps) => {
  const { lang } = useParams();

  if (!items) return null;

  return (
    <div className={`grid grid-cols-${columnCount} gap-[16px]`}>
      {items
        ?.filter((f) => !!f.value)
        .map((field, index) => {
          const isIsoDate =
            typeof field.value === 'string' &&
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(field.value);
          const isMoney =
            typeof field.value === 'string' && field?.unit?.includes('Kč');

          const formattedValue =
            typeof field.value === 'object' && field.value !== null
              ? field.value[lang as string]
              : isIsoDate
                ? new Intl.DateTimeFormat('default', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(field.value))
                : isMoney
                  ? addPriceDelimiters(field.value)
                  : field.value;

          return (
            <div className="flex items-baseline gap-[8px]" key={index}>
              <div className="flex-1">{capitalizeFirstLetter(field.label)}</div>
              <div
                className={`flex-1 text-heading ${valuePosition === 'right' ? 'flex justify-end' : ''}`}
              >
                {formattedValue} {field.unit}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PropertyFieldsColumn;
