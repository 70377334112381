import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ShieldCheckIcon: FC<ISvgProps> = ({
  height = 24,
  width = 24,
  color = '#E53E3E',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_79_7573)">
        <path
          d="M18.581 2.14024L12.316 0.0512408C12.1109 -0.0170803 11.8891 -0.0170803 11.684 0.0512408L5.419 2.14024C4.42291 2.47112 3.55642 3.1075 2.94265 3.95895C2.32887 4.8104 1.99904 5.83363 2 6.88324V12.0002C2 19.5632 11.2 23.7402 11.594 23.9142C11.7218 23.971 11.8601 24.0004 12 24.0004C12.1399 24.0004 12.2782 23.971 12.406 23.9142C12.8 23.7402 22 19.5632 22 12.0002V6.88324C22.001 5.83363 21.6711 4.8104 21.0574 3.95895C20.4436 3.1075 19.5771 2.47112 18.581 2.14024ZM20 12.0002C20 17.4552 13.681 21.0332 12 21.8892C10.317 21.0362 4 17.4692 4 12.0002V6.88324C4.00006 6.25352 4.19828 5.63978 4.56657 5.12898C4.93486 4.61819 5.45455 4.23623 6.052 4.03724L12 2.05424L17.948 4.03724C18.5455 4.23623 19.0651 4.61819 19.4334 5.12898C19.8017 5.63978 19.9999 6.25352 20 6.88324V12.0002Z"
          fill={color}
        />
        <path
          d="M14.8284 12.4129L13.4142 10.9986L14.8284 9.58443C15.016 9.39689 15.1213 9.14254 15.1213 8.87732C15.1213 8.61211 15.016 8.35775 14.8284 8.17022C14.6409 7.98268 14.3865 7.87733 14.1213 7.87733C13.8561 7.87733 13.6017 7.98268 13.4142 8.17022L12 9.58443L10.5858 8.17022C10.3983 7.98268 10.1439 7.87733 9.87868 7.87733C9.61347 7.87733 9.35911 7.98268 9.17158 8.17022C8.98404 8.35775 8.87869 8.61211 8.87869 8.87732C8.87869 9.14254 8.98404 9.39689 9.17158 9.58443L10.5858 10.9986L9.17158 12.4129C8.98404 12.6004 8.87869 12.8547 8.87869 13.12C8.87869 13.3852 8.98404 13.6395 9.17158 13.8271C9.35911 14.0146 9.61347 14.12 9.87868 14.12C10.1439 14.12 10.3983 14.0146 10.5858 13.8271L12 12.4129L13.4142 13.8271C13.6017 14.0146 13.8561 14.12 14.1213 14.12C14.3865 14.12 14.6409 14.0146 14.8284 13.8271C15.016 13.6395 15.1213 13.3852 15.1213 13.12C15.1213 12.8547 15.016 12.6004 14.8284 12.4129Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_79_7573">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldCheckIcon;
