import i18next from 'i18next';

import {
  IPropertyAddress,
  IPropertyCategory,
  IPropertySearch,
} from '../types/dto/property';

interface ISubTreeItem {
  name: string;
  title?: string;
  children?: ISubTreeItem[];
  entities?: ISubTreeItem[];
  id?: string;
}

export const addPriceDelimiters = (price: number | string): string =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const getAddressString = (
  address?: IPropertyAddress,
): string | undefined => {
  if (!address) return undefined;
  return `${address?.street} ${address?.houseNumber}, ${address?.city}`.replaceAll(
    '  ',
    '',
  );
};

export const capitalizeFirstLetter = (str?: string): string =>
  str ? String(str).charAt(0).toUpperCase() + String(str).slice(1) : '';

export const getSubTree = (
  array: ISubTreeItem[],
  path: string[],
): ISubTreeItem | null => {
  let currentArray: ISubTreeItem[] = array;
  let lastSubTree: ISubTreeItem | null = null;

  for (const p of path) {
    const subTree = currentArray.find((item) => item.name === p);

    if (!subTree) return null;

    lastSubTree = subTree;

    currentArray = subTree.children || [];
  }

  return lastSubTree;
};

export const getNamesByPath = (
  array: ISubTreeItem[],
  path: string[],
): string[] | null => {
  let currentArray = array;
  const names: string[] = [];

  for (const p of path) {
    const subTree = currentArray.find((item) => item.name === p);

    if (!subTree) return null;

    if (subTree.title) {
      names.push(subTree.title);
    }

    currentArray = subTree.children || [];
  }

  return names;
};

export const formatPhoneNumber = (phone: string): string | null => {
  if (!phone) return null;
  const cleaned = phone.replace(/\s+/g, '');

  if (cleaned.startsWith('+420') || cleaned.startsWith('420')) {
    const prefix = cleaned.startsWith('+420') ? '+420' : '420';
    const number = cleaned.slice(prefix.length);

    const groups = number.match(/.{1,3}/g);

    return `${prefix} ${groups ? groups.join(' ') : ''}`.trim();
  }

  return phone;
};

export const generatePropertyDataString = (
  category: IPropertyCategory | undefined,
  fields: IPropertySearch['fields'],
): string => {
  const {
    roomsCountFlat,
    totalArea,
    floor,
    floorsBuilding,
    roomsCountHouse,
    houseArea,
    roomArea,
    parkingPlace,
    typeOfPremises,
  } = fields || {};

  const categoryNameArray = category?.name?.split('.');
  if (!categoryNameArray) return '';

  const categoryName = categoryNameArray[categoryNameArray.length - 1];

  switch (categoryName) {
    case 'apartment':
      return [
        roomsCountFlat?.label || '',
        totalArea ? `${totalArea} m²` : '',
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'house':
      return [
        roomsCountHouse?.label || '',
        houseArea ? `${houseArea} m²` : '',
        floorsBuilding
          ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'room':
      return [
        roomArea ? `${roomArea} m²` : '',
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'garage':
      return [
        ...(parkingPlace.map((p: any) => p.label || '') || ''),
        totalArea ? `${totalArea} m²` : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'office':
      return [floor ? `${floor} floor` : '', totalArea ? `${totalArea} m²` : '']
        .filter(Boolean)
        .join(', ');

    case 'retailSpace':
      return [typeOfPremises?.label || '', totalArea ? `${totalArea} m²` : '']
        .filter(Boolean)
        .join(', ');

    case 'building':
      return [
        totalArea ? `${totalArea} m²` : '',
        floorsBuilding
          ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'land':
    case 'warehouse':
    case 'production':
      return totalArea ? `${totalArea} m²` : '';

    default:
      return '';
  }
};

export const generatePropertyDataStringMapped = (
  category?: IPropertyCategory,
  mappedFields?: { [key: string]: any },
  lang?: string,
): string => {
  const {
    roomsCountFlat,
    totalArea,
    floor,
    floorsBuilding,
    roomsCountHouse,
    houseArea,
    roomArea,
    parkingPlace,
    typeOfPremises,
  } = mappedFields || {};

  const categoryNameArray = category?.name?.split('.');
  if (!categoryNameArray) return '';

  const categoryName = categoryNameArray[categoryNameArray.length - 1];

  switch (categoryName) {
    case 'apartment':
      return [
        roomsCountFlat?.label?.[lang as string] || '',
        totalArea ? `${totalArea} m²` : '',
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'house':
      return [
        roomsCountHouse?.label?.[lang as string] || '',
        houseArea ? `${houseArea} m²` : '',
        floorsBuilding
          ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'room':
      return [
        roomArea ? `${roomArea} m²` : '',
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'garage':
      return [
        ...(parkingPlace.map((p: any) => p.label?.[lang as string] || '') ||
          ''),
        totalArea ? `${totalArea} m²` : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'office':
      return [
        floor ? `${floor} ${i18next.t('floor')}` : '',
        totalArea ? `${totalArea} m²` : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'retailSpace':
      return [
        typeOfPremises?.label?.[lang as string] || '',
        totalArea ? `${totalArea} m²` : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'building':
      return [
        totalArea ? `${totalArea} m²` : '',
        floorsBuilding
          ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
          : '',
      ]
        .filter(Boolean)
        .join(', ');

    case 'land':
    case 'warehouse':
    case 'production':
      return totalArea ? `${totalArea} m²` : '';

    default:
      return '';
  }
};

export const generatePropertyDataObject = (
  category: IPropertyCategory | undefined,
  fields: IPropertySearch['fields'],
): { [key: string]: any } => {
  const {
    roomsCountFlat,
    totalArea,
    floor,
    floorsBuilding,
    roomsCountHouse,
    houseArea,
    roomArea,
    parkingPlace,
    typeOfPremises,
  } = fields || {};

  const categoryNameArray = category?.name?.split('.');
  if (!categoryNameArray) return {};

  const categoryName = categoryNameArray[categoryNameArray.length - 1];

  const propertyData = {} as any;

  switch (categoryName) {
    case 'apartment':
      propertyData['rooms'] = roomsCountFlat?.label || null;
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      propertyData['floors'] =
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : null;
      break;

    case 'house':
      propertyData['rooms'] = roomsCountHouse?.label || null;
      propertyData['area'] = houseArea ? `${houseArea} m²` : null;
      propertyData['floors'] = floorsBuilding
        ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
        : null;
      break;

    case 'room':
      propertyData['area'] = roomArea ? `${roomArea} m²` : null;
      propertyData['floors'] =
        floor && floorsBuilding
          ? `${floor}/${floorsBuilding} ${i18next.t('floor')}`
          : null;
      break;

    case 'garage':
      propertyData['parkingPlace'] =
        parkingPlace.map((p: any) => p.label) || [];
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      break;

    case 'office':
      propertyData['floors'] = floor ? `${floor} floor` : null;
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      break;

    case 'retailSpace':
      propertyData['typeOfPremises'] = typeOfPremises?.label || null;
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      break;

    case 'building':
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      propertyData['floors'] = floorsBuilding
        ? `${floorsBuilding} ${floorsBuilding > 1 ? i18next.t('floors') : i18next.t('floor')}`
        : null;
      break;

    case 'land':
    case 'warehouse':
    case 'production':
      propertyData['area'] = totalArea ? `${totalArea} m²` : null;
      break;

    default:
      return {};
  }

  return propertyData;
};
