import React, { useMemo, FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addPriceDelimiters,
  generatePropertyDataString,
  getAddressString,
} from '../../../common/utils/helpers';

import { EPriceType } from '../../../api/services/property/types';
import { IPropertySearch } from '../../../common/types/dto/property';

import FavoriteButton from '../../../common/components/FavoriteButton/FavoriteButton';

import PropertyMapCarousel from './PropertyMapCarousel';

import NoImageIcon from '../../../common/assets/icons/NoImageIcon';

interface IPropertyMapItemProps {
  item: IPropertySearch;
  refetch?: () => void;
  isLoggedIn?: boolean;
}

const PropertyMapItem: FC<IPropertyMapItemProps> = ({
  item: {
    id,
    mainImage,
    gallery = [],
    price,
    priceType,
    address,
    fields,
    category,
    isFavorite,
  },
  refetch,
  isLoggedIn = false,
}) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const addressString = getAddressString(address);

  const allImages = useMemo(() => {
    const collateralImages = gallery;

    return mainImage ? [...[mainImage], ...collateralImages] : collateralImages;
  }, [gallery, mainImage]);

  const meterPrice = Math.round(price / fields.totalArea);

  const dataString = generatePropertyDataString(category, fields);

  return (
    <Link className="cursor-pointer" to={`/${lang}/property/${id}`}>
      <div className="relative group w-[490px] h-[320px] mb-[15px] fade-in">
        {allImages && allImages.length > 0 ? (
          <PropertyMapCarousel items={allImages} />
        ) : (
          <div className="flex-center w-full h-full bg-[#F8F8F8] rounded-[15px]">
            <NoImageIcon />
          </div>
        )}
        {isLoggedIn && (
          <FavoriteButton id={id} isFavorite={isFavorite} refetch={refetch} />
        )}
      </div>
      <div className="fade-in">
        <div className="mb-[10px]">
          <h4 className="text-heading-4 text-primary mb-[5px]">{dataString}</h4>
          <div className="text-body-small text-[#9997A8]">{addressString}</div>
        </div>
        <div>
          <div className="text-heading-4 text-heading mb-[5px]">
            {addPriceDelimiters(price)} Kč
            {priceType === EPriceType.PerMonth
              ? `/${t('month')}`
              : priceType === EPriceType.PerDay
                ? `/${t('day')}`
                : ''}
          </div>
          <div className="text-body-smaller text-[#9997A8]">
            {addPriceDelimiters(meterPrice)} Kč/m2
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PropertyMapItem;
