import React from 'react';

import ImageWithFallback from '../../common/components/Image/ImageWithFallback';

interface IServiceCardProps {
  image: string;
  title: string;
  text: string;
}

const ServiceCard: React.FC<IServiceCardProps> = ({ image, title, text }) => {
  return (
    <div className="flex flex-col items-center bg-[#f8fbff] rounded-[10px] gap-[10px] py-[0px] sm:py-[0]">
      <ImageWithFallback className="fade-in" src={image} alt="reelty" />
      <div className="text-center sm:mt-[0px] text-[#343f52] font-semibold text-[23px] leading-[140%]">
        {title}
      </div>
      <div className="text-center sm:mt-[6px] text-[#60697B] font-medium text-[17px] leading-[170%] ">
        {text}
      </div>
    </div>
  );
};

export default ServiceCard;
