import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const LikeIcon: FC<ISvgProps> = ({
  height = 11,
  width = 12,
  color = 'white',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.06608 2.75781C6.06608 2.75781 6.06608 3.07031 5.61152 2.19531C5.08519 1.54281 4.30764 1.07031 3.37459 1.07031C1.8853 1.07031 0.683105 2.20094 0.683105 3.60156C0.683105 4.12469 0.850576 4.60844 1.13767 5.00781C1.62214 5.68844 6.06608 10.0703 6.06608 10.0703M6.06608 2.75781C6.06608 2.75781 6.06608 2.75781 6.52065 2.19531C7.04698 1.54281 7.82452 1.07031 8.75757 1.07031C10.2469 1.07031 11.4491 2.20094 11.4491 3.60156C11.4491 4.12469 11.2816 4.60844 10.9945 5.00781C10.51 5.68844 6.06608 10.0703 6.06608 10.0703"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikeIcon;
