import React, { useState, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { allowedLanguages } from '../common/constants/language';

import useAuthStore from '../common/stores/useAuthStore';

import Layout from '../pages/base/Layout';
import LayoutProfile from '../pages/profile/LayoutProfile';
import AuthLayout from '../pages/auth/AuthLayout';

import MainPage from '../pages/base/MainPage';
import SearchPage from '../pages/base/SearchPage';
import MapPage from '../pages/base/MapPage';
import ApplicationPage from '../pages/base/ApplicationPage';
import AgenciesPage from '../pages/base/AgenciesPage';
import PropertyPage from '../pages/base/PropertyPage';
import FavoritesPage from '../pages/base/FavoritesPage';
import DocumentsPage from '../pages/base/DocumentsPage';

import MyProfilePage from '../pages/profile/MyProfilePage';
import PersonalPage from '../pages/profile/PersonalPage';
import NotificationSettingsPage from '../pages/profile/NotificationSettingsPage';
import SecurityPage from '../pages/profile/SecurityPage';

import AuthPage from '../pages/auth/AuthPage';

import NotFoundPage from '../pages/NotFoundPage';
import Modal from '../common/components/Modal/Modal';

const AuthExternalHandler: React.FC = () => {
  const { search } = useLocation();

  const { setAccessToken } = useAuthStore();

  const searchParams = new URLSearchParams(search);
  const accessToken = searchParams.get('accessToken');

  setAccessToken(accessToken || null);

  return <Navigate to="/cz" />;
};

const LanguageRedirect: React.FC = () => {
  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0];

    if (allowedLanguages.includes(browserLanguage)) {
      window.location.replace(`/${browserLanguage}`);
    } else {
      window.location.replace('/cz');
    }
  }, []);

  return null;
};

const LanguageValidator: React.FC = () => {
  const [languageChanged, setLanguageChanged] = useState<boolean>(false);

  const { lang } = useParams<{ lang: string }>();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && allowedLanguages.includes(lang) && i18n.language !== lang) {
      i18n.changeLanguage(lang).then(() => {
        setLanguageChanged(true);
      });
    } else {
      setLanguageChanged(true);
    }
  }, [lang, i18n]);

  if (lang && !allowedLanguages.includes(lang)) {
    const browserLanguage = navigator.language.split('-')[0];

    if (allowedLanguages.includes(browserLanguage)) {
      window.location.replace(`/${browserLanguage}`);
    } else {
      window.location.replace('/cz');
    }
  }

  return <Outlet key={languageChanged.toString()} />;
};

const allowedTypes = ['rent', 'buy'];

const TypeValidator: React.FC = () => {
  const { lang, type } = useParams<{ lang: string; type: string }>();

  if (!type) {
    return <Navigate to={`/${lang}/search/buy`} />;
  }

  if (!allowedTypes.includes(type)) {
    return <Navigate to="/not-found" />;
  }

  return <Outlet />;
};

const UserValidator: React.FC = () => {
  const navigate = useNavigate();
  const { lang } = useParams<{ lang: string }>();

  const { accessToken } = useAuthStore();

  useEffect(() => {
    if (!accessToken) {
      navigate(`/${lang}/auth`);
    }
  }, []);

  return <Outlet />;
};

const routes = [
  {
    path: '/',
    element: <LanguageRedirect />,
  },
  {
    path: '/:lang',
    element: (
      <>
        <LanguageValidator />
        <Modal />
      </>
    ),
    children: [
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <MainPage />,
          },
          {
            path: 'search/:type?',
            element: <TypeValidator />,
            children: [
              {
                path: '',
                element: <SearchPage />,
              },
            ],
          },
          {
            path: 'map',
            element: <MapPage />,
          },
          {
            path: 'application',
            element: <ApplicationPage />,
          },
          {
            path: 'agencies',
            element: <AgenciesPage />,
          },
          {
            path: 'property/:id',
            element: <PropertyPage />,
          },
          {
            path: 'documents',
            element: <DocumentsPage />,
          },
          {
            path: '',
            element: <UserValidator />,
            children: [
              {
                path: 'favorites',
                element: <FavoritesPage />,
              },
            ],
          },
        ],
      },
      {
        path: '',
        element: <UserValidator />,
        children: [
          {
            path: 'profile',
            element: <LayoutProfile />,
            children: [
              {
                path: '',
                element: <MyProfilePage />,
              },
              {
                path: 'personal',
                element: <PersonalPage />,
              },
              {
                path: 'notifications',
                element: <NotificationSettingsPage />,
              },
              {
                path: 'security',
                element: <SecurityPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'auth',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <AuthPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/auth/external',
    element: <AuthExternalHandler />,
  },
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" />,
  },
];

export default routes;
