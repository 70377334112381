import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCategorySchemaById } from '../../api/services/category/requests';
import { getPropertyById } from '../../api/services/property/requests';
import { postStatisticEventPropertyCall } from '../../api/services/statistic/requests';
import { postChatStart } from '../../api/services/chat/requests';

import {
  addPriceDelimiters,
  capitalizeFirstLetter,
  formatPhoneNumber,
  generatePropertyDataStringMapped,
  getAddressString,
} from '../../common/utils/helpers';

import { EPriceType } from '../../api/services/property/types';

import useGlobalLoaderStore from '../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../common/stores/useAuthStore';
import useGlobalModalStore from '../../common/stores/useGlobalModalStore';

import useWebsocketChatStore from '../../features/messages/stores/useWebsocketChatStore';
import usePropertyFieldsFromSchema from '../../common/hooks/usePropertyFieldsFromSchema';

import Container from '../../common/components/Container/Container';
import Loader from '../../common/components/Loader/Loader';
import ImageWithFallback from '../../common/components/Image/ImageWithFallback';
import Button from '../../common/components/Button/Button';

import MessagesModal from '../../common/components/modals/MessagesModal';

import PropertyHeader from '../../features/property/components/PropertyHeader';
import PropertyMap from '../../features/property/components/PropertyMap';
import PropertyPageCarousel from '../../features/property/components/PropertyPageCarousel';
import PropertyFieldWrapper from '../../features/property/components/PropertyFieldWrapper';
import PropertyFieldsColumn from '../../features/property/components/PropertyFieldsColumn';

import NoImageIcon from '../../common/assets/icons/NoImageIcon';
import LocationPin from '../../common/assets/icons/LocationPin';
import MakeChatIcon from '../../common/assets/icons/MakeChatIcon';
import EmailIcon from '../../common/assets/icons/EmailIcon';
import PhoneIcon from '../../common/assets/icons/PhoneIcon';

import ParametersIcon from '../../common/assets/icons/property-fields/ParametersIcon';
import DescriptionIcon from '../../common/assets/icons/property-fields/DescriptionIcon';
import FeatureIcon from '../../common/assets/icons/property-fields/FeatureIcon';

import { ReactComponent as MapIcon } from '../../common/assets/icons/mapIcon.svg';

const PropertyPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  const navigate = useNavigate();
  const { id, lang } = useParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { user } = useAuthStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();
  const { setNewChat } = useWebsocketChatStore();

  const { data, refetch } = useQuery(
    ['getEditPropertyById', 'getCategorySchemaById', id, lang],
    async () => {
      setLoader(true);
      const property = await getPropertyById(id as string);
      if (!property.category?.id) {
        setError('Unknown property category');
        setLoader(false);
        return null;
      }
      const categorySchema = await getCategorySchemaById(property.category.id);
      setLoader(false);
      return {
        property,
        categorySchema,
      };
    },
    {
      enabled: !!id,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
        setIsLoading(false);
      },
      onSuccess: () => {
        setTimeout(() => setIsLoading(false), 0);
      },
    },
  );

  const property = data?.property;
  const categorySchema = data?.categorySchema;

  const pricePart = property?.price ? `, ${property.price} Kč` : '';
  const dataString =
    generatePropertyDataStringMapped(
      property?.category,
      property?.mappedFields,
      lang,
    ) + pricePart;

  const fancyBoxData = {
    property: dataString,
    phone: property?.contactPhone,
  };

  const propertyFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'parameters'),
  );
  const priceAndRentalTermsFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'priceAndRentalTerms'),
  );
  const featuresFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'features'),
  );

  const allImages = useMemo(() => {
    if (!property) return null;
    const mainImage = property.mainImage;
    const collateralImages = property.gallery?.map((img) => img.image) || [];
    return mainImage ? [mainImage, ...collateralImages] : collateralImages;
  }, [property]);

  const handleClickChat = async () => {
    if (!user) {
      navigate(`/${lang}/auth`);
      return;
    }
    if (!property) return;
    try {
      const payload = {
        userId: property.owner.id,
        propertyId: property.id,
      };
      const data = await postChatStart(payload);
      if (data?.id) {
        setNewChat(data);
        setChildren(<MessagesModal />);
        setIsOpen(true);
      }
    } catch (error: any) {
      setError(error.response?.data?.message);
    }
  };

  const handleShowPhone = async () => {
    setShowPhone(true);
    if (!property) return;
    try {
      await postStatisticEventPropertyCall(property.id);
    } catch (error: any) {
      setError(error.response?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="h-[100vh]">
        <Loader />
      </div>
    );
  }

  const { lat, lon } = property?.address?.coordinates || {};

  return (
    // Добавляем класс px-0 для мобильных, чтобы расширить содержимое
    // На больших экранах отступы будут как обычно (sm:px-[24px], md:px-[32px] и т.п. из Container)
    <div className="pt-[36px] pb-7 fade-in mb-[70px]">
      <Container>
        <PropertyHeader property={property} refetch={refetch} />

        {/*
          До lg: <1024px блоки вертикально,
          От lg: ≥1024px – горизонтально.
        */}
        <div className="flex flex-col lg:flex-row gap-[30px] mt-5 w-full ">
          {/* Левая часть */}
          <div className="flex-1 sm:w-full lg:w-0">
            {allImages && allImages.length > 0 ? (
              <div className="rounded-[15px] max-w-full lg:max-h-[600px]  lg:max-w-[980px] overflow-hidden">
                <PropertyPageCarousel items={allImages} data={fancyBoxData} />
              </div>
            ) : (
              <div className="flex-center aspect-[1.58] bg-[#F8F8F8] rounded-[15px]">
                <NoImageIcon />
              </div>
            )}

            {!!property?.description?.length && (
              <div className="relative mt-[25px] w-full">
                <PropertyFieldWrapper
                  label={t('description')}
                  icon={<DescriptionIcon />}
                >
                  <div>{property.description as string}</div>
                </PropertyFieldWrapper>
              </div>
            )}

            {!!propertyFields?.length && (
              <div className="mt-[25px] w-full">
                <PropertyFieldWrapper
                  label={t('parameters')}
                  icon={<ParametersIcon />}
                >
                  <div>
                    {/* Показываем 1 колонку на xl, а на более мелких разрешениях — 2 */}
                    <div className="hidden xl:block">
                      <PropertyFieldsColumn
                        items={propertyFields}
                        columnCount={2}
                      />
                    </div>
                    <div className="block xl:hidden">
                      <PropertyFieldsColumn
                        items={propertyFields}
                        columnCount={1}
                      />
                    </div>
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}

            {!!lat && !!lon && (
              <div className="mt-[25px] w-full">
                <PropertyFieldWrapper
                  label={t('location')}
                  icon={
                    <MapIcon width={24} height={24} className="text-heading" />
                  }
                >
                  <div className="w-full h-[318px] mt-[18px] rounded-[8px] overflow-hidden mb-[16px]">
                    <PropertyMap lat={lat} lng={lon} />
                  </div>
                  <div className="flex items-center gap-[6px] h-[44px]">
                    <LocationPin width={24} height={24} color="#605E76" />
                    <div className="text-[#333333]">
                      {getAddressString(property?.address)}
                    </div>
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}

            {!!featuresFields?.length && (
              <div className="mt-[25px] w-full">
                <PropertyFieldWrapper
                  label={t('features')}
                  icon={<FeatureIcon />}
                >
                  <div className="mt-[16px] line-clamp-3">
                    <PropertyFieldsColumn items={featuresFields} />
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}
          </div>

          {/* Правая часть (стикер для десктопа) */}
          <div className="relative lg:w-[330px] mt-5 lg:mt-0">
            <div className="lg:sticky lg:top-[20px] flex flex-col gap-[25px] bg-white">
              <div className="flex flex-col gap-[16px] border-b border-disabled pb-[15px]">
                <h4 className="block 2xl:hidden text-heading-4 text-heading">
                  {t('price_and_rental_terms')}
                </h4>
                <div className="hidden sm:block text-display-4 text-primary">
                  {property?.price
                    ? `${addPriceDelimiters(property?.price)} Kč`
                    : '0 Kč'}
                  <span>
                    {property?.priceType === EPriceType.PerMonth
                      ? `/${t('month')}`
                      : property?.priceType === EPriceType.PerDay
                        ? `/${t('day')}`
                        : ''}
                  </span>
                </div>
                {!!priceAndRentalTermsFields?.length && (
                  <div>
                    <PropertyFieldsColumn
                      items={priceAndRentalTermsFields}
                      valuePosition="right"
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-[20px]">
                <div className="flex items-center gap-[10px]">
                  {!property?.owner?.avatar ? (
                    <div className="flex-center rounded-full w-[56px] h-[56px] bg-primary text-heading-2 text-white">
                      {property?.owner?.name.charAt(0)}
                    </div>
                  ) : (
                    <ImageWithFallback
                      className="rounded-full w-9 h-9"
                      src={property.owner.avatar.url}
                      alt="avatar"
                      width={56}
                      height={56}
                    />
                  )}
                  <div className="flex flex-col justify-center">
                    <div className="text-heading">{property?.owner.name}</div>
                    <div className="text-100 text-heading">
                      {property?.owner.agentProfile?.legalName}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-[20px]">
                  <div className="hidden sm:block">
                    <Button
                      onClick={handleClickChat}
                      size="smaller"
                      className={`w-full lg:w-[330px] hidden sm:flex`}
                    >
                      <MakeChatIcon />
                      <span className="text-[18px] leading-[1]">
                        {t('send_message')}
                      </span>
                    </Button>
                  </div>
                  {!!property?.owner.email && (
                    <>
                      {showEmail ? (
                        <div className="flex gap-[8px]">
                          <div className="flex-1">
                            {capitalizeFirstLetter(t('email'))}:
                          </div>
                          <div className="flex-1 text-heading text-right">
                            {property?.owner.email}
                          </div>
                        </div>
                      ) : (
                        <Button
                          variant="primary-outlined"
                          size="smaller"
                          className="w-full lg:w-[330px]"
                          onClick={() => setShowEmail(true)}
                        >
                          <EmailIcon />
                          <span className="text-[18px] leading-[1]">
                            {t('show_email')}
                          </span>
                        </Button>
                      )}
                    </>
                  )}

                  {!!property?.contactPhone && (
                    <>
                      {showPhone ? (
                        <div className="flex gap-[8px]">
                          <div className="flex-1">
                            {capitalizeFirstLetter(t('phone_number'))}:
                          </div>
                          <div className="flex-1 text-heading text-right">
                            <div>
                              {formatPhoneNumber(property.contactPhone)}
                            </div>
                            {!!property?.additionalPhone && (
                              <div>
                                {formatPhoneNumber(property.additionalPhone)}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Button
                          variant="primary-outlined"
                          size="smaller"
                          className="w-full lg:w-[330px]"
                          onClick={handleShowPhone}
                        >
                          <PhoneIcon />
                          <span className="text-[18px] leading-[1]">
                            {t('show_phone_number')}
                          </span>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PropertyPage;
