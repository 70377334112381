import React from 'react';
import { RouterProvider } from 'react-router-dom';

import useRouter from '../common/hooks/useRouter';
import useGetUserMe from '../common/hooks/useGetUserMe';

const Router = () => {
  const { router } = useRouter();
  useGetUserMe();

  if (!router) return null;

  return <RouterProvider router={router} />;
};

export default Router;
