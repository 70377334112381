import { AxiosResponse } from 'axios';
import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';

export const postChatStart = async (payload: any) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    `/chat/start`,
    payload,
  );
  return response.data.data;
};
