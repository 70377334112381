import React from 'react';
import { useTranslation } from 'react-i18next';

import ProfileTip from '../../common/components/ProfileTip/ProfileTip';
import Switch from '../../common/components/Switch/SwitchControlled';

import useFormNotificationSettings from '../../features/profile/hooks/useFormNotificationSettings';

import BellIcon from '../../common/assets/icons/BellBigIcon';

const NotificationSettingsPage = () => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useFormNotificationSettings();

  const handleOnChange = async () => {
    await handleSubmit();
  };

  return (
    <div className="grid grid-cols-[1fr_342px] pb-[45px]">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-[30px] px-[25px]"
      >
        <h3 className="text-heading-5 text-heading">
          {t('notification_settings')}
        </h3>
        <div className="flex flex-col gap-[15px]">
          {[
            { name: 'service', label: t('notification_service') },
            { name: 'promotions', label: t('notification_promotions') },
            { name: 'chatMessage', label: t('notification_chat_messages') },
            { name: 'savedSearches', label: t('notification_saved_searches') },
            { name: 'changeStatuses', label: t('notification_statuses') },
          ].map(({ name, label }) => (
            <div key={name} className="flex items-center gap-[15px]">
              <Switch
                control={control}
                name={`notificationSettings.${name}`}
                onChange={handleOnChange}
              />
              <div>{label}</div>
            </div>
          ))}
        </div>
      </form>
      <div>
        <ProfileTip
          icon={<BellIcon />}
          text={t('notifications_help_message_1')}
        />
      </div>
    </div>
  );
};

export default NotificationSettingsPage;
