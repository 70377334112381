import { FC } from 'react';

import { ISvgProps } from '../../../types/media';

const DescriptionIcon: FC<ISvgProps> = ({
  height = 17,
  width = 24,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.00048H23C23.2652 2.00048 23.5196 1.89513 23.7071 1.70759C23.8946 1.52006 24 1.2657 24 1.00049C24 0.73527 23.8946 0.480917 23.7071 0.293381C23.5196 0.105845 23.2652 0.000488281 23 0.000488281H1C0.734784 0.000488281 0.48043 0.105845 0.292893 0.293381C0.105357 0.480917 0 0.73527 0 1.00049C0 1.2657 0.105357 1.52006 0.292893 1.70759C0.48043 1.89513 0.734784 2.00048 1 2.00048Z"
        fill={color}
      />
      <path
        d="M23.0002 5H9.00024C8.73503 5 8.48067 5.10536 8.29314 5.29289C8.1056 5.48043 8.00024 5.73478 8.00024 6C8.00024 6.26521 8.1056 6.51957 8.29314 6.7071C8.48067 6.89464 8.73503 7 9.00024 7H23.0002C23.2655 7 23.5198 6.89464 23.7074 6.7071C23.8949 6.51957 24.0002 6.26521 24.0002 6C24.0002 5.73478 23.8949 5.48043 23.7074 5.29289C23.5198 5.10536 23.2655 5 23.0002 5Z"
        fill={color}
      />
      <path
        d="M23 15.0005H1C0.734784 15.0005 0.48043 15.1058 0.292893 15.2934C0.105357 15.4809 0 15.7353 0 16.0005C0 16.2657 0.105357 16.5201 0.292893 16.7076C0.48043 16.8951 0.734784 17.0005 1 17.0005H23C23.2652 17.0005 23.5196 16.8951 23.7071 16.7076C23.8946 16.5201 24 16.2657 24 16.0005C24 15.7353 23.8946 15.4809 23.7071 15.2934C23.5196 15.1058 23.2652 15.0005 23 15.0005Z"
        fill={color}
      />
      <path
        d="M23.0002 9.99951H9.00024C8.73503 9.99951 8.48067 10.1049 8.29314 10.2924C8.1056 10.4799 8.00024 10.7343 8.00024 10.9995C8.00024 11.2647 8.1056 11.5191 8.29314 11.7066C8.48067 11.8942 8.73503 11.9995 9.00024 11.9995H23.0002C23.2655 11.9995 23.5198 11.8942 23.7074 11.7066C23.8949 11.5191 24.0002 11.2647 24.0002 10.9995C24.0002 10.7343 23.8949 10.4799 23.7074 10.2924C23.5198 10.1049 23.2655 9.99951 23.0002 9.99951Z"
        fill={color}
      />
      <path
        d="M1.707 12.2447L4.681 9.2707C4.88508 9.06601 4.99968 8.78875 4.99968 8.4997C4.99968 8.21065 4.88508 7.9334 4.681 7.7287L1.707 4.7547C1.56709 4.61483 1.38883 4.5196 1.19479 4.48107C1.00074 4.44253 0.799624 4.46242 0.616884 4.53821C0.434143 4.61401 0.27799 4.7423 0.168182 4.90687C0.0583736 5.07143 -0.00015534 5.26487 3.09641e-07 5.4627V11.5367C-0.00015534 11.7345 0.0583736 11.928 0.168182 12.0925C0.27799 12.2571 0.434143 12.3854 0.616884 12.4612C0.799624 12.537 1.00074 12.5569 1.19479 12.5183C1.38883 12.4798 1.56709 12.3846 1.707 12.2447Z"
        fill={color}
      />
    </svg>
  );
};

export default DescriptionIcon;
