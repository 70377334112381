import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CheckIcon: FC<ISvgProps> = ({
  height = 9,
  width = 11,
  color = '#465FF1',
  opacity = 1,
  strokeWidth = 1.5,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      opacity={opacity}
      className={className}
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.77124 5.32267L3.39318 7.94461L9.94802 1.38977"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
