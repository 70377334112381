import React from 'react';

import ModalTemplate from '../Modal/ModalTemplate';

import Messages from '../../../features/messages/components/Messages';

const ModalBody = () => {
  return (
    <div>
      <Messages />
    </div>
  );
};

const MessagesModal = () => {
  return <ModalTemplate body={<ModalBody />} width="calc(100vw - 66px)" />;
};

export default MessagesModal;
