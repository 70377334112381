import React from 'react';

import { capitalizeFirstLetter } from '../../../utils/helpers';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

import CheckIcon from '../../../assets/icons/CheckIcon';

interface IFieldOption {
  value: string;
  label: string;
}

interface IField {
  name: string;
  label: string;
  options?: IFieldOption[];
}

interface IRadioFieldProps {
  field: IField;
  multi?: boolean;
}

const RadioField: React.FC<IRadioFieldProps> = ({ field, multi = true }) => {
  const { filters, setFilters } = usePropertyFiltersStore();

  const handleChange = (value: string | string[]) => {
    setFilters({ [`FIELD_${field.name}`]: value });
  };

  return (
    <div className="pb-[25px] border-b border-[#D9D9D9]">
      <div className="text-heading-5 text-heading mb-[10px]">{field.label}</div>
      <div className="flex gap-[25px] flex-wrap">
        {field.options?.map((option) => {
          const isActive = filters[`FIELD_${field.name}`]?.includes(
            option.value,
          );

          return (
            <label
              key={option.value}
              className="flex items-center h-[25px] gap-[7px] fade-in animation-fast"
            >
              <div className="relative flex-center w-[24px] h-[24px]">
                <input
                  type="checkbox"
                  className="appearance-none w-[24px] h-[24px] border border-[#006CFB] rounded-[6px] bg-transparent shrink-0 cursor-pointer checked:bg-[#006CFB]"
                  checked={isActive}
                  onChange={() => {
                    if (multi) {
                      if (
                        filters[`FIELD_${field.name}`]?.includes(option.value)
                      )
                        handleChange(
                          filters[`FIELD_${field.name}`].filter(
                            (v: string) => v !== option.value,
                          ),
                        );
                      else {
                        const currentFieldValue =
                          filters[`FIELD_${field.name}`] || [];
                        handleChange([...currentFieldValue, option.value]);
                      }
                    } else handleChange(option.value);
                  }}
                />
                <CheckIcon
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  height={10}
                  width={15}
                  color="white"
                  strokeWidth={1}
                />
              </div>
              <div>{capitalizeFirstLetter(option.value)}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default RadioField;
