import {
  deletePropertyFavoriteRemoveById,
  postPropertyFavoriteAddById,
} from '../../api/services/property/requests';

import useGlobalErrorStore from '../stores/useGlobalErrorStore';
import useAuthStore from '../stores/useAuthStore';

const usePropertyFavorite = () => {
  const { setError } = useGlobalErrorStore();
  const { user } = useAuthStore();

  const toggleFavorite = async (id?: string, state?: boolean) => {
    if (!id || !user) return;

    try {
      if (state) {
        await deletePropertyFavoriteRemoveById(id);
      } else {
        await postPropertyFavoriteAddById(id);
      }
    } catch (error: any) {
      setError(error.response?.data?.message);
    }
  };

  return {
    toggleFavorite,
  };
};

export default usePropertyFavorite;
