import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../utils/helpers';
import usePropertySearchStore from '../../stores/usePropertySearchStore';
import usePropertyCategories from '../../hooks/usePropertyCategories';

import Button from '../Button/Button';
import PropertyTypeSelector from './PropertyTypeSelector';
import PropertyRangeSelector from './PropertyRangeSelector';
import PropertyAddressInput from './PropertyAddressInput';

import SearchIcon from '../../assets/icons/SearchIcon';

const PropertySearchBar = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { propertyTypes, setPropertyTypes, area, setArea, price, setPrice } =
    usePropertySearchStore();

  const { categories } = usePropertyCategories();

  const handleRadioChange = useCallback(
    (level: number, selectedValue: string, path = [...propertyTypes.path]) => {
      path[level] = selectedValue;
      path.splice(level + 1);

      const currentSubTree = getSubTree(categories, path);

      const subTreeChildren = currentSubTree?.children;
      if (subTreeChildren?.length) {
        const firstChildKey = subTreeChildren[0].name;
        if (firstChildKey) {
          path.push(firstChildKey);
          handleRadioChange(level + 1, firstChildKey, path);
        }
      }

      const newSubTree = getSubTree(categories, path);
      const finalEntities = newSubTree?.entities || [];

      const newValue = {
        path: path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      setPropertyTypes(newValue);
    },
    [propertyTypes, categories, setPropertyTypes],
  );

  const forceHardcodedPath = (code: string) => {
    const path =
      code === 'rent'
        ? ['rent', 'longTerm', 'residental']
        : ['sale', 'residental'];

    const currentSubTree = getSubTree(categories, path);
    const finalEntities = currentSubTree?.entities || [];
    const neededEntity = finalEntities.find((e: any) =>
      code === 'rent'
        ? e.name === 'rent.longTerm.residental.apartment'
        : e.name === 'sale.residental.apartment',
    );

    const updatedValue = {
      path,
      entities: neededEntity ? [neededEntity] : [],
    };

    if (JSON.stringify(propertyTypes.path) !== JSON.stringify(path)) {
      setPropertyTypes(updatedValue);
    }
  };

  const type = propertyTypes.path[0] === 'rent' ? 'rent' : 'buy';
  const handleSearchRedirect = () => {
    navigate(`/${lang}/search/${type}`);
  };

  return (
    <div className="fade-in mt-5 lg:mt-0">
      <div className="flex relative">
        <div
          onClick={() => forceHardcodedPath('sale')}
          className={`flex-center w-[140px] h-[45px] rounded-[15px_15px_0_0] text-heading-5 cursor-pointer ${
            type === 'buy'
              ? 'bg-white text-body z-[1]'
              : 'bg-secondary text-heading z-0'
          }`}
        >
          {t('buy')}
        </div>
        <div
          onClick={() => forceHardcodedPath('rent')}
          className={`flex-center w-[140px] h-[45px] rounded-[15px_15px_0_0] text-heading-5 cursor-pointer -ml-[26px] ${
            type === 'rent'
              ? 'bg-white text-body z-[1]'
              : 'bg-secondary text-heading z-0'
          }`}
        >
          {t('rent')}
        </div>
      </div>
      <div className="flex flex-1 items-center justify-between gap-[50px] h-[88px] bg-white rounded-[0_15px_15px_15px] px-[25px]">
        <div className="flex items-center w-full gap-[24px]">
          <PropertyTypeSelector />
          {/* Остальные поля скрываем на мобильной и планшетной версии */}
          <div className="hidden lg:flex items-center gap-[24px]">
            <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            <PropertyRangeSelector
              title={t('price')}
              placeholder={t('placeholder-price')}
              placeholderFrom="0"
              placeholderTo="150 000"
              unit="Kč"
              defaultValue={price}
              onChange={setPrice}
            />
            <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            <PropertyRangeSelector
              title={t('area')}
              placeholder={t('placeholder-area')}
              placeholderFrom="0"
              placeholderTo="250"
              unit="m²"
              defaultValue={area}
              onChange={setArea}
            />
            <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            <div className="hidden lg:block">
              <PropertyAddressInput />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="hidden sm:flex">
            <Button
              size="smaller"
              onClick={handleSearchRedirect}
              className="min-w-[124px]"
            >
              <SearchIcon color="white" />
              {t('button-search')}
            </Button>
          </div>
          <div
            className="flex sm:hidden w-[48px] h-[48px] bg-accent flex-center rounded-[14px]"
            onClick={handleSearchRedirect}
          >
            <SearchIcon color="white" height={26} width={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertySearchBar;
