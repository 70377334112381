import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const LocationPinIcon: FC<ISvgProps> = ({
  height = 24,
  width = 22,
  color = '#605E76',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6C10.2089 6 9.43552 6.2346 8.77772 6.67412C8.11993 7.11365 7.60723 7.73836 7.30448 8.46927C7.00173 9.20017 6.92252 10.0044 7.07686 10.7804C7.2312 11.5563 7.61216 12.269 8.17157 12.8284C8.73098 13.3878 9.44372 13.7688 10.2196 13.9231C10.9956 14.0775 11.7998 13.9983 12.5307 13.6955C13.2616 13.3928 13.8864 12.8801 14.3259 12.2223C14.7654 11.5645 15 10.7911 15 10C15 8.93913 14.5786 7.92172 13.8284 7.17157C13.0783 6.42143 12.0609 6 11 6ZM11 12C10.6044 12 10.2178 11.8827 9.88886 11.6629C9.55996 11.4432 9.30362 11.1308 9.15224 10.7654C9.00087 10.3999 8.96126 9.99778 9.03843 9.60982C9.1156 9.22186 9.30608 8.86549 9.58579 8.58579C9.86549 8.30608 10.2219 8.1156 10.6098 8.03843C10.9978 7.96126 11.3999 8.00087 11.7654 8.15224C12.1308 8.30362 12.4432 8.55996 12.6629 8.88886C12.8827 9.21776 13 9.60444 13 10C13 10.5304 12.7893 11.0391 12.4142 11.4142C12.0391 11.7893 11.5304 12 11 12Z"
        fill={color}
      />
      <path
        d="M10.9998 24.0001C10.1578 24.0044 9.32693 23.8069 8.57689 23.4241C7.82684 23.0413 7.17942 22.4844 6.68882 21.8C2.87782 16.543 0.944824 12.591 0.944824 10.053C0.944824 7.3863 2.00419 4.82877 3.88987 2.94309C5.77554 1.05741 8.33307 -0.00195312 10.9998 -0.00195312C13.6666 -0.00195312 16.2241 1.05741 18.1098 2.94309C19.9955 4.82877 21.0548 7.3863 21.0548 10.053C21.0548 12.591 19.1218 16.543 15.3108 21.8C14.8202 22.4844 14.1728 23.0413 13.4228 23.4241C12.6727 23.8069 11.8419 24.0044 10.9998 24.0001ZM10.9998 2.18105C8.91224 2.18343 6.91084 3.01377 5.4347 4.48992C3.95855 5.96606 3.12821 7.96746 3.12582 10.055C3.12582 12.065 5.01882 15.782 8.45482 20.521C8.74652 20.9228 9.12919 21.2498 9.57154 21.4753C10.0139 21.7008 10.5033 21.8183 10.9998 21.8183C11.4963 21.8183 11.9858 21.7008 12.4281 21.4753C12.8705 21.2498 13.2531 20.9228 13.5448 20.521C16.9808 15.782 18.8738 12.065 18.8738 10.055C18.8714 7.96746 18.0411 5.96606 16.565 4.48992C15.0888 3.01377 13.0874 2.18343 10.9998 2.18105Z"
        fill={color}
      />
    </svg>
  );
};

export default LocationPinIcon;
