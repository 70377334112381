import {
  RENT_LONGTERM_APARTMENT,
  RENT_LONGTERM_GARAGE,
  RENT_LONGTERM_HOUSE,
  RENT_LONGTERM_OFFICE,
  SALE_APARTMENT,
  SALE_GARAGE,
  SALE_HOUSE,
  SALE_OFFICE,
} from './entities';

export interface IMenuItem {
  label: string;
  href: string;
}

export const headerMenuItems: IMenuItem[] = [
  {
    label: 'header-menu-0',
    href: '',
  },
  {
    label: 'header-menu-1',
    href: '/search/buy',
  },
  {
    label: 'header-menu-2',
    href: '/search/rent',
  },
  {
    label: 'header-menu-3',
    href: '/map',
  },
  {
    label: 'header-menu-4',
    href: '/application',
  },
  {
    label: 'header-menu-5',
    href: '/agencies',
  },
];

export const footerMenuItems: IMenuItem[] = [
  {
    label: 'header-menu-0',
    href: '',
  },
  {
    label: 'header-menu-3',
    href: '/map',
  },
  {
    label: 'header-menu-4',
    href: '/application',
  },
  {
    label: 'header-menu-5',
    href: '/agencies',
  },
];

export const footerRentItems: IMenuItem[] = [
  {
    label: 'apartments',
    href: `/search/rent?entity=${RENT_LONGTERM_APARTMENT}`,
  },
  {
    label: 'houses',
    href: `/search/rent?entity=${RENT_LONGTERM_HOUSE}`,
  },
  {
    label: 'office',
    href: `/search/rent?entity=${RENT_LONGTERM_OFFICE}`,
  },
  {
    label: 'garage',
    href: `/search/rent?entity=${RENT_LONGTERM_GARAGE}`,
  },
];

export const footerBuyItems: IMenuItem[] = [
  {
    label: 'apartments',
    href: `/search/buy?entity=${SALE_APARTMENT}`,
  },
  {
    label: 'houses',
    href: `/search/buy?entity=${SALE_HOUSE}`,
  },
  {
    label: 'office',
    href: `/search/buy?entity=${SALE_OFFICE}`,
  },
  {
    label: 'garage',
    href: `/search/buy?entity=${SALE_GARAGE}`,
  },
];

export const userMenuItems: IMenuItem[] = [
  {
    label: 'my_profile',
    href: '/profile',
  },
  {
    label: 'messages',
    href: 'messages',
  },
  {
    label: 'favorites',
    href: '/favorites',
  },
];

export const profileMenuItems: IMenuItem[] = [
  {
    label: 'my_profile',
    href: '/profile',
  },
  {
    label: 'personal_data',
    href: '/profile/personal',
  },
  // {
  //   label: 'notification_settings',
  //   href: '/profile/notifications',
  // },
  {
    label: 'auth_and_security',
    href: '/profile/security',
  },
];
