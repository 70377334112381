import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../common/components/Button/Button';

import ArrowRight from '../../../common/assets/icons/ArrowRight';

const AdminPanelBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between items-center w-full h-[960px] rounded-[20px] pt-[108px] pb-[70px] px-[32px] text-center text-white bg-registration-gradient-block bg-cover">
      <div className="text-center">
        <div className="text-display-3 mb-[22px]">{t('welcome_message')}</div>
        <div className="text-body-large">
          {t('welcome_message_description')}
        </div>
      </div>
      <div className="flex flex-col items-center gap-[25px]">
        <div className="text-[40px] leading-[46px] font-bold">
          {t('agent_account_question')}
        </div>
        <div className="text-body-large">
          {t('agent_registration_benefits')}
        </div>
        <div className="w-full grid grid-cols-2 gap-[28px]">
          <a
            href="https://agent.reelty.cz/create-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="secondary-master" className="w-full">
              {t('create_agent_account')}
              <div>
                <ArrowRight />
              </div>
            </Button>
          </a>
          <a
            href="https://agent.reelty.cz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="secondary-outlined" className="w-full">
              {t('login_agent_account')}
              <div>
                <ArrowRight />
              </div>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdminPanelBlock;
