import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '../../../common/assets/icons/CheckIcon';

interface IPasswordStrengthProps {
  password: string;
  setValidPassword?: (validPassword: boolean) => void;
  className?: string;
}

const PasswordStrength = ({
  password,
  setValidPassword,
  className = '',
}: IPasswordStrengthProps) => {
  const { t } = useTranslation();

  const checkEightCharacters = useMemo(() => password.length >= 8, [password]);
  const checkOneNumber = useMemo(() => /\d/.test(password), [password]);
  const checkOneUppercase = useMemo(() => /[A-Z]/.test(password), [password]);
  const checkOneLowercase = useMemo(() => /[a-z]/.test(password), [password]);

  const getIconStyles = (check: boolean) =>
    check
      ? { opacity: 1, color: '#03c15b' }
      : { opacity: 0.25, color: '#465FF1' };

  useEffect(() => {
    setValidPassword?.(
      checkEightCharacters &&
        checkEightCharacters &&
        checkOneUppercase &&
        checkOneLowercase,
    );
  }, [
    setValidPassword,
    checkEightCharacters,
    checkOneNumber,
    checkOneUppercase,
    checkOneLowercase,
  ]);

  return (
    <div className={`flex flex-col gap-[8px] ${className}`}>
      <div className="flex items-center gap-[8px]">
        <div className="flex-center w-[20px] h-[20px]">
          <CheckIcon
            width={13}
            height={10}
            {...getIconStyles(checkEightCharacters)}
          />
        </div>
        <div className="text-body-smaller text-[#333333]">
          {t('eight_characters')}
        </div>
      </div>
      <div className="flex items-center gap-[8px]">
        <div className="flex-center w-[20px] h-[20px]">
          <CheckIcon
            width={13}
            height={10}
            {...getIconStyles(checkOneNumber)}
          />
        </div>
        <div className="text-body-smaller text-[#333333]">{t('one_digit')}</div>
      </div>
      <div className="flex items-center gap-[8px]">
        <div className="flex-center w-[20px] h-[20px]">
          <CheckIcon
            width={13}
            height={10}
            {...getIconStyles(checkOneUppercase)}
          />
        </div>
        <div className="text-body-smaller text-[#333333]">
          {t('one_uppercase_letter')}
        </div>
      </div>
      <div className="flex items-center gap-[8px]">
        <div className="flex-center w-[20px] h-[20px]">
          <CheckIcon
            width={13}
            height={10}
            {...getIconStyles(checkOneLowercase)}
          />
        </div>
        <div className="text-body-smaller text-[#333333]">
          {t('one_lowercase_letter')}
        </div>
      </div>
    </div>
  );
};

export default PasswordStrength;
