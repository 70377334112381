import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getPropertyFavorite } from '../../../api/services/property/requests';

import {
  addPriceDelimiters,
  generatePropertyDataObject,
  getAddressString,
} from '../../../common/utils/helpers';

import { EPriceType } from '../../../api/services/property/types';
import { IImage } from '../../../common/types/media';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import usePropertyFavorite from '../../../common/hooks/usePropertyFavorite';

import PropertyTileCarousel from '../../../common/components/PropertyList/PropertyTileCarousel';

import NoImageIcon from '../../../common/assets/icons/NoImageIcon';
import LocationPin from '../../../common/assets/icons/LocationPin';
import LikeIcon from '../../../common/assets/icons/LikeIcon';

import { ReactComponent as RoomsIcon } from '../../../common/assets/icons/roomsIcon.svg';
import { ReactComponent as AreaIcon } from '../../../common/assets/icons/areaIcon.svg';
import { ReactComponent as FloorsIcon } from '../../../common/assets/icons/floorsIcon.svg';

import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

const FavoritePropertiesList = () => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { toggleFavorite } = usePropertyFavorite();

  const { data = [] as any, refetch } = useQuery(
    ['propertiesFavorite'],
    async () => {
      setLoader(true);

      const data = await getPropertyFavorite();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  return data?.length ? (
    <div className="grid grid-cols-4 gap-x-[25px] gap-y-[35px]">
      {data.map((item: any) => {
        const {
          id,
          mainImage,
          gallery = [],
          price,
          address,
          fields,
          category,
          isFavorite,
          priceType,
        } = item;

        const addressString = getAddressString(address);

        const dataObject = generatePropertyDataObject(category, fields);

        const collateralImages = gallery;

        const allImages: IImage[] = mainImage
          ? [...[mainImage], ...collateralImages]
          : collateralImages;

        const handleClick = async (event: any) => {
          event.preventDefault();

          await toggleFavorite(id, isFavorite);
          if (refetch) {
            await refetch();
          }
        };

        return (
          <Link
            key={item.id}
            className="relative group flex flex-col gap-[10px] rounded-[15px] cursor-pointer fade-in"
            to={`/${lang}/property/${id}`}
          >
            {allImages && allImages.length > 0 ? (
              <PropertyTileCarousel items={allImages} />
            ) : (
              <div className="flex-center aspect-[1.34] bg-[#F8F8F8] rounded-[15px]">
                <NoImageIcon width={40} height={40} />
              </div>
            )}
            <div
              onClick={handleClick}
              className={`absolute transition-opacity duration-500 opacity-0 group-hover:opacity-60 group-hover:hover:opacity-100 top-[7px] right-[7px] flex-center w-[32px] h-[32px] rounded-[10px] ${isFavorite ? 'bg-primary' : 'bg-[#A9A9A9]'}`}
            >
              <LikeIcon width={20} height={20} />
            </div>
            <div className="flex-1 flex flex-col gap-[10px]">
              {!!fields && (
                <div className="flex flex-wrap items-center gap-[15px]">
                  {dataObject.rooms && (
                    <div className="flex items-center gap-[5px]">
                      <div className="flex-center w-[14px] h-[14px]">
                        <RoomsIcon width={14} height={14} />
                      </div>
                      <div className="text-[12px] leading-[16.4px]">
                        {dataObject.rooms}
                      </div>
                    </div>
                  )}
                  {dataObject.area && (
                    <div className="flex items-center gap-[5px]">
                      <div className="flex-center w-[14px] h-[14px]">
                        <AreaIcon width={14} height={14} />
                      </div>
                      <div className="text-[12px] leading-[16.4px]">
                        {dataObject.area}
                      </div>
                    </div>
                  )}
                  {dataObject.floors && (
                    <div className="flex items-center gap-[5px]">
                      <div className="flex-center w-[14px] h-[14px]">
                        <FloorsIcon width={14} height={14} />
                      </div>
                      <div className="text-[12px] leading-[16.4px]">
                        {dataObject.floors}
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="flex items-center gap-[5px]">
                <LocationPin width={14} height={14} />
                <div className="text-body-smaller">{addressString}</div>
              </div>

              <h6 className="text-heading-6 text-primary">
                {addPriceDelimiters(price)} Kč
                {priceType === EPriceType.PerMonth
                  ? `/${t('month')}`
                  : priceType === EPriceType.PerDay
                    ? `/${t('day')}`
                    : ''}
              </h6>
            </div>
          </Link>
        );
      })}
    </div>
  ) : (
    <div className={'flex flex-col items-center gap-[17px] py-[35px]'}>
      <EmptyListImage />
      <div className="fade-in">{t('empty_list')}</div>
    </div>
  );
};

export default FavoritePropertiesList;
