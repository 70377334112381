import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ChevronIcon: FC<ISvgProps> = ({
  height = 24,
  width = 27,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5822 17.7385C19.2753 19.6165 17.3656 20.9912 15.17 21.6346C12.9744 22.278 10.6247 22.1514 8.51096 21.2757C6.39718 20.4001 4.6463 18.8281 3.54881 16.8205C2.45131 14.813 2.07316 12.4905 2.47711 10.2385C2.88105 7.9865 4.0428 5.94026 5.76961 4.4393C7.49643 2.93833 9.68451 2.07286 11.9708 1.98648C14.2572 1.90009 16.5043 2.59798 18.3395 3.96435C20.1746 5.33073 21.4875 7.28346 22.0602 9.49859L19.3565 7.93759C19.1268 7.80498 18.8538 7.76904 18.5977 7.83769C18.3415 7.90633 18.1231 8.07393 17.9905 8.30361C17.8579 8.5333 17.8219 8.80625 17.8906 9.06243C17.9592 9.31861 18.1268 9.53703 18.3565 9.66964L21.9444 11.7411C22.371 11.9871 22.8778 12.0537 23.3535 11.9263C23.8291 11.7988 24.2347 11.4877 24.4811 11.0614L26.5526 7.47348C26.6852 7.2438 26.7212 6.97084 26.6525 6.71466C26.5839 6.45849 26.4163 6.24007 26.1866 6.10746V6.10746C25.9569 5.97485 25.684 5.93891 25.4278 6.00756C25.1716 6.0762 24.9532 6.2438 24.8206 6.47348L23.7816 8.27308C22.9384 5.71094 21.2555 3.50815 19.0052 2.02098C16.7549 0.533803 14.0687 -0.150793 11.3811 0.0779122C8.69345 0.306617 6.16156 1.43525 4.19489 3.28127C2.22821 5.12729 0.941753 7.58276 0.543576 10.2505C0.145397 12.9183 0.658783 15.6424 2.0007 17.9823C3.34262 20.3221 5.4346 22.1409 7.9383 23.1444C10.442 24.1479 13.211 24.2776 15.7975 23.5123C18.384 22.747 20.6368 21.1316 22.1914 18.9274C22.2722 18.8133 22.3284 18.6835 22.3563 18.5465C22.3842 18.4094 22.3832 18.2681 22.3535 18.1314C22.3238 17.9948 22.2659 17.8658 22.1836 17.7527C22.1013 17.6397 21.9963 17.545 21.8754 17.4748C21.6656 17.3502 21.4169 17.3083 21.1779 17.357C20.9388 17.4058 20.7265 17.5418 20.5822 17.7385Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronIcon;
