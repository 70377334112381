import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ShareIcon: FC<ISvgProps> = ({
  height = 22,
  width = 22,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.2858C1 11.233 1.37627 12.1414 2.04605 12.8112C2.71582 13.4809 3.62423 13.8572 4.57143 13.8572C5.51863 13.8572 6.42704 13.4809 7.09681 12.8112C7.76658 12.1414 8.14286 11.233 8.14286 10.2858C8.14286 9.33858 7.76658 8.43018 7.09681 7.7604C6.42704 7.09063 5.51863 6.71436 4.57143 6.71436C3.62423 6.71436 2.71582 7.09063 2.04605 7.7604C1.37627 8.43018 1 9.33858 1 10.2858V10.2858Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8574 5.28578C13.8574 6.23299 14.2337 7.14139 14.9035 7.81117C15.5732 8.48094 16.4816 8.85721 17.4289 8.85721C18.3761 8.85721 19.2845 8.48094 19.9542 7.81117C20.624 7.14139 21.0003 6.23299 21.0003 5.28578C21.0003 4.33858 20.624 3.43018 19.9542 2.7604C19.2845 2.09063 18.3761 1.71436 17.4289 1.71436C16.4816 1.71436 15.5732 2.09063 14.9035 2.7604C14.2337 3.43018 13.8574 4.33858 13.8574 5.28578Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8574 16.7145C13.8574 17.6617 14.2337 18.5701 14.9035 19.2399C15.5732 19.9097 16.4816 20.2859 17.4289 20.2859C18.3761 20.2859 19.2845 19.9097 19.9542 19.2399C20.624 18.5701 21.0003 17.6617 21.0003 16.7145C21.0003 15.7673 20.624 14.8589 19.9542 14.1891C19.2845 13.5193 18.3761 13.1431 17.4289 13.1431C16.4816 13.1431 15.5732 13.5193 14.9035 14.1891C14.2337 14.8589 13.8574 15.7673 13.8574 16.7145Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.90137 8.99153L14.0995 6.58105"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7666 11.8838L14.2333 15.1171"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
