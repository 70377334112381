import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const EmailIcon: FC<ISvgProps> = ({
  height = 18,
  width = 19,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0417 0.75H3.95833C2.9089 0.751191 1.90282 1.14666 1.16076 1.84966C0.418698 2.55267 0.00125705 3.5058 0 4.5L0 13.5C0.00125705 14.4942 0.418698 15.4473 1.16076 16.1503C1.90282 16.8533 2.9089 17.2488 3.95833 17.25H15.0417C16.0911 17.2488 17.0972 16.8533 17.8392 16.1503C18.5813 15.4473 18.9987 14.4942 19 13.5V4.5C18.9987 3.5058 18.5813 2.55267 17.8392 1.84966C17.0972 1.14666 16.0911 0.751191 15.0417 0.75ZM3.95833 2.25H15.0417C15.5157 2.25088 15.9786 2.38614 16.3708 2.63835C16.7631 2.89057 17.0666 3.24821 17.2425 3.66525L11.1799 9.4095C10.7338 9.83049 10.1297 10.0669 9.5 10.0669C8.8703 10.0669 8.26624 9.83049 7.82008 9.4095L1.7575 3.66525C1.93337 3.24821 2.23694 2.89057 2.62916 2.63835C3.02138 2.38614 3.4843 2.25088 3.95833 2.25ZM15.0417 15.75H3.95833C3.32844 15.75 2.72435 15.5129 2.27895 15.091C1.83356 14.669 1.58333 14.0967 1.58333 13.5V5.625L6.70067 10.47C7.44375 11.1722 8.45045 11.5665 9.5 11.5665C10.5495 11.5665 11.5563 11.1722 12.2993 10.47L17.4167 5.625V13.5C17.4167 14.0967 17.1664 14.669 16.721 15.091C16.2756 15.5129 15.6716 15.75 15.0417 15.75Z"
        fill={color}
      />
    </svg>
  );
};

export default EmailIcon;
