import * as zod from 'zod';
import i18next from 'i18next';

const createMinLengthErrorMessage = (length: number) =>
  i18next.t('error_min_length', { length });

const zodStringWithMin = (length: number) =>
  zod.string().min(length, createMinLengthErrorMessage(length));

export const loginFormSchema = zod.object({
  email: zod.string().email(i18next.t('error_enter_valid_email')),
  password: zod
    .string()
    .min(8, createMinLengthErrorMessage(8))
    .regex(/[A-Z]/, i18next.t('error_one_uppercase_letter'))
    .regex(/[a-z]/, i18next.t('error_one_lowercase_letter'))
    .regex(/\d/, i18next.t('error_one_digit'))
    .regex(
      // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/,
      i18next.t('error_latin_letters_only'),
    ),
});

export const registrationFormSchema = zod.object({
  name: zodStringWithMin(1),
  email: zod.string().email(i18next.t('error_enter_valid_email')),
  password: zod
    .string()
    .min(8, createMinLengthErrorMessage(8))
    .regex(
      // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/,
      i18next.t('error_latin_letters_only'),
    ),
});

export const forgotPasswordEmailFormSchema = zod.object({
  email: zod.string().email(i18next.t('error_enter_valid_email')),
});

export const forgotPasswordPinFormSchema = zod.object({
  pin: zodStringWithMin(4),
});

export const forgotPasswordChangeFormSchema = zod
  .object({
    password: zod
      .string()
      .min(8, createMinLengthErrorMessage(8))
      .regex(
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/,
        i18next.t('error_latin_letters_only'),
      ),
    confirmPassword: zodStringWithMin(8),
  })
  .refine(
    (values) => {
      return values.password === values.confirmPassword;
    },
    {
      message: i18next.t('error_passwords_must_match'),
      path: ['confirmPassword'],
    },
  );
