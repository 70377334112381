import { create } from 'zustand';

interface IChangeEmailStore {
  email: string;
  setEmail: (email: string) => void;
}

const useChangeEmailStore = create<IChangeEmailStore>((set) => ({
  email: '',
  setEmail: (email: string) =>
    set(() => ({
      email,
    })),
}));

export default useChangeEmailStore;
