import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import useGlobalLoaderStore from '../../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../stores/useGlobalErrorStore';

import Button from '../Button/Button';

import RotateIcon from '../../assets/icons/RotateIcon';

interface AvatarUploaderProps {
  onUpload: (file: File) => Promise<void>;
}

const AvatarUploader: React.FC<AvatarUploaderProps> = ({ onUpload }) => {
  const [image, setImage] = useState<File | null>(null);
  const [editorRef, setEditorRef] = useState<AvatarEditor | null>(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isTouching, setIsTouching] = useState(false);

  const { t } = useTranslation();

  const { loader, setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const touchStartDistance = useRef<number | null>(null);

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0]);
    }
  };

  const handleRotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleSave = () => {
    if (editorRef) {
      setLoader(true);
      const canvas = editorRef.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], 'avatar.png', { type: 'image/png' });
          try {
            await onUpload(file);
          } catch (error: any) {
            setError(error.response?.data?.message || t('upload_failed'));
          } finally {
            setLoader(false);
          }
        }
      }, 'image/png');
    }
  };

  const handleWheel = (event: React.WheelEvent) => {
    if (event.deltaY < 0) {
      setScale((prev) => Math.min(prev + 0.1, 3));
    } else {
      setScale((prev) => Math.max(prev - 0.1, 0.1));
    }
  };

  const handleTouchStart = (event: any) => {
    if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      touchStartDistance.current = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY,
      );
      setIsTouching(true);
    }
  };

  const handleTouchMove = (event: any) => {
    if (
      isTouching &&
      event.touches.length === 2 &&
      touchStartDistance.current
    ) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const touchEndDistance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY,
      );
      const scaleChange = touchEndDistance / touchStartDistance.current;
      setScale((prev) => Math.min(Math.max(prev * scaleChange, 0.1), 3));
      touchStartDistance.current = touchEndDistance;
    }
  };

  const handleTouchEnd = () => {
    setIsTouching(false);
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isTouching]);

  return (
    <div>
      {!image ? (
        <Dropzone onDrop={handleDrop} accept={{ 'image/*': [] }}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="flex-center flex-col w-full h-[233px] rounded-[15px] border-[3px] border-dashed border-[#D9D9D9] cursor-pointer"
            >
              <input {...getInputProps()} />
              <p className="text-placeholder">{t('drop_here')}</p>
              <p className="text-accent">{t('or_select_photo')}</p>
            </div>
          )}
        </Dropzone>
      ) : (
        <div onWheel={handleWheel} className="relative">
          <AvatarEditor
            ref={(ref) => setEditorRef(ref)}
            image={image}
            width={412}
            height={412}
            border={103}
            borderRadius={500}
            scale={scale}
            rotate={rotation}
            className="w-full aspect-[1] rounded-[15px] bg-[#00000099]"
          />
          <div className="flex items-center justify-between pl-[5px] mt-[20px]">
            <button onClick={handleRotate}>
              <RotateIcon />
            </button>
            <Button
              withCooldown
              size="small"
              onClick={handleSave}
              disabled={loader}
              className="w-[158px]"
            >
              {t('save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarUploader;
