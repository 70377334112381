import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '../../common/components/Header/Header';
import Footer from '../../common/components/Footer/Footer';

const Layout = () => {
  const location = useLocation();

  const isMapRoute = location.pathname.includes('/map');

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Outlet />
      </div>
      {!isMapRoute && <Footer />}
    </div>
  );
};

export default Layout;
