import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../common/components/Input/Input';
import Button from '../../../common/components/Button/Button';

import useFormRegistration from '../hooks/useFormRegistration';

import PasswordStrength from './PasswordStrength';
// import ExternalAuth from './ExternalAuth';

const RegistrationForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const { control, handleSubmit, password, isValid } = useFormRegistration();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-[30px] fade-in">
        <Input
          label={t('name_label')}
          name="name"
          control={control}
          placeholder={t('name_placeholder')}
        />
        <Input
          label={t('email_label')}
          name="email"
          control={control}
          placeholder={t('email_placeholder')}
        />
        <Input
          type="password"
          label={<div>{t('password_label')}</div>}
          name="password"
          control={control}
          placeholder={t('password_placeholder')}
        />
        {!!password.length && (
          <PasswordStrength
            password={password}
            setValidPassword={setValidPassword}
            className="scale-fade-in"
          />
        )}
        <div>
          <Button
            className="w-full"
            withCooldown
            type="submit"
            disabled={!isValid || !validPassword}
          >
            {t('sign_in')}
          </Button>
        </div>
        {/*{!password.length && <ExternalAuth isRegister />}*/}
      </div>
    </form>
  );
};

export default RegistrationForm;
