import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { getCategorySchemaById } from '../../api/services/category/requests';

import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';

import usePropertyCategories from './usePropertyCategories';

const usePropertyCategoriesSchemes = () => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { flatCategories } = usePropertyCategories();

  const leafCategoriesIds = flatCategories
    .filter((c) => !c.fullName)
    .map((c) => c.id);

  const { data = [] as any } = useQuery(
    ['categorySchemes', lang],
    async () => {
      setLoader(true);

      const data = await Promise.all(
        leafCategoriesIds.map(async (id) => {
          return await getCategorySchemaById(id);
        }),
      );

      setLoader(false);

      return data;
    },
    {
      enabled: !!leafCategoriesIds.length,
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const allFields = useMemo(
    () =>
      Array.from(
        new Set(
          data.flatMap(
            (item: any) =>
              item.groups?.flatMap((group: any) => group.fields) || [],
          ),
        ),
      ),
    [data],
  );

  const processFields = useCallback(
    (fields: any) => {
      if (!allFields?.length) return {};
      const processedFields: string[] = [];

      Object.keys(fields).forEach((key) => {
        const value = fields[key];

        const field: any = allFields.find((f: any) => f.name === key);

        if (field) {
          const fieldLabel = field.label;

          if (Array.isArray(value)) {
            const labels = value.map((id) => {
              const tag = field.tags.find((t: any) => t.id === id);
              return tag ? tag.label : id;
            });
            processedFields.push(`${fieldLabel}: ${labels.join(', ')}`);
          } else if (typeof value === 'object' && value !== null) {
            if ('from' in value || 'to' in value) {
              let rangeString = `${fieldLabel}: `;
              if ('from' in value && 'to' in value) {
                rangeString += `${value.from} - ${value.to}`;
              } else if ('from' in value) {
                rangeString += `${t('from')} ${value.from}`;
              } else if ('to' in value) {
                rangeString += `${t('to')} ${value.to}`;
              }
              processedFields.push(rangeString);
            } else {
              processedFields.push(`${fieldLabel}: ${JSON.stringify(value)}`);
            }
          } else {
            processedFields.push(`${fieldLabel}: ${value}`);
          }
        } else {
          processedFields.push(`${key}: ${value}`);
        }
      });

      const description = processedFields.join('. ');

      return { description };
    },
    [allFields],
  );

  const processFilters = useCallback(
    (fields: any) => {
      if (!allFields?.length) return {};
      const processedFields: string[] = [];

      // Group fields by base name (e.g., XXX from FIELD_XXXFrom and FIELD_XXXTo)
      const groupedFields: Record<string, { from?: any; to?: any }> = {};

      Object.keys(fields).forEach((key) => {
        const baseKey = key.replace(/^FIELD_/, '').replace(/(From|To)$/, '');
        const suffix = key.endsWith('From')
          ? 'from'
          : key.endsWith('To')
            ? 'to'
            : null;

        if (suffix) {
          if (!groupedFields[baseKey]) {
            groupedFields[baseKey] = {};
          }
          groupedFields[baseKey][suffix] = fields[key];
        } else {
          const value = fields[key];
          const field: any = allFields.find((f: any) => f.name === baseKey);
          const fieldLabel = field?.label || baseKey;

          if (Array.isArray(value)) {
            const labels = value.map((id) => {
              const tag = field?.tags?.find((t: any) => t.id === id);
              return tag ? tag.label : id;
            });
            processedFields.push(`${fieldLabel}: ${labels.join(', ')}`);
          } else {
            processedFields.push(`${fieldLabel}: ${value}`);
          }
        }
      });

      // Process grouped fields for From/To logic
      Object.entries(groupedFields).forEach(([key, { from, to }]) => {
        const field: any = allFields.find((f: any) => f.name === key);
        const fieldLabel = field?.label || key;

        if (from !== undefined && to !== undefined) {
          processedFields.push(`${fieldLabel}: ${from} - ${to}`);
        } else if (from !== undefined) {
          processedFields.push(`${fieldLabel}: от ${from}`);
        } else if (to !== undefined) {
          processedFields.push(`${fieldLabel}: до ${to}`);
        }
      });

      const description = processedFields.join('. ');

      return { description };
    },
    [allFields],
  );

  return {
    allFields,
    processFields,
    processFilters,
  };
};

export default usePropertyCategoriesSchemes;
