import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

// import { formatPhoneNumber } from '../../utils/helpers';

import { IImage } from '../../types/media';

import ImageWithFallback from '../Image/ImageWithFallback';
import DropdownSimple from '../Dropdown/DropdownSimple';

import LinkModal from '../../../features/search/components/modals/LinkModal';

import ChevronIcon from '../../../common/assets/icons/ChevronIcon';
import CrossIcon from '../../../common/assets/icons/CrossIcon';
import FullScreenIcon from '../../assets/icons/FullScreenIcon';
import ShareIcon from '../../assets/icons/ShareIcon';

interface ICustomArrowProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isLarge?: boolean;
}

interface IPropertyCarouselProps {
  items: IImage[];
  settings?: any;
  withFullScreen?: boolean;
  wrapperClass?: string;
  imageClass?: string;
  data?: {
    property?: string;
    phone?: string;
  };
}

const CustomPrevArrow: React.FC<ICustomArrowProps> = (props) => {
  const { onClick, isLarge = false } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick(e);
  };
  return (
    <div
      className={`opacity-0 group-hover:opacity-60 group-hover:hover:opacity-100 transition-opacity duration-500 absolute top-1/2 z-10 transform -translate-y-1/2 flex-center cursor-pointer bg-[#A9A9A9] shadow-2 ${isLarge ? 'w-[56px] h-[56px] rounded-[15px] left-[15px]' : 'w-[32px] h-[32px] rounded-[6px] left-[7px]'}`}
      onClick={handleClick}
    >
      <ChevronIcon
        width={isLarge ? 16 : 12}
        height={isLarge ? 16 : 14}
        strokeWidth={2}
        className="mr-0.5"
      />
    </div>
  );
};

const CustomNextArrow: React.FC<ICustomArrowProps> = (props) => {
  const { onClick, isLarge = false } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick(e);
  };
  return (
    <div
      className={`opacity-0 group-hover:opacity-60 group-hover:hover:opacity-100 transition-opacity duration-500 absolute top-1/2 z-10 transform -translate-y-1/2 flex-center cursor-pointer bg-[#A9A9A9] shadow-2 ${isLarge ? 'w-[56px] h-[56px] rounded-[15px] right-[15px]' : 'w-[32px] h-[32px] rounded-[6px] right-[7px]'}`}
      onClick={handleClick}
    >
      <ChevronIcon
        width={isLarge ? 16 : 12}
        height={isLarge ? 16 : 14}
        strokeWidth={2}
        className="transform rotate-180 ml-1"
      />
    </div>
  );
};

const PropertyCarousel: React.FC<IPropertyCarouselProps> = ({
  items,
  settings,
  withFullScreen = false,
  wrapperClass,
  imageClass,
  data = {},
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFancyboxOpen, setFancyboxOpen] = useState(false);

  const { lang, id } = useParams();

  const sliderRef = useRef<Slider | null>(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (index: number) => setCurrentSlide(index),
    arrows: false,
    prevArrow: null,
    nextArrow: null,
    ...settings,
  };

  const openFancybox = () => {
    setFancyboxOpen(true);
  };

  const closeFancybox = () => {
    sliderRef.current?.slickGoTo(currentSlide, true);
    setFancyboxOpen(false);
  };

  const prevImage = () => {
    setCurrentSlide((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentSlide((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    sliderRef.current?.slickPrev();
  };

  const nextSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    sliderRef.current?.slickNext();
  };

  const getFullLink = (lang: string, id: string) => {
    return `${window.location.protocol}//${window.location.host}/${lang}/property/${id}`;
  };

  const fullLink = getFullLink(lang as string, id as string);

  return (
    <>
      <div className="relative group slider-container">
        <div className={`absolute w-full ${wrapperClass || 'aspect-[1.52]'}`}>
          <CustomPrevArrow onClick={prevSlide} isLarge={withFullScreen} />
          <CustomNextArrow onClick={nextSlide} isLarge={withFullScreen} />

          {withFullScreen && (
            <div className="opacity-0 group-hover:opacity-60 group-hover:hover:opacity-100 transition-opacity duration-500 absolute top-[15px] right-[15px] z-10">
              <button
                onClick={() => openFancybox()}
                className="flex-center w-[56px] h-[56px] bg-[#A9A9A9] rounded-[15px]"
              >
                <FullScreenIcon />
              </button>
            </div>
          )}

          {/*<div*/}
          {/*  className={`opacity-0 group-hover:opacity-60 group-hover:hover:opacity-100 transition-opacity duration-500 absolute bottom-2 flex-center text-white rounded-[15px] text-[12px] z-10 bg-[#A9A9A9] ${withFullScreen ? 'w-[40px] h-[24px] left-1/2 transform -translate-x-1/2 ' : 'w-[46px] h-[28px] right-2'}`}*/}
          {/*>*/}
          {/*  {currentSlide + 1} / {items.length}*/}
          {/*</div>*/}
        </div>
        <Slider
          ref={(slider: Slider | null) => {
            sliderRef.current = slider;
          }}
          {...sliderSettings}
        >
          {items?.map((image) => (
            <div
              key={image.id}
              onClick={() => withFullScreen && openFancybox()}
              className={`relative outline-none ${wrapperClass || 'aspect-[1.52]'}`}
            >
              <ImageWithFallback
                src={image.url}
                alt={image.name}
                className={`w-full h-full object-cover ${imageClass || 'rounded-[15px]'}`}
                withSkeleton
              />
            </div>
          ))}
        </Slider>
      </div>
      {isFancyboxOpen &&
        ReactDOM.createPortal(
          <div className="group fixed inset-0 bg-black flex flex-col items-center justify-between gap-[10px] px-2.5 py-5 z-[9000] overflow-hidden">
            <div className="flex items-center justify-between w-full py-[2px] px-[30px] bg-gray-900 text-white">
              <div>{data.property}</div>
              {/*<div>*/}
              {/*  {currentSlide + 1} / {items.length}*/}
              {/*</div>*/}
              <div className="flex items-center gap-[30px]">
                {/*{!!data.phone && (*/}
                {/*  <button className="flex-center w-[156px] h-[26px] bg-[#006CFB] rounded-[8px] text-[14px] font-medium">*/}
                {/*    {formatPhoneNumber(data.phone)}*/}
                {/*  </button>*/}
                {/*)}*/}
                <DropdownSimple
                  title={
                    <div className="flex-center w-[26px] h-[26px]">
                      <ShareIcon width={20} height={20} color="#fff" />
                    </div>
                  }
                  menuClass="left-auto right-0"
                >
                  <LinkModal link={fullLink} />
                </DropdownSimple>
                <button
                  className="flex-center w-[26px] h-[26px]"
                  onClick={closeFancybox}
                >
                  <CrossIcon
                    width={18}
                    height={18}
                    color="#fff"
                    strokeWidth={2}
                  />
                </button>
              </div>
            </div>
            <div className="relative w-full flex-1 flex-center py-[30px] overflow-hidden z-50">
              <ImageWithFallback
                src={items[currentSlide]?.url}
                alt={`Slide ${currentSlide}`}
                className="w-full h-full object-contain"
                withSkeleton
              />
              <CustomPrevArrow onClick={prevImage} isLarge={withFullScreen} />,
              <CustomNextArrow onClick={nextImage} isLarge={withFullScreen} />
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default PropertyCarousel;
