import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const EmailIcon: FC<ISvgProps> = ({
  height = 18,
  width = 22,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 4.13965V14.1396C21 14.8027 20.7366 15.4386 20.2678 15.9074C19.7989 16.3763 19.163 16.6396 18.5 16.6396H3.5C2.83696 16.6396 2.20107 16.3763 1.73223 15.9074C1.26339 15.4386 1 14.8027 1 14.1396V4.13965"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 4.13965C21 3.47661 20.7366 2.84072 20.2678 2.37188C19.7989 1.90304 19.163 1.63965 18.5 1.63965H3.5C2.83696 1.63965 2.20107 1.90304 1.73223 2.37188C1.26339 2.84072 1 3.47661 1 4.13965L9.675 9.55632C10.0723 9.80465 10.5314 9.93632 11 9.93632C11.4686 9.93632 11.9277 9.80465 12.325 9.55632L21 4.13965Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
