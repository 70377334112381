import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const LogoIcon: FC<ISvgProps> = ({ height = 36, width = 36 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="512" height="512" rx="100" fill="white" />
      <path
        d="M83 66.68V349.107L158.933 265.359C163.136 260.904 170.297 261.032 174.334 265.642L339.748 446.613H423.644C429.109 446.613 432.022 440.21 428.39 436.14L307.012 301.754C307.15 301.443 307.168 301.406 307.306 301.095H312.108C340.734 301.095 380.558 287.823 399.313 266.374C417.395 245.684 428.823 217.411 429.616 186.074C431.33 118.945 383.046 64 302.818 64L85.2304 64.1189C83.9954 64.128 83 65.2714 83 66.68ZM328.873 211.429C320.329 217.566 308.532 220.804 295.5 220.804H168.96C167.67 220.804 166.629 219.606 166.629 218.134V145.718C166.629 144.245 167.67 143.047 168.96 143.047H295.491C321.555 143.047 342.697 156.905 342.697 180.037C342.706 191.599 337.425 205.291 328.873 211.429ZM249.898 409.687V446.448C249.898 447.857 248.903 449 247.668 449H85.5898C84.3548 449 83.3594 447.857 83.3594 446.448V409.687C83.3594 409.001 83.6083 408.333 84.0414 407.857L165.08 318.118C165.947 317.157 167.32 317.157 168.177 318.118L249.216 407.857C249.649 408.333 249.898 409.001 249.898 409.687Z"
        fill="#006CFB"
      />
    </svg>
  );
};

export default LogoIcon;
