import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { addPriceDelimiters } from '../../utils/helpers';

import Dropdown from '../Dropdown/Dropdown';

interface IRangeValue {
  from: string;
  to: string;
}

interface IPropertyRangeSelectorProps {
  title?: string;
  placeholder?: string;
  placeholderFrom?: string;
  placeholderTo?: string;
  unit?: string;
  defaultValue?: IRangeValue;
  onChange: (value: IRangeValue) => void;
}

const isCorrectValue = (value: IRangeValue): boolean => {
  const { from, to } = value;

  if (!from || !to) return true;

  return +from <= +to;
};

const MAX_LIMIT = 1000000000;

const PropertyRangeSelector: React.FC<IPropertyRangeSelectorProps> = ({
  title = '',
  placeholder = '',
  placeholderFrom = '',
  placeholderTo = '',
  unit = '',
  defaultValue = {
    from: '',
    to: '',
  },
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const { t } = useTranslation();

  const dropdownRef = useRef<any>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const valueToString = () => {
    if (!defaultValue.to && !defaultValue.from) {
      return '';
    }

    if (!isCorrectValue(defaultValue)) {
      return t('incorrect_interval');
    }

    return `${!defaultValue.to?.length ? t('placeholder-from') + ': ' : ''}${defaultValue.from?.length ? addPriceDelimiters(defaultValue.from) + ' ' + unit : t('placeholder-to') + ': '}${defaultValue.from?.length && defaultValue.to?.length ? ' - ' : ' '}${defaultValue.to?.length ? addPriceDelimiters(defaultValue.to) + ' ' + unit : ''}`;
  };

  const handleFromChange: NumericFormatProps['onValueChange'] = (values) => {
    const { value } = values;

    if (+value >= 1000000000) {
      return;
    }

    setValue((prevValue: IRangeValue) => ({
      ...prevValue,
      from: value,
    }));
  };

  const handleToChange: NumericFormatProps['onValueChange'] = (values) => {
    const { value } = values;

    if (+value >= 1000000000) {
      return;
    }

    setValue((prevValue: IRangeValue) => ({
      ...prevValue,
      to: value,
    }));
  };

  const handleDropdownClose = () => {
    if (value.to !== defaultValue.to || value.from !== defaultValue.from) {
      setValue(defaultValue);
    }
  };

  useEffect(() => {
    if (value.to !== defaultValue.to || value.from !== defaultValue.from) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    if (isCorrectValue(value)) {
      onChange(value);
    }
  }, [value, onChange, defaultValue]);

  return (
    <div
      className="cursor-pointer"
      ref={parentRef}
      onClick={() => dropdownRef.current?.toggleDropdown()}
    >
      <h5 className="text-heading-5 text-heading capitalize">{title}</h5>
      <Dropdown
        ref={dropdownRef}
        className="w-[176px] h-[19px]"
        buttonClass={`text-body-smaller pl-0 ${valueToString() ? 'text-body' : 'text-placeholder'}`}
        menuClass="-ml-[25px] mt-[38px]"
        title={valueToString() || placeholder}
        onDropdownClose={handleDropdownClose}
        parentRef={parentRef}
      >
        <div className="flex items-center gap-[20px] w-[490px] p-[25px]">
          <div>
            <div className="text-body-small text-heading mb-1.5">
              {t('from')}
            </div>
            <div className="relative">
              <NumericFormat
                thousandSeparator=" "
                onValueChange={handleFromChange}
                value={value.from || ''}
                step={1}
                className="w-full h-[46px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
                placeholder={placeholderFrom}
                allowNegative={false}
                isAllowed={(values) => {
                  const { value } = values;
                  return +value <= MAX_LIMIT;
                }}
              />
              <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
                {unit}
              </div>
            </div>
          </div>
          <div>
            <div className="text-body-small text-heading mb-1.5">{t('to')}</div>
            <div className="relative">
              <NumericFormat
                thousandSeparator=" "
                onValueChange={handleToChange}
                value={value.to || ''}
                step={1}
                className="w-full h-[46px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
                placeholder={placeholderTo}
                allowNegative={false}
                isAllowed={(values) => {
                  const { value } = values;
                  return +value <= MAX_LIMIT;
                }}
              />
              <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
                {unit}
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default PropertyRangeSelector;
