import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react';

interface IDropdownProps {
  children: ReactNode;
  title?: string;
  className?: string;
  buttonClass?: string;
  menuClass?: string;
  onDropdownClose?: () => void;
  parentRef?: any;
}

export interface IDropdownHandle {
  closeDropdown: () => void;
  openDropdown: () => void;
  toggleDropdown: () => void;
}

const Dropdown = forwardRef<IDropdownHandle, IDropdownProps>(
  (
    {
      children,
      title = 'Toggle Dropdown',
      className = '',
      buttonClass = '',
      menuClass = '',
      onDropdownClose,
      parentRef,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => ({
      closeDropdown: () => {
        setIsOpen(false);
        onDropdownClose?.();
      },
      openDropdown: () => setIsOpen(true),
      toggleDropdown: () => {
        if (isOpen) {
          onDropdownClose?.();
        }
        setIsOpen((prev) => !prev);
      },
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current?.contains(event.target as Node) &&
          !parentRef?.current?.contains(event.target as Node)
        ) {
          onDropdownClose?.();
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onDropdownClose]);

    return (
      <div ref={dropdownRef} className={`relative inline-block ${className}`}>
        <button
          onClick={() => {
            if (isOpen) {
              onDropdownClose?.();
            }
            if (!parentRef) {
              setIsOpen(!isOpen);
            }
          }}
          className={`flex items-center justify-between w-full h-full pr-[26px] cursor-pointer ${buttonClass}`}
        >
          <div className="truncate leading-[20px]">{title}</div>
          <div
            className={`absolute top-1/2 right-[10px] transform -translate-y-1/2 rotate-45 w-[8px] h-[8px] border-r border-b border-heading -mt-[2px]`}
          />
        </button>
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`absolute top-full left-0 z-[1000] ${isOpen ? '' : 'hidden'} min-w-full bg-white p-0 mt-[15px] rounded-[15px] shadow-2 overflow-hidden fade-in animation-fast ${menuClass}`}
        >
          <div className="">{children}</div>
        </div>
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
