import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAuthStore from '../../common/stores/useAuthStore';

import ProfileTip from '../../common/components/ProfileTip/ProfileTip';
import Input from '../../common/components/Input/Input';
import Button from '../../common/components/Button/Button';

import useFormChangePassword from '../../features/profile/hooks/useFormChangePassword';

import PasswordStrength from '../../features/auth/components/PasswordStrength';

import CodeIcon from '../../common/assets/icons/CodeIcon';

const SecurityPage = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const { user } = useAuthStore();

  const { control, handleSubmit, newPassword, isValid } =
    useFormChangePassword();

  const isExternalAuth =
    user?.googleAccountId || user?.facebookAccountId || user?.appleAccountId;

  return (
    <div className="grid grid-cols-[1fr_342px] pb-[45px]">
      <div className="flex flex-col gap-[30px] px-[25px]">
        <h3 className="text-heading-5 text-heading">
          {t('auth_and_security')}
        </h3>
        {isExternalAuth ? (
          <div>
            {user?.googleAccountId
              ? t('signed_by_google')
              : user?.facebookAccountId
                ? t('signed_by_facebook')
                : t('signed_by_apple')}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
            <h4 className="text-heading-6 text-heading">
              {t('change_password')}
            </h4>
            <div className="max-w-[420px]">
              <Input
                type="password"
                label={t('current_password_label')}
                name="currentPassword"
                control={control}
                placeholder={t('current_password_placeholder')}
              />
            </div>
            <div className="max-w-[420px]">
              <Input
                type="password"
                label={t('new_password_label')}
                name="newPassword"
                control={control}
                placeholder={t('new_password_placeholder')}
              />
            </div>
            <div>
              <PasswordStrength
                password={newPassword}
                setValidPassword={setValidPassword}
              />
            </div>
            <div className="max-w-[420px]">
              <Input
                type="password"
                label={t('confirm_password_label')}
                name="confirmNewPassword"
                control={control}
                placeholder={t('confirm_password_placeholder')}
              />
            </div>
            <div className="flex justify-end">
              <Button
                withCooldown
                disabled={!isValid || !validPassword}
                type="submit"
              >
                {t('change_password')}
              </Button>
            </div>
          </form>
        )}
      </div>
      <div className="flex flex-col gap-[25px]">
        <ProfileTip icon={<CodeIcon />} text={t('security_help_message_1')} />
      </div>
    </div>
  );
};

export default SecurityPage;
