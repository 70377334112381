import { create } from 'zustand';

interface IConfirmEmailStore {
  email: string;
  setEmail: (email: string) => void;
}

const useConfirmEmailStore = create<IConfirmEmailStore>((set) => ({
  email: '',
  setEmail: (email: string) =>
    set(() => ({
      email,
    })),
}));

export default useConfirmEmailStore;
