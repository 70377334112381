import { useTranslation } from 'react-i18next';

import Container from '../../../common/components/Container/Container';

import PropertySearchBar from '../../../common/components/PropertySearchBar/PropertySearchBar';

const PropertySearchSection = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-[56px] pb-[85px] bg-primary">
      <Container>
        {/* Блок только для десктопа (от lg) */}
        <h1 className="flex flex-col text-display-3 text-white mb-0 lg:mb-[45px] fade-in">
          <span>{t('main-header-1-1')}</span>
          <span>{t('main-header-1-2')}</span>
        </h1>
        {/* Показывать поиск только на lg и выше, и поднимать его над всем */}
        <div className="relative z-[99]">
          <PropertySearchBar />
        </div>
      </Container>
    </div>
  );
};

export default PropertySearchSection;
