import { create } from 'zustand';
import Cookies from 'universal-cookie';

import { getLocaleFromUrl } from '../../api/axios';

import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
} from '../constants/cookies';

import { IUser } from '../types/dto/user';

interface IAuthStore {
  accessToken?: string | null;
  setAccessToken: (accessToken?: string | null) => void;
  refreshToken?: string | null;
  setRefreshToken: (refreshToken?: string | null) => void;
  user?: IUser | null;
  setUser: (user?: IUser | null) => void;
  updateUserPartially: (partialUser: Partial<IUser>) => void;
  logout: () => void;
  impersonationToken?: string | null;
  setImpersonationToken: (impersonationToken?: string | null) => void;
}

const cookies = new Cookies();
const cookieOptions = { path: '/' };

const useAuthStore = create<IAuthStore>((set) => ({
  accessToken: cookies.get(COOKIE_ACCESS_TOKEN) || null,
  setAccessToken: (accessToken) => {
    set((prevState) => ({
      ...prevState,
      accessToken,
    }));
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken, cookieOptions);
  },
  refreshToken: cookies.get(COOKIE_REFRESH_TOKEN) || null,
  setRefreshToken: (refreshToken) => {
    set((prevState) => ({
      ...prevState,
      refreshToken,
    }));
    cookies.set(COOKIE_REFRESH_TOKEN, refreshToken, cookieOptions);
  },
  user: null,
  setUser: (user) =>
    set((prevState) => ({
      ...prevState,
      user,
    })),
  updateUserPartially: (partialUser: Partial<IUser>) => {
    set((prevState) => {
      if (!prevState.user) {
        return prevState;
      }
      return {
        ...prevState,
        user: {
          ...prevState.user,
          ...partialUser,
        },
      };
    });
  },
  logout: () => {
    set((prevState) => ({
      ...prevState,
      accessToken: null,
      refreshToken: null,
      user: null,
    }));

    try {
      cookies.remove(COOKIE_ACCESS_TOKEN, cookieOptions);
      cookies.remove(COOKIE_REFRESH_TOKEN, cookieOptions);
    } catch (error) {
      console.error('Error removing cookies:', error);
    }

    const lang = getLocaleFromUrl();

    window?.location.replace(`/${lang}`);
  },
  impersonationToken: null,
  setImpersonationToken: (impersonationToken) =>
    set((prevState) => ({
      ...prevState,
      impersonationToken,
    })),
}));

export default useAuthStore;
