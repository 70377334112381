import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ChevronIcon: FC<ISvgProps> = ({
  height = 12,
  width = 7,
  color = 'white',
  strokeWidth = 1,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.61873 0.424835L1.07983 5.77367L6.44337 11.3278"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default ChevronIcon;
