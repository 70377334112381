import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TabSwitch, {
  ITabOption,
} from '../../../common/components/Switch/TabSwitch';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import LogoIcon from '../../../common/assets/icons/LogoIcon';

const SiteBlock = () => {
  const [isRegister, setIsRegister] = useState(false);

  const { lang } = useParams();
  const { t } = useTranslation();

  const tabs: ITabOption[] = [
    { title: t('sign_in'), action: () => setIsRegister(false) },
    { title: t('sign_up'), action: () => setIsRegister(true) },
  ];

  return (
    <div className="flex flex-col justify-between items-center gap-[8px] pt-[25px] pb-[7px]">
      <div className="relative w-full pb-[44px] flex flex-col items-center">
        <LogoIcon width={160} height={56} className="text-primary" />
        <button
          className="mt-5 text-primary"
          onClick={() => window.history.back()}
        >
          {t('return_to_site')}
        </button>
      </div>
      <div className="w-[420px]">
        <div className="mb-[55px]">
          <TabSwitch options={tabs} isLarge />
        </div>
        <div>{isRegister ? <RegistrationForm /> : <LoginForm />}</div>
      </div>
      <div className="pt-[44px]">
        <div className="w-2/3 text-[#9C9AA5] text-[12px] text-center mx-auto">
          {t('login_text_accept')}
          <Link to={`/${lang}/documents`}>
            <span className="text-[#26203B] text-[12px]">
              {t('link_terms')}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SiteBlock;
