import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../common/components/Button/Button';

// import { ReactComponent as GooglePlayIcon } from '../../../common/assets/icons/googlePlayIcon.svg';
import { ReactComponent as AppleIcon } from '../../../common/assets/icons/appleIcon.svg';
// import { ReactComponent as AndroidQRCodeImage } from '../../../common/assets/images/androidQRCodeImage.svg';
import { ReactComponent as AppleQRCodeImage } from '../../../common/assets/images/appleQRCodeImage.svg';

const DownloadApp = () => {
  const [qrCodeApple, setQrCodeApple] = useState(false);
  // const [qrCodeAndroid, setQrCodeAndroid] = useState(false);

  const { t } = useTranslation();

  const handleAppleQRCode = () => {
    setQrCodeApple(true);
  };

  // const handleAndroidQRCode = () => {
  //   setQrCodeAndroid(true);
  // };

  const handleAppleDownload = () => {
    window.open('https://apps.apple.com/us/app/reelty/id6602896278', '_blank');
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-[40px] py-[80px] sm:py-[120px] md:py-[160px]">
      <div className="flex-center max-h-[530px] xl:aspect-[1.11] p-[20px] sm:p-[65px] rounded-[20px] bg-primary text-white">
        {qrCodeApple ? (
          <div>
            <div className="flex-center w-[289px] h-[289px] rounded-[20px] bg-white mb-8 fade-in">
              <AppleQRCodeImage />
            </div>
            <div>
              <Button
                variant="secondary-master"
                size="large"
                className="w-[289px] text-200 text-primary"
                onClick={handleAppleDownload}
              >
                <div>
                  <AppleIcon />
                </div>
                {t('download_for_apple_button')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="max-w-[450px] fade-in">
            <h2 className="text-heading-2 text-white mb-4">
              {t('download_for_apple')}
            </h2>
            <div className="mb-6">{t('download_for_apple_description')}</div>
            <div className="flex items-center gap-[18px] flex-wrap">
              <Button
                variant="secondary-master"
                onClick={handleAppleDownload}
                size="large"
                className="text-200 text-primary max-w-full sm:max-w-[245px]"
              >
                <div>
                  <AppleIcon />
                </div>
                {t('download_for_apple_button')}
              </Button>
              <br />
              <div className="text-center sm:text-left">{t('or')}</div>
              <button onClick={handleAppleQRCode} className="font-bold">
                {t('QR_code')}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex-center max-h-[530px] xl:aspect-[1.11] p-[20px] sm:p-[65px] rounded-[20px] bg-white border border-[#D9D9D9]">
        <div className="max-w-[450px] fade-in">
          <h2 className="text-heading-2 text-primary mb-4">
            {t('download_for_android')}
          </h2>
          <div className="mb-6">{t('download_for_android_description')}</div>
          <div className="flex items-center gap-[18px] flex-wrap">
            {t('coming_soon')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
