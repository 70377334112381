import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { postAuthRegister } from '../../../api/services/auth/requests';

import { IRegistration } from '../types/form';

import { registrationFormSchema } from '../validations/schema';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

const useFormRegistration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setAccessToken, setRefreshToken } = useAuthStore((state) => state);
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();

  const { mutateAsync } = useMutation((payload: IRegistration) =>
    postAuthRegister(payload),
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<IRegistration>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    resolver: zodResolver(registrationFormSchema),
  });

  const password = watch('password');

  const onSubmit = async (value: IRegistration) => {
    try {
      setLoader(true);
      const { accessToken, refreshToken } = await mutateAsync({
        ...value,
      });
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      navigate('/');
    } catch (error: any) {
      const needConfirmEmail =
        error?.response?.data?.data?.name === 'EmailConfirmException';

      if (needConfirmEmail) {
        setLoader(false);
        navigate(`/verify-email?email=${btoa(value.email)}`);
      } else {
        setError(error?.response?.data?.message || t('login_failed'));
      }
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    password,
    isValid,
  };
};

export default useFormRegistration;
