import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../../common/utils/helpers';

import usePropertyStore from '../../../common/stores/usePropertyStore';
import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';
import useAuthStore from '../../../common/stores/useAuthStore';

import usePropertyCategories from '../../../common/hooks/usePropertyCategories';
import usePropertySearchSave from '../../../common/hooks/usePropertySearchSave';
import useSelectedCategorySchema from '../../../common/hooks/useSelectedCategorySchema';
import usePropertyCategoriesSchemes from '../../../common/hooks/usePropertyCategoriesSchemes';

import PropertySearchBar from '../../../common/components/PropertySearchBar/PropertySearchBarMap';
import PropertySort from '../../../common/components/PropertySort/PropertySort';

import PropertyMapItem from './PropertyMapItem';

import ListIcon from '../../../common/assets/icons/ListIcon';

import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';
import { ReactComponent as SaveSearchIcon } from '../../../common/assets/icons/saveSearch.svg';

const PropertyMapList = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { properties, total, refetch } = usePropertyStore();
  const { propertyTypes, area, rooms, bedRooms, address, filters } =
    usePropertySearchStore();
  const { user } = useAuthStore();

  const { categories } = usePropertyCategories();
  const { handleSaveSearch } = usePropertySearchSave();
  const { roomsOptions, bedRoomsOptions } = useSelectedCategorySchema();
  const { processFilters } = usePropertyCategoriesSchemes();

  const { description } = processFilters(filters);

  const propertiesMap = properties.map((item) => (
    <PropertyMapItem
      key={item.id}
      item={item}
      refetch={refetch as () => void}
      isLoggedIn={!!user}
    />
  ));

  const roomNames = roomsOptions
    .filter((option: any) => rooms.includes(option.id))
    .map((option: any) => option.label);

  const bedRoomNames = bedRoomsOptions
    .filter((option: any) => bedRooms.includes(option.id))
    .map((option: any) => option.label);

  const entities = getSubTree(categories, propertyTypes.path)?.entities || [];
  const entityName =
    entities.find((ent) => ent.id === propertyTypes.entities[0]?.id)?.title ||
    '';

  const titleString = [
    propertyTypes.path[0] === 'rent' ? t('rent') : t('sale'),
    entityName || '',
    roomNames.length ? roomNames.join(', ') : '',
    bedRoomNames.length ? bedRoomNames.join(', ') : '',
    area.from ? `${area.from} m²` : '',
    address?.formattedAddress ? address.formattedAddress : '',
  ]
    .filter(Boolean)
    .join(', ');

  const handleListRedirect = () => {
    navigate(
      `/${lang}/search/${propertyTypes.path[0] === 'rent' ? 'rent' : 'buy'}`,
    );
  };

  return (
    <div className="h-[calc(100vh-85px)] px-[55px] py-[25px] overflow-auto scrollbar-none">
      <div className="min-w-[490px]">
        <div className="flex flex-col gap-[10px] mb-[35px]">
          <div>
            <PropertySearchBar />
          </div>
          <div>
            <h2 className="text-display-4 text-heading">{titleString}</h2>
          </div>
          <div>{description}</div>
          <div className="flex items-center flex-wrap gap-[17px]">
            <PropertySort menuClass="left-0" />
            <button
              onClick={handleListRedirect}
              className="flex-center gap-[5px] h-[25px] text-accent font-medium underline"
            >
              <ListIcon width={16} height={16} color="#006CFB" />
              <span>{t('list_view')}</span>
            </button>
            <button
              onClick={handleSaveSearch}
              className={`flex-center gap-[5px] h-[25px] text-accent font-medium underline`}
            >
              <SaveSearchIcon />
              <span>{t('save_search')}</span>
            </button>
          </div>
          <div>
            <div>
              {total} {t('listings_found')}
            </div>
          </div>
        </div>
        {propertiesMap.length === 0 ? (
          <div className={'flex flex-col items-center gap-[46px] pt-[10px]'}>
            <EmptyListImage width={292} height={272} />
            <div className="fade-in">{t('empty_list')}</div>
          </div>
        ) : (
          <div className="flex flex-col gap-[35px]">{propertiesMap}</div>
        )}
      </div>
    </div>
  );
};

export default PropertyMapList;
