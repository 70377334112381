import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { editAccountFormSchema } from '../validations/schema';

import {
  patchUpdateAgent,
  patchUpdateClient,
} from '../../../api/services/user/requests';

import {
  IPatchUpdateAgentPayload,
  IPatchUpdateClientPayload,
} from '../../../api/services/user/types';
import { EUserRole } from '../../../common/types/dto/user';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useNotificationStore from '../../../common/stores/useNotificationStore';

const useFormEditAccount = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { user, setUser } = useAuthStore();
  const { addNotification } = useNotificationStore();

  // const [avatar, setAvatar] = useState((user?.avatar as any) || null);

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    about: '',
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<any>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver(editAccountFormSchema),
  });

  const onSubmit = async (value: any) => {
    setLoader(true);
    // let avatarData;
    // if (avatar?.toUpload) {
    //   try {
    //     avatarData = await postMediaUploadImage({ image: avatar.file });
    //   } catch (error: any) {
    //     setError(error.response.data.message);
    //     setLoader(false);
    //     return;
    //   }
    // } else {
    //   avatarData = avatar;
    // }

    const requestFunction =
      user?.role === EUserRole.Agent ? patchUpdateAgent : patchUpdateClient;

    const payload: IPatchUpdateAgentPayload | IPatchUpdateClientPayload = {
      name: value.name,
      // avatar: avatarData?.id || null,
      phone: value.phone,
      ...(user?.role === EUserRole.Agent && {
        profile: {
          businessType: 'enterpreter',
        },
      }),
    };

    try {
      setLoader(true);
      const user = await requestFunction(payload as IPatchUpdateAgentPayload);
      setUser(user);
      addNotification('Update successful!', 'success');
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
    // avatar,
    // setAvatar,
  };
};

export default useFormEditAccount;
