import { useEffect, useState } from 'react';

import usePropertySearchStore from '../../common/stores/usePropertySearchStore';

import Container from '../../common/components/Container/Container';

import PropertySearchSection from '../../features/main/components/PropertySearchSection';
// import Statistics from '../../features/main/components/Statistics';
import BestOffers from '../../features/main/components/BestOffers';
import JoinPlatform from '../../features/main/components/JoinPlatform';
import DownloadApp from '../../features/main/components/DownloadApp';

const MainPage = () => {
  const [isInitial, setIsInitial] = useState(true);
  const [resetKey, setResetKey] = useState(0);

  const { reset } = usePropertySearchStore();

  useEffect(() => {
    reset();
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsInitial(false);
      setResetKey((prevKey) => prevKey + 1);
    }, 0);
  }, [reset]);

  return (
    !isInitial && (
      <div>
        <PropertySearchSection key={resetKey} />
        <Container>
          {/*<Statistics />*/}
          <BestOffers />
        </Container>
        <Container>
          <DownloadApp />
        </Container>
        <JoinPlatform />
      </div>
    )
  );
};

export default MainPage;
