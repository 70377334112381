import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';
import useSelectedCategorySchema from '../../hooks/useSelectedCategorySchema';

import PropertyTypeSelector from './PropertyTypeSelector';
import PropertyRoomsSelector from './PropertyRoomsSelector';
import PropertyRangeSelector from './PropertyRangeSelector';
import PropertyFilters from './PropertyFilters';
import PropertyAddressInput from './PropertyAddressInput';

const PropertySearchBar: React.FC = () => {
  const { t } = useTranslation();
  const {
    propertyTypes,
    area,
    setArea,
    price,
    setPrice,
    setRooms,
    setBedRooms,
  } = usePropertySearchStore();

  const { schema, roomsOptions, bedRoomsOptions } = useSelectedCategorySchema();

  const entityName = propertyTypes.entities[0]?.name || '';

  const showRooms = roomsOptions.length > 0 && entityName.includes('apartment');
  const showBedrooms =
    bedRoomsOptions.length > 0 && entityName.includes('house');

  const showPrice =
    entityName.includes('commercial') ||
    entityName.includes('apartment') ||
    entityName.includes('house') ||
    entityName.includes('room') ||
    entityName.includes('garage');

  const showArea = entityName.includes('commercial');

  useEffect(() => {
    if (!showRooms) setRooms([]);
  }, [showRooms, setRooms]);

  useEffect(() => {
    if (!showBedrooms) setBedRooms([]);
  }, [showBedrooms, setBedRooms]);

  return (
    <>
      {/* Мобильная версия (до sm):
          Высота 88px, в левом углу - selector, в правом - фильтр */}
      <div className="block sm:hidden">
        <div className="w-full h-[88px] bg-white border border-primary rounded-[15px] px-[25px] flex items-center justify-between">
          <PropertyTypeSelector withTopType />
          <PropertyFilters groups={schema.groups} />
        </div>
      </div>

      {/* Десктопная версия (от sm): полный бар */}
      <div className="hidden sm:flex flex-1 items-center justify-between gap-[50px] h-[88px] bg-white border border-primary rounded-[15px] px-[25px]">
        <div className="flex items-center gap-[24px] w-full">
          <PropertyTypeSelector withTopType />
          <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
          {showPrice && (
            <>
              <PropertyRangeSelector
                title={t('price')}
                placeholder={t('placeholder-price')}
                placeholderFrom="0"
                placeholderTo="150 000"
                unit="Kč"
                defaultValue={price}
                onChange={setPrice}
              />
              <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            </>
          )}
          {showArea && (
            <>
              <PropertyRangeSelector
                title={t('area')}
                placeholder={t('placeholder-area')}
                placeholderFrom="0"
                placeholderTo="250"
                unit="m²"
                defaultValue={area}
                onChange={setArea}
              />
              <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            </>
          )}
          {showRooms && (
            <>
              <PropertyRoomsSelector options={roomsOptions} />
              <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            </>
          )}
          {showBedrooms && (
            <>
              <PropertyRoomsSelector options={bedRoomsOptions} isBedRooms />
              <div className="bg-[#D9D9D9] h-[48px] w-[1px]" />
            </>
          )}
          <PropertyAddressInput />
        </div>
        <div>
          <PropertyFilters groups={schema.groups} />
        </div>
      </div>
    </>
  );
};

export default PropertySearchBar;
