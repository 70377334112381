import { FC } from 'react';

import { ISvgProps } from '../../../types/media';

const FeatureIcon: FC<ISvgProps> = ({
  height = 22,
  width = 24,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40645 7.03784L11.1412 1.53047C11.2208 1.37108 11.3432 1.23702 11.4948 1.14333C11.6463 1.04963 11.8209 1 11.9991 1C12.1772 1 12.3519 1.04963 12.5034 1.14333C12.6549 1.23702 12.7774 1.37108 12.857 1.53047L15.5917 7.03784L21.7054 7.92626C21.8818 7.95072 22.0477 8.02426 22.1842 8.13849C22.3208 8.25272 22.4225 8.40304 22.4777 8.57229C22.5329 8.74155 22.5394 8.92291 22.4965 9.09569C22.4535 9.26847 22.3629 9.42569 22.2349 9.54943L17.8117 13.8336L18.8559 19.8863C18.9896 20.6631 18.1686 21.2547 17.4665 20.8884L11.9991 18.0294L6.53065 20.8884C5.8296 21.2558 5.00855 20.6631 5.14223 19.8852L6.18644 13.8326L1.76328 9.54837C1.6359 9.42456 1.5458 9.26749 1.50324 9.09502C1.46068 8.92255 1.46735 8.7416 1.52251 8.57273C1.57766 8.40387 1.67909 8.25387 1.81525 8.13977C1.95141 8.02568 2.11684 7.95207 2.29275 7.92732L8.40645 7.03784Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FeatureIcon;
