import { useMemo } from 'react';
import { IPropertySearch } from '../../types/dto/property';
import useAuthStore from '../../stores/useAuthStore';

import PropertyTile from './PropertyTile';
import PropertyListItem from './PropertyListItem';

interface IPropertyListProps {
  items: IPropertySearch[];
  refetch?: () => void;
  isTiles?: boolean;
}

const PropertyList: React.FC<IPropertyListProps> = ({
  items = [],
  refetch,
  isTiles = false,
}) => {
  const { user } = useAuthStore();

  const itemsTilesMap = useMemo(
    () =>
      items.map((item) => (
        <PropertyTile
          key={item.id}
          item={item}
          refetch={refetch}
          isLoggedIn={!!user}
        />
      )),
    [items, refetch, user],
  );

  const itemsListMap = useMemo(
    () =>
      items.map((item) => (
        <PropertyListItem
          key={item.id}
          item={item}
          refetch={refetch}
          isLoggedIn={!!user}
        />
      )),
    [items, refetch, user],
  );

  return isTiles ? (
    // Для мобильных устройств (<768px) одна колонка, начиная с md (>=768px) — 4 колонки
    <div className="grid grid-cols-1 gap-[35px] md:grid-cols-4">
      {itemsTilesMap}
    </div>
  ) : (
    <div className="flex flex-col gap-[45px]">{itemsListMap}</div>
  );
};

export default PropertyList;
