import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  postPropertySearchSave,
  putPropertySearchUpdate,
} from '../../api/services/property/requests';

import { ESortField, ESortOrder } from '../../api/services/property/types';

import useAuthStore from '../stores/useAuthStore';
import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';
import useGlobalModalStore from '../stores/useGlobalModalStore';
import usePropertySearchStore from '../stores/usePropertySearchStore';

import SaveSearchModal from '../components/modals/SaveSearchModal';

const generateUniqueName = (): string => {
  const timestamp = Date.now();
  const randomId = Math.random().toString(36).substring(2, 10);
  return `Search-${timestamp}-${randomId}`;
};

const usePropertySearchSave = (refetch?: any) => {
  const navigate = useNavigate();
  const { lang } = useParams();

  const { user } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();

  const saveSearch = async (name?: string, notificationInterval?: number) => {
    const currentState = usePropertySearchStore.getState();
    const {
      propertyTypes,
      area,
      price,
      osmId,
      sort,
      rooms,
      bedRooms,
      filters,
      byOwner,
    } = currentState;

    const categories = propertyTypes.entities.map((entity) => entity.id);
    const sortParams =
      sort === 'default' ? ['createdAt', 'DESC'] : sort.split('_');
    const sortField: ESortField = sortParams[0] as ESortField;
    const sortOrder: ESortOrder = sortParams[1] as ESortOrder;

    try {
      setLoader(true);

      const payload = {
        name: name || generateUniqueName(),
        notificationInterval: notificationInterval || null,
        ...filters,

        ...(byOwner && { byOwner: byOwner }),

        sortField,
        sortOrder,

        ...(osmId?.length && { osmIds: osmId }),
        ...(categories.length && { categories }),
        ...(rooms.length && { FIELD_roomsCountFlat: rooms }),
        ...(bedRooms.length && { FIELD_roomsCountHouse: bedRooms }),
        ...(area.from?.length && { FIELD_totalAreaFrom: area.from }),
        ...(area.to?.length && { FIELD_totalAreaTo: area.to }),
        ...(price.from?.length && { priceFrom: Number(price.from) }),
        ...(price.to?.length && { priceTo: Number(price.to) }),
      };

      await postPropertySearchSave(payload);
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const editSearch = async (
    id: string,
    name?: string,
    notificationInterval?: number,
  ) => {
    const currentState = usePropertySearchStore.getState();
    const {
      propertyTypes,
      area,
      price,
      osmId,
      sort,
      rooms,
      bedRooms,
      filters,
      byOwner,
    } = currentState;

    const categories = propertyTypes.entities.map((entity) => entity.id);
    const sortParams =
      sort === 'default' ? ['createdAt', 'DESC'] : sort.split('_');
    const sortField: ESortField = sortParams[0] as ESortField;
    const sortOrder: ESortOrder = sortParams[1] as ESortOrder;

    try {
      setLoader(true);

      const payload = {
        name: name || generateUniqueName(),
        notificationInterval: notificationInterval || 0,
        ...filters,

        ...(byOwner && { byOwner: byOwner }),

        sortField,
        sortOrder,

        ...(osmId?.length && { osmIds: osmId }),
        ...(categories.length && { categories }),
        ...(rooms.length && { FIELD_roomsCountFlat: rooms }),
        ...(bedRooms.length && { FIELD_roomsCountHouse: bedRooms }),
        ...(area.from?.length && { FIELD_totalAreaFrom: area.from }),
        ...(area.to?.length && { FIELD_totalAreaTo: area.to }),
        ...(price.from?.length && { priceFrom: Number(price.from) }),
        ...(price.to?.length && { priceTo: Number(price.to) }),
      };

      await putPropertySearchUpdate(id, payload);
      if (refetch) {
        refetch();
      }
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const handleSaveSearch = () => {
    if (!user) {
      navigate(`/${lang}/auth`);
      return;
    }

    setChildren(React.createElement(SaveSearchModal, { saveSearch }));
    setIsOpen(true);
  };

  const handleEditSearch = (
    id: string,
    name: string,
    notificationInterval: number | null,
  ) => {
    if (!user) {
      navigate(`/${lang}/auth`);
      return;
    }

    const defaultValues = {
      name,
      notificationInterval,
    };

    setChildren(
      React.createElement(SaveSearchModal, {
        saveSearch: (name: string, notificationInterval: number) =>
          editSearch(id, name, notificationInterval),
        defaultValues,
        isEdit: true,
      }),
    );
    setIsOpen(true);
  };

  return {
    handleSaveSearch,
    handleEditSearch,
  };
};

export default usePropertySearchSave;
