import React, { useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { headerMenuItems, userMenuItems } from '../../constants/menu';

import useAuthStore from '../../stores/useAuthStore';
import useGlobalModalStore from '../../stores/useGlobalModalStore';
import MarketingBanner from '../MarketingBanner';

import Menu from '../Menu/Menu';
import Container from '../Container/Container';
import LangSwitcher from '../LangSwitcher/LangSwitcher';
import Button from '../Button/Button';
import DropdownSimple from '../Dropdown/DropdownSimple';
import ImageWithFallback from '../Image/ImageWithFallback';

import CrossIcon from '../../assets/icons/CrossIcon';

import { ReactComponent as BurgerIcon } from '../../assets/icons/burgerIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import MessagesModal from '../modals/MessagesModal';

const Header = () => {
  const [isReady, setIsReady] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const navigate = useNavigate();
  const { lang } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { accessToken, user, logout } = useAuthStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();

  const handleLoginRedirect = () => {
    navigate(`/${lang}/auth`);
  };

  const toggleBurgerOpen = () => {
    setIsBurgerOpen((isBurgerOpen) => !isBurgerOpen);
  };

  const isActivePath = (href: string): boolean => {
    if (href === '') {
      return pathname === `/${lang}/` || pathname === `/${lang}`;
    }

    const regex = new RegExp(`^/${lang}${href}(?:/|$)`);
    return regex.test(pathname);
  };

  const menuItemsMap = headerMenuItems
    .filter(({ href }) => !(window.innerWidth < 640 && href === '/map')) // Исключаем "Mapa" на мобильных
    .map(({ label, href }) => {
      return (
        <Link key={label} to={`/${lang}${href}`}>
          <div
            onClick={() => setIsBurgerOpen(false)}
            className={`${
              isActivePath(href) ? 'bg-secondary' : 'bg-transparent'
            } flex items-center px-[33px] h-[42px] text-200 text-body hover:bg-secondary`}
            title={t(label)}
          >
            {t(label)}
          </div>
        </Link>
      );
    });

  const handleOpenMessages = () => {
    setChildren(<MessagesModal />);
    setIsOpen(true);
  };

  useLayoutEffect(() => {
    document.fonts.ready.finally(() => {
      setIsReady(true);
    });
  }, []);

  return (
    <header className="relative z-[100] bg-primary">
      <MarketingBanner />
      <Container>
        <div className="flex justify-between items-center h-[85px] fade-in">
          <div>
            <Link to={`/${lang}`}>
              <Logo width={134} height={47} />
            </Link>
          </div>
          {isReady && (
            <div className="hidden lg:flex items-center gap-[32px]">
              <div className="hidden lg:block">
                <Menu items={headerMenuItems} />
              </div>
              <div className="hidden lg:flex">
                <LangSwitcher />
              </div>
              {accessToken ? (
                <DropdownSimple
                  title={
                    <div className="flex-center w-[24px] h-[24px]">
                      <UserIcon />
                    </div>
                  }
                  menuClass="left-auto right-0"
                >
                  <div className="flex flex-col gap-[15px] min-w-[305px] p-[25px]">
                    <div className="flex items-center gap-[5px]">
                      <div>
                        {!user?.avatar ? (
                          <div className="flex-center rounded-full w-[44px] h-[44px] bg-primary text-heading-2 text-white">
                            {user?.name.charAt(0)}
                          </div>
                        ) : (
                          <ImageWithFallback
                            className="rounded-full w-[44px] h-[44px]"
                            src={user.avatar.url}
                            alt="avatar"
                            width={44}
                            height={44}
                          />
                        )}
                      </div>
                      <div className="flex flex-col justify-center gap-[4px]">
                        <div className="text-100 font-bold">{user?.name}</div>
                        <div className="text-body-smaller">{user?.email}</div>
                      </div>
                    </div>
                    <div className="w-full h-[1px] bg-[#D9D9D9]" />
                    <div className="flex flex-col gap-[10px]">
                      {userMenuItems.map((item) => {
                        const { label, href } = item;

                        if (href === 'messages') {
                          return (
                            <div
                              key={href}
                              className={`relative inline-block cursor-pointer ${isActivePath(href) ? 'text-accent' : ''} hover:text-accent`}
                              onClick={handleOpenMessages}
                            >
                              {t(label)}
                            </div>
                          );
                        }

                        return (
                          <Link key={label} to={`/${lang}${href}`}>
                            <div
                              className={`relative inline-block ${isActivePath(href) ? 'text-accent' : ''} hover:text-accent`}
                              title={t(label)}
                            >
                              {t(label)}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                    <div className="w-full h-[1px] bg-[#D9D9D9]" />
                    <div>
                      <button onClick={logout} className="hover:text-accent">
                        {t('logout')}
                      </button>
                    </div>
                  </div>
                </DropdownSimple>
              ) : (
                <Button
                  onClick={handleLoginRedirect}
                  variant="secondary-master"
                  size="custom"
                  className="min-w-[108px] lg:min-w-[125px] h-[36px] px-[24px] text-[16px] leading-[18px] rounded-[15px]"
                >
                  {t('sign_in')}
                </Button>
              )}
            </div>
          )}
          <button onClick={toggleBurgerOpen} className="flex-center lg:hidden">
            {isBurgerOpen ? (
              <CrossIcon color="white" strokeWidth={2} />
            ) : (
              <BurgerIcon />
            )}
          </button>
        </div>
      </Container>
      <div
        className={`fixed top-0 left-0 bottom-0 w-[265px] py-[23px] bg-white transition-transform duration-500 transform ${isBurgerOpen ? 'translate-x-0' : '-translate-x-[265px]'}`}
      >
        {menuItemsMap}
        <div className="flex items-center px-[33px] h-[42px]">
          <LangSwitcher isBurger />
        </div>
      </div>
    </header>
  );
};

export default Header;
