import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';
import React from 'react';

const AreaHardcodedField = () => {
  const { t } = useTranslation();

  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (values: any) => {
    setFilters({ FIELD_totalAreaFrom: values.value });
  };

  const handleToChange = (values: any) => {
    setFilters({ FIELD_totalAreaTo: values.value });
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <h5 className="text-heading-5 text-heading">{t('placeholder-area')}</h5>
      <div className="grid grid-cols-2 gap-[20px]">
        <div>
          <div className="text-body-small text-[#9997A8] mb-[6px]">
            {t('from')}
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleFromChange}
              value={filters[`FIELD_totalAreaFrom`] || ''}
              placeholder="0"
              className="w-full h-[66px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
              m²
            </div>
          </div>
        </div>
        <div>
          <div className="text-body-small text-[#9997A8] mb-[6px]">
            {t('to')}
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleToChange}
              value={filters[`FIELD_totalAreaTo`] || ''}
              placeholder="10 000"
              className="w-full h-[66px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
              m²
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaHardcodedField;
