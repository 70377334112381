import React from 'react';
import { useTranslation } from 'react-i18next';

import useAuthStore from '../../common/stores/useAuthStore';

import ImageWithFallback from '../../common/components/Image/ImageWithFallback';
import ProfileTip from '../../common/components/ProfileTip/ProfileTip';

import EmailIcon from '../../common/assets/icons/EmailIcon2';
import ShieldCheckIcon from '../../common/assets/icons/ShieldCheckIcon';
import ShieldCrossIcon from '../../common/assets/icons/ShieldCrossIcon';
import PhoneIcon from '../../common/assets/icons/PhoneIcon2';

import { ReactComponent as AvatarIcon } from '../../common/assets/icons/avatarIcon.svg';

const MyProfilePage = () => {
  const { t } = useTranslation();

  const { user } = useAuthStore();

  return (
    <div className="grid grid-cols-[1fr_342px] pb-[45px]">
      <div className="flex flex-col gap-[30px] px-[25px]">
        <div className="flex items-center gap-[30px]">
          <div>
            {!user?.avatar ? (
              <div className="flex-center rounded-full w-[100px] h-[100px] bg-secondary">
                <AvatarIcon />
              </div>
            ) : (
              <ImageWithFallback
                className="rounded-full w-[100px] h-[100px]"
                src={user.avatar.url}
                alt="avatar"
                width={100}
                height={100}
              />
            )}
          </div>
          <div className="text-body-large text-black">{user?.name}</div>
        </div>
        <div className="flex flex-col gap-[15px]">
          <div className="flex items-center gap-[10px]">
            <div className="flex items-center gap-[5px]">
              <div className="flex-center w-[24px] h-[24px]">
                <EmailIcon />
              </div>
              <div>{user?.email}</div>
            </div>
            <div className="flex-center w-[24px] h-[24px]">
              {user?.isEmailConfirmed ? (
                <ShieldCheckIcon width={24} height={24} />
              ) : (
                <ShieldCrossIcon />
              )}
            </div>
          </div>
          <div className="flex items-center gap-[10px]">
            {!!user?.phone && (
              <div className="flex items-center gap-[5px]">
                <div className="flex-center w-[24px] h-[24px]">
                  <PhoneIcon />
                </div>
                <div>{user?.phone}</div>
              </div>
            )}
            {/*<div className="flex-center w-[24px] h-[24px]">*/}
            {/*  {user?.isPhoneConfirmed ? (*/}
            {/*    <ShieldCheckIcon width={24} height={24} />*/}
            {/*  ) : (*/}
            {/*    <ShieldCrossIcon />*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
        {/*{!!user?.about && (*/}
        {/*  <div className="flex flex-col gap-[15px]">*/}
        {/*    <h5 className="text-heading-5 text-heading">{t('about_me')}</h5>*/}
        {/*    <div>{user?.about}</div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div>
        <ProfileTip
          icon={<ShieldCheckIcon />}
          text={t('my_profile_help_message_1')}
        />
      </div>
    </div>
  );
};

export default MyProfilePage;
