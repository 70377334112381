import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { postAuthLogin } from '../../../api/services/auth/requests';

import { ILogin } from '../types/form';

import { loginFormSchema } from '../validations/schema';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

const useFormLogin = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { setAccessToken, setRefreshToken } = useAuthStore((state) => state);
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();

  const { mutateAsync } = useMutation((payload: ILogin) =>
    postAuthLogin(payload),
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ILogin>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      isAdminPanel: false,
    },
    resolver: zodResolver(loginFormSchema),
  });

  const onSubmit = async (value: ILogin) => {
    try {
      setLoader(true);
      const { accessToken, refreshToken } = await mutateAsync({
        ...value,
        isAdminPanel: false,
      });
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      navigate('/');
    } catch (error: any) {
      const needConfirmEmail =
        error?.response?.data?.data?.name === 'EmailConfirmException';

      if (needConfirmEmail) {
        setLoader(false);
        navigate(`/${lang}/verify-email?email=${btoa(value.email)}`);
      } else {
        setError(error?.response?.data?.message || t('login_failed'));
      }
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
};

export default useFormLogin;
