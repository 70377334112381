import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { postOtpPasswordResetSend } from '../../../../api/services/otp/requests';

import useGlobalErrorStore from '../../../../common/stores/useGlobalErrorStore';

import useForgotPasswordStore from '../../stores/useForgotPasswordStore';

import ModalTemplate from '../../../../common/components/Modal/ModalTemplate';
import PinInput from '../../../../common/components/PinInput/PinInput';

import useFormResetPasswordPin from '../../hooks/useFormResetPasswordPin';

import { ReactComponent as PinCodeIcon } from '../../../../common/assets/icons/pinCodeIcon.svg';

const ModalBody = () => {
  const [counter, setCounter] = useState(60);

  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();

  const { email, pinError, setPinError } = useForgotPasswordStore();

  const { control, pin } = useFormResetPasswordPin();

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1);
    }, 1000);
  };

  useEffect(() => {
    startCounter();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1);
    }, 1000);
  }, []);

  const resendCode = async () => {
    if (counter > 0) return;
    setCounter(60);
    try {
      await postOtpPasswordResetSend({ email });
      startCounter();
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
    }
  };

  useEffect(() => {
    if (pin.length < 4) {
      setPinError(false);
    }
  }, [pin]);

  return (
    <div className="flex flex-col items-center gap-[20px]">
      <PinInput
        length={4}
        defaultValue={''}
        name="pin"
        control={control}
        error={pinError}
      />
      <div
        className={`font-bold ${counter <= 0 ? 'cursor-pointer text-accent' : 'text-[#9997A8]'}`}
        onClick={resendCode}
      >
        {t('resend_code')} {counter >= 0 && counter}
      </div>
    </div>
  );
};

const ForgotPasswordPinModal = () => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<PinCodeIcon />}
      title={t('verify_code')}
      description={t('verify_code_description')}
      body={<ModalBody />}
    />
  );
};

export default ForgotPasswordPinModal;
