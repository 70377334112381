import { FC } from 'react';
import { ISvgProps } from '../../types/media';

const PhoneIcon: FC<ISvgProps> = ({ color = '#DF5753' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 3V1.5H10.25V3H5.75ZM4.25 3V1C4.25 0.734784 4.35536 0.48043 4.54289 0.292893C4.73043 0.105357 4.98478 0 5.25 0L10.75 0C11.0152 0 11.2696 0.105357 11.4571 0.292893C11.6446 0.48043 11.75 0.734784 11.75 1V3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75C15 3.94891 14.921 4.13968 14.7803 4.28033C14.6397 4.42098 14.4489 4.5 14.25 4.5H13.885L13.142 14.153C13.1034 14.6554 12.8767 15.1248 12.507 15.4673C12.1373 15.8097 11.6519 16 11.148 16H4.852C4.34808 16 3.86274 15.8097 3.49304 15.4673C3.12335 15.1248 2.89655 14.6554 2.858 14.153L2.115 4.5H1.75C1.55109 4.5 1.36032 4.42098 1.21967 4.28033C1.07902 4.13968 1 3.94891 1 3.75C1 3.55109 1.07902 3.36032 1.21967 3.21967C1.36032 3.07902 1.55109 3 1.75 3H4.25ZM3.62 4.5H12.38L11.646 14.038C11.6364 14.1636 11.5798 14.2809 11.4875 14.3666C11.3952 14.4522 11.2739 14.4999 11.148 14.5H4.852C4.72607 14.4999 4.60482 14.4522 4.5125 14.3666C4.42017 14.2809 4.36357 14.1636 4.354 14.038L3.62 4.5Z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
