import React, { useMemo, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../../common/utils/helpers';

import { IProperty } from '../../../common/types/dto/property';

import useAuthStore from '../../../common/stores/useAuthStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import usePropertyFavorite from '../../../common/hooks/usePropertyFavorite';

import DropdownSimple from '../../../common/components/Dropdown/DropdownSimple';

import LinkModal from '../../search/components/modals/LinkModal';
import usePropertyCategories from '../../../common/hooks/usePropertyCategories';

import ComplaintModal from './modals/ComplaintModal';

import ShareIcon from '../../../common/assets/icons/ShareIcon';

import ComplaintIcon from '../../../common/assets/icons/ComplaintIcon';
import HeartIcon from '../../../common/assets/icons/HeartIcon';

import { ReactComponent as ArrowBackIcon } from '../../../common/assets/icons/arrowBackIcon.svg';

interface PropertyHeaderProps {
  property?: IProperty;
  refetch?: any;
}

const PropertyHeader: FC<PropertyHeaderProps> = ({ property, refetch }) => {
  const navigate = useNavigate();
  const { id, lang } = useParams();
  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();

  const { categories } = usePropertyCategories();
  const { toggleFavorite } = usePropertyFavorite();

  const titleString = useMemo(() => {
    if (!property) return '';

    const categoryNameArray = property.category?.name?.split('.') || [];
    const propertyType =
      categoryNameArray[0] === 'rent' ? t('rent') : t('sale');
    const totalPropertyArea = property.fields?.find(
      (f) => f.field.name === 'totalArea',
    )?.value;
    const roomsCount = property.fields?.find(
      (f) => f.field.name === 'roomsCountFlat',
    )?.value;
    const entities =
      getSubTree(categories, categoryNameArray.slice(0, -1))?.entities || [];
    const entityName = entities.find(
      (ent) => ent.id === property?.category?.id,
    )?.title;

    const parts = [
      t('for'),
      propertyType,
      roomsCount,
      entityName,
      totalPropertyArea ? `${totalPropertyArea} m²` : '',
    ];

    return parts.filter(Boolean).join(' ');
  }, [categories, property, t]);

  const getFullLink = (lang: string, id: string) => {
    return `${window.location.protocol}//${window.location.host}/${lang}/property/${id}`;
  };

  const fullLink = getFullLink(lang as string, id as string);

  const handleBack = () => {
    navigate(-1);
  };

  const openComplaintModal = () => {
    if (!id) return;
    setChildren(<ComplaintModal id={id} />);
    setIsOpen(true);
  };

  const handleToggleLike = async () => {
    await toggleFavorite(id, property?.isFavorite);
    if (refetch) {
      await refetch();
    }
  };

  return (
    <div className="flex items-center justify-between mb-[25px]">
      <div className="flex items-center gap-[18px]">
        <button onClick={handleBack} className="flex-center w-[42px] h-[42px]">
          <ArrowBackIcon />
        </button>
        <h3 className="text-heading-3 text-heading">{titleString}</h3>
      </div>
      <div className="flex items-center gap-4">
        <DropdownSimple
          title={
            <div className="flex-center w-[24px] h-[24px]">
              <ShareIcon />
            </div>
          }
          menuClass="left-auto right-0"
        >
          <LinkModal link={fullLink} />
        </DropdownSimple>
        <button
          onClick={openComplaintModal}
          className="flex-center w-[24px] h-[24px]"
        >
          <ComplaintIcon />
        </button>
        {!!user && (
          <button
            onClick={handleToggleLike}
            className="flex-center w-[24px] h-[24px]"
          >
            <HeartIcon fill={property?.isFavorite ? '#006CFB' : 'none'} />
          </button>
        )}
      </div>
    </div>
  );
};

export default PropertyHeader;
