import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import DropdownSimple from '../Dropdown/DropdownSimple';

import CheckIcon from '../../assets/icons/CheckIcon';
import SortIcon from '../../assets/icons/SortIcon';

const sortTypes = [
  {
    type: 'createdAt_DESC',
    label: 'by_date_desc',
  },
  {
    type: 'createdAt_ASC',
    label: 'by_date_asc',
  },
  {
    type: 'price_ASC',
    label: 'by_price_asc',
  },
  {
    type: 'price_DESC',
    label: 'by_price_desc',
  },
  {
    type: 'totalArea_DESC',
    label: 'by_total_area',
  },
];

const PropertySort = ({ menuClass }: { menuClass?: string }) => {
  const { t } = useTranslation();

  const { sort, setSort } = usePropertySearchStore();

  const dropdownRef = useRef<any>(null);

  const titleString =
    sortTypes.find((t) => t.type === sort)?.label || 'by_default';

  return (
    <DropdownSimple
      ref={dropdownRef}
      title={
        <div className="flex-center gap-[5px] h-[25px] text-accent font-medium underline">
          <SortIcon />
          <span>{t(titleString)}</span>
        </div>
      }
      menuClass={menuClass || 'left-1/2 transform -translate-x-1/2'}
    >
      <div className="flex flex-col gap-4 py-2.5 px-5">
        {sortTypes.map(({ type, label }) => {
          const handleClick = () => {
            setSort(type);
            dropdownRef.current?.closeDropdown();
          };
          const isSelected =
            type === sort || (sort === 'default' && type === 'createdAt_DESC');

          return (
            <div
              key={type}
              className={`flex items-center justify-between gap-6 whitespace-nowrap cursor-pointer text-[12px] ${isSelected ? 'text-[#006CFB]' : 'text-[#5D5D5D]'}`}
              onClick={handleClick}
            >
              {t(label)}
              <div>
                {isSelected && (
                  <CheckIcon width={12} height={12} color="#006CFB" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </DropdownSimple>
  );
};

export default PropertySort;
