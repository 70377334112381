import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  KeyboardEvent,
} from 'react';

interface IOption {
  label?: string;
  value: number;
  metaData: {
    display_name?: string;
    address?: any;
    osm_type?: string;
    osm_id?: number;
    lat?: string;
    lon?: string;
    addresstype?: string;
  };
}

interface IAsyncSelectProps {
  loadOptions: (query: string) => Promise<IOption[]>;
  onChange: (option: IOption | null) => void;
  placeholder?: string;
  inputClassName?: string;
  debounceDelay?: number;
  defaultValue?: string | null;
  inner?: boolean;
}

interface ICache {
  query: string;
  options: IOption[];
}

const AsyncSelect: React.FC<IAsyncSelectProps> = ({
  loadOptions,
  onChange,
  placeholder = 'Search...',
  inputClassName = '',
  debounceDelay = 300,
  defaultValue = null,
  inner = false,
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || '');
  const [options, setOptions] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1);
  const lastQueryRef = useRef<string>('');
  const cache = useRef<ICache>({ query: '', options: [] });

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (!showDropdown || options?.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        setFocusedOptionIndex((prev) => Math.min(prev + 1, options.length - 1));
        break;
      case 'ArrowUp':
        setFocusedOptionIndex((prev) => Math.max(prev - 1, 0));
        break;
      case 'Enter':
        if (focusedOptionIndex >= 0) {
          handleOptionClick(options[focusedOptionIndex]);
        }
        break;
      case 'Escape':
        setShowDropdown(false);
        break;
      default:
        break;
    }
  };

  const fetchOptions = useCallback(
    async (query: string) => {
      if (cache.current.query === query) {
        setOptions(cache.current.options);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const loadedOptions = await loadOptions(query);
        cache.current = { query, options: loadedOptions }; // Обновляем кэш только с последним значением
        setOptions(loadedOptions);
      } catch (error) {
        console.error('Failed to load options:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [loadOptions],
  );

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (
      !inputValue ||
      inputValue?.length < 3 ||
      lastQueryRef.current === inputValue
    ) {
      return;
    }

    lastQueryRef.current = inputValue;

    const timeoutId = setTimeout(() => {
      fetchOptions(inputValue);
    }, debounceDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue, fetchOptions, debounceDelay]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length === 0) {
      onChange(null);
    }

    setShowDropdown(value.length > 2);
  };

  const handleOptionClick = (option: IOption) => {
    onChange(option);
    setInputValue(option?.label || '');
    setShowDropdown(false);
  };

  const handleInputFocus = () => {
    if (!!inputValue && options?.length) {
      setShowDropdown(true);
    }
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const relatedTarget = e.relatedTarget;
    setTimeout(() => {
      if (!relatedTarget || !relatedTarget.closest('.dropdown')) {
        setShowDropdown(false);
      }
    }, 200);
  };

  return (
    <div className="relative" onKeyDown={handleKeyDown}>
      <input
        type="text"
        className={`w-full outline-none text-body-small placeholder-placeholder ${inner ? 'py-[22px] px-[16px] border border-secondary rounded-[20px]' : 'p-[8px] border-0 rounded-[8px]'} ${inputClassName}`}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {showDropdown && (
        <div
          className={`overflow-y-auto ${inner ? 'relative w-[calc(100%+10px)] pr-[10px] max-h-[600px] mt-[20px]' : 'absolute z-10 w-full bg-white border border-[#E2E8F0] rounded-[8px] shadow max-h-60 py-2.5 mt-1'}`}
        >
          {isLoading ? (
            <div className="px-5 text-[12px] text-[#5D5D5D]">Loading...</div>
          ) : options?.length > 0 ? (
            options.map((option, index) => (
              <div
                key={option.value}
                className={`cursor-pointer hover:bg-[#EAEFFE] ${inner ? 'pb-[6px] border-b border-[#D9D9D9] mb-[20px]' : 'px-5 py-1 '} ${
                  index === focusedOptionIndex ? 'bg-[#EAEFFE]' : ''
                }`}
                onMouseEnter={() => setFocusedOptionIndex(index)}
                onClick={() => handleOptionClick(option)}
              >
                {inner ? (
                  <div>
                    <div className="text-[12px] text-primary leading-[16.4px] font-semibold capitalize">
                      {option.metaData?.addresstype}
                    </div>
                    <div>{option.label}</div>
                  </div>
                ) : (
                  option.label
                )}
              </div>
            ))
          ) : (
            <div className="px-5 text-[12px] text-[#5D5D5D]">
              No options found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AsyncSelect;
