import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { postAuthEmailChange } from '../../../api/services/auth/requests';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import useChangeEmailStore from '../stores/useChangeEmailStore';

const useFormChangeEmailOTP = () => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const { email } = useChangeEmailStore();

  const defaultValues = {
    otp: '',
  };

  const { control, handleSubmit, watch } = useForm({
    defaultValues,
  });

  const otp = watch('otp');

  const onSubmit = async ({ otp }: { otp: string }) => {
    const payload = {
      otp,
      email,
    };
    try {
      setLoader(true);
      await postAuthEmailChange(payload);
      onClose();
    } catch (error: any) {
      setError(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (otp.length === 4) handleSubmit(onSubmit)();
  }, [otp]);

  return {
    control,
  };
};

export default useFormChangeEmailOTP;
