import React, { useRef, MouseEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useGlobalModalStore from '../../stores/useGlobalModalStore';

const Modal: React.FC = () => {
  const { isOpen, children, onClose } = useGlobalModalStore();

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    // При открытии модалки блокируем прокрутку бэкграунда
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 flex-center bg-[#00000040] z-[6000]"
      onClick={handleClickOutside}
    >
      {/*
        На мобильных (меньше sm) — модалка на весь экран:
          w-full h-full
        На больших экранах (от sm) — классическое "окно":
          w-auto h-auto
      */}
      <div
        ref={modalRef}
        className="
          relative
          bg-white
          w-full h-full
          sm:w-auto sm:h-auto
          sm:rounded-[25px]
          scale-fade-in
          overflow-auto
        "
      >
        <div>{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
