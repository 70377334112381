import React, { useState } from 'react';

import AchtungIcon from '../../../common/assets/icons/AchtungIcon';

interface ImageWithFallbackProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  withSkeleton?: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  withSkeleton = false,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(withSkeleton);

  return (
    <>
      {withSkeleton && !isError && isLoading && (
        <div className="relative w-full h-full">
          <div
            className={`absolute inset-0 flex-center bg-disabled animate-pulse ${props.className}`}
          >
            <div className="w-1/2 h-1/2 bg-[#D9D9D9] rounded-full"></div>
          </div>
        </div>
      )}
      {isError ? (
        <div className={`flex-center w-full h-full ${props.className}`}>
          <AchtungIcon
            width={(props?.width as number) || 100}
            height={(props?.height as number) || 100}
            className="max-w-full max-h-full object-contain text-yellow-400"
          />
        </div>
      ) : (
        <img
          onError={() => {
            setIsError(true);
            setIsLoading(false);
          }}
          onLoad={() => setIsLoading(false)}
          style={{
            display: isError ? 'none' : 'block',
            height: isLoading && withSkeleton ? 0 : '100%',
          }}
          {...props}
        />
      )}
    </>
  );
};

export default ImageWithFallback;
