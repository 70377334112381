import React, { useState } from 'react';

import usePropertySearch from '../../common/hooks/usePropertySearch';

import usePreventZoom from '../../common/hooks/usePreventZoom';

import PropertyPanel from '../../features/map/components/PropertyPanel';
import PropertyMap from '../../features/map/components/PropertyMap';

const MapPage = () => {
  const [showPanel, setShowPanel] = useState(true);

  usePropertySearch(true);
  usePreventZoom();

  const togglePanel = () => {
    setShowPanel((prevState) => !prevState);
  };

  return (
    <div className="h-full">
      <div className="flex max-h-[calc(100vh-85px)] overflow-hidden">
        <div>
          <PropertyPanel showPanel={showPanel} togglePanel={togglePanel} />
        </div>
        <div className="h-[calc(100vh-85px)] flex-1">
          <PropertyMap />
        </div>
      </div>
    </div>
  );
};

export default MapPage;
