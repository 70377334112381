import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import useGlobalLoaderStore from '../../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../stores/useGlobalErrorStore';
import useGlobalModalStore from '../../stores/useGlobalModalStore';

import ModalTemplate from '../Modal/ModalTemplate';
import Input from '../Input/Input';
import Button from '../Button/Button';

import EditSearchIcon from '../../assets/icons/EditSearchIcon';

interface IFormValues {
  searchName: string;
  notificationInterval: number;
}

const ModalBody = ({
  saveSearch,
  defaultValues,
}: {
  saveSearch: (name: string, interval: number) => void;
  defaultValues?: any;
}) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const intervals = [
    {
      label: t('once_an_hour'),
      value: 1,
    },
    {
      label: t('once_a_day'),
      value: 24,
    },
    {
      label: t('once_a_week'),
      value: 168,
    },
    {
      label: t('dont_send'),
      value: 0,
    },
  ];

  const defaultFormValues: IFormValues = {
    searchName: defaultValues?.name || '',
    notificationInterval: defaultValues?.notificationInterval || 0,
  };

  const { control, handleSubmit, watch } = useForm<IFormValues>({
    mode: 'onTouched',
    defaultValues: defaultFormValues,
  });

  const searchName = watch('searchName');

  const onSubmit = async (value: IFormValues) => {
    try {
      setLoader(true);
      saveSearch(value.searchName, value.notificationInterval);
      onClose();
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-[24px]">
      <div>
        <Input
          label={t('search_name_label')}
          name="searchName"
          control={control}
          placeholder={t('search_name_placeholder')}
        />
      </div>
      <div>
        <p className="text-heading-5 text-heading mb-[10px]">
          {t('push_notifications')}
        </p>
        <Controller
          name="notificationInterval"
          control={control}
          render={({ field }) => (
            <div className="grid grid-cols-4 gap-[25px]">
              {intervals.map((interval) => (
                <label
                  key={interval.value}
                  className="flex items-center gap-[7px] whitespace-nowrap"
                >
                  <input
                    type="radio"
                    {...field}
                    value={interval.value}
                    checked={field.value === interval.value}
                    onChange={() => field.onChange(interval.value)}
                    className="appearance-none w-[24px] h-[24px] border border-[#006CFB] rounded-[6px] bg-transparent shrink-0 cursor-pointer checked:bg-[#006CFB]"
                  />
                  {interval.label}
                </label>
              ))}
            </div>
          )}
        />
      </div>
      <div className="flex justify-end">
        <Button
          className="w-[260px]"
          disabled={!searchName.length}
          type="submit"
          size="small"
        >
          {t('save')}
        </Button>
      </div>
    </form>
  );
};

const SaveSearchModal = ({
  saveSearch,
  defaultValues,
  isEdit,
}: {
  saveSearch: any;
  defaultValues?: any;
  isEdit?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<EditSearchIcon />}
      title={isEdit ? t('edit_search_title') : t('save_search_title')}
      body={<ModalBody saveSearch={saveSearch} defaultValues={defaultValues} />}
      width={700}
    />
  );
};

export default SaveSearchModal;
