import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';

import {
  IPostAuthEmailConfirmPayload,
  IPostAuthLoginPayload,
  IPostAuthLoginResponse,
  IPostAuthPasswordResetPayload,
  IPostAuthRegisterPayload,
  IPostAuthPasswordChangePayload,
  IPostAuthEmailChangePayload,
  IPostAuthImpersonatePayload,
  IPostAuthImpersonateResponse,
} from './types';

export const postAuthLogin = async (payload: IPostAuthLoginPayload) => {
  const response: AxiosResponse<IResponse<IPostAuthLoginResponse>> =
    await axiosInstance.post('/auth/login', payload);
  return response.data.data;
};

export const postAuthRegister = async (payload: IPostAuthRegisterPayload) => {
  const response: AxiosResponse<IResponse<IPostAuthLoginResponse>> =
    await axiosInstance.post('/auth/client/register', payload);
  return response.data.data;
};

export const postAuthEmailConfirm = async (
  payload: IPostAuthEmailConfirmPayload,
) => {
  const response: AxiosResponse<
    IResponse<{ accessToken: string; refreshToken: string }>
  > = await axiosInstance.post('/auth/email-confirm', payload);
  return response.data.data;
};

export const postAuthPasswordReset = async (
  payload: IPostAuthPasswordResetPayload,
): Promise<string> => {
  const response: AxiosResponse<string> = await axiosInstance.post(
    '/auth/password-reset',
    payload,
  );
  return response.data;
};

export const postAuthPasswordChange = async (
  payload: IPostAuthPasswordChangePayload,
): Promise<string> => {
  const response: AxiosResponse<string> = await axiosInstance.post(
    '/auth/password-change',
    payload,
  );
  return response.data;
};

export const postAuthEmailChange = async (
  payload: IPostAuthEmailChangePayload,
): Promise<string> => {
  const response: AxiosResponse<string> = await axiosInstance.post(
    '/auth/email-change',
    payload,
  );
  return response.data;
};

export const postAuthImpersonate = async (
  payload: IPostAuthImpersonatePayload,
) => {
  const response: AxiosResponse<IResponse<IPostAuthImpersonateResponse>> =
    await axiosInstance.post('/auth/impersonate', payload);
  return response.data.data;
};
