import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Container from '../../../common/components/Container/Container';
import Button from '../../../common/components/Button/Button';

import CheckIcon from '../../../common/assets/icons/CheckIcon';

import { ReactComponent as JoinUsImage } from '../../../common/assets/images/main/joinUsImage.svg';
import { ReactComponent as ArrowRight } from '../../../common/assets/icons/arrowRight.svg';

const JoinUsItem = ({ title }: { title: string }) => (
  <div className="flex items-center gap-[16px]">
    <div>
      <div className="flex-center w-5 h-5 bg-[#006CFB] rounded-full">
        <CheckIcon color="white" />
      </div>
    </div>
    <div className="text-body-small text-body">{title}</div>
  </div>
);

const JoinPlatform = () => {
  const { t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();

  return (
    <div className="py-[40px] sm:py-[60px] md:py-[85px] bg-secondary">
      <Container>
        <div className="flex flex-col lg:flex-row items-center justify-between gap-[20px]">
          <div className="py-10 rounded-[20px] bg-white w-full max-w-[500px] mb-6 lg:mb-0">
            <JoinUsImage className="mx-auto w-full max-w-[450px] h-auto" />
          </div>
          <div className="max-w-[500px]">
            <div className="mb-9">
              <h2 className="text-heading-2 text-heading">
                {t('main_join_us')}
              </h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[40px] gap-y-[24px] items-start mb-[60px]">
              <JoinUsItem title={t('join_us_item_1')} />
              <JoinUsItem title={t('join_us_item_2')} />
              <JoinUsItem title={t('join_us_item_3')} />
              <JoinUsItem title={t('join_us_item_4')} />
              <JoinUsItem title={t('join_us_item_5')} />
            </div>
            <div>
              <Link
                className="inline-block"
                to={`/${lang}/agencies`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="small" className="min-w-[202px]">
                  {t('join_now')}
                  <ArrowRight />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default JoinPlatform;
