import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addPriceDelimiters,
  generatePropertyDataString,
  getAddressString,
} from '../../utils/helpers';

import { EPriceType } from '../../../api/services/property/types';
import { IPropertySearch } from '../../types/dto/property';
import { IImage } from '../../types/media';

import PropertyListCarousel from './PropertyListCarousel';
import FavoriteButton from '../FavoriteButton/FavoriteButton';

import NoImageIcon from '../../assets/icons/NoImageIcon';

interface PropertyListItemProps {
  item: IPropertySearch;
  refetch?: () => void;
  isLoggedIn?: boolean;
}

const PropertyListItem: React.FC<PropertyListItemProps> = ({
  item: {
    id,
    mainImage,
    gallery = [],
    price,
    priceType,
    address,
    description,
    fields,
    category,
    isFavorite,
  },
  refetch,
  isLoggedIn = false,
}) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const addressString = getAddressString(address);

  const allImages: IImage[] = useMemo(() => {
    const collateralImages = gallery;
    return mainImage ? [mainImage, ...collateralImages] : collateralImages;
  }, [gallery, mainImage]);

  const showMeterPrice =
    category?.name?.includes('sale') || category?.name?.includes('commercial');

  const meterPrice = fields?.totalArea
    ? Math.round(price / fields?.totalArea)
    : '';

  const dataString = generatePropertyDataString(category, fields);

  return (
    <Link
      to={`/${lang}/property/${id}`}
      className="
        flex flex-col
        sm:flex-row
        gap-2 sm:gap-[25px]  /* меньший отступ на мобильных, больше на больших экранах */
        rounded-[8px]
        cursor-pointer
      "
    >
      <div
        className="
          relative group
          w-full h-[240px]        /* для мобильных */
          sm:w-[430px] sm:h-[320px] /* от sm и выше */
          flex-shrink-0
          rounded-[8px]
          overflow-hidden
          fade-in
        "
      >
        {allImages && allImages.length > 0 ? (
          <PropertyListCarousel items={allImages} />
        ) : (
          <div className="flex-center w-full h-full bg-[#F8F8F8]">
            <NoImageIcon />
          </div>
        )}
        {isLoggedIn && (
          <FavoriteButton id={id} isFavorite={isFavorite} refetch={refetch} />
        )}
      </div>

      <div className="flex flex-col flex-1 fade-in">
        {/* Уменьшим отступы на мобильных */}
        <div className="mb-2 sm:mb-[35px]">
          <h4 className="text-heading-4 text-primary mb-[5px]">{dataString}</h4>
          <div className="text-body-small text-[#9997A8]">{addressString}</div>
        </div>

        <div className="mb-2 sm:mb-[35px]">
          <h4 className="text-heading-4 text-heading mb-[5px]">
            {addPriceDelimiters(price)} Kč
            {priceType === EPriceType.PerMonth
              ? `/${t('month')}`
              : priceType === EPriceType.PerDay
                ? `/${t('day')}`
                : ''}
          </h4>
          {showMeterPrice && (
            <div className="text-body-smaller text-[#9997A8]">
              {addPriceDelimiters(meterPrice)} Kč/m²
            </div>
          )}
        </div>

        {typeof description === 'string' && description.trim() !== '' && (
          <div className="flex-1 overflow-hidden">
            <p className="line-clamp-4">{description}</p>
          </div>
        )}
      </div>
    </Link>
  );
};

export default PropertyListItem;
