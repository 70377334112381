import React, { useRef, useState } from 'react';

import ChevronIconRounded from '../../assets/icons/ChevronIconRounded';

interface IAccordionItem {
  title: React.ReactNode;
  content: React.ReactNode;
}

interface IAccordionProps {
  items: IAccordionItem[];
  itemsGap?: string;
}

const Accordion: React.FC<IAccordionProps> = ({ items, itemsGap = '35px' }) => {
  const [openIndices, setOpenIndices] = useState<number[]>([]);

  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleAccordion = (index: number) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div className="flex flex-col w-full" style={{ gap: itemsGap }}>
      {items.map((item, index) => (
        <div key={index}>
          <button
            onClick={() => toggleAccordion(index)}
            className="grid grid-cols-[1fr_auto] items-center w-full text-left focus:outline-none"
          >
            <div>{item.title}</div>
            <div className="flex-center w-[57px] h-[33px]">
              <span
                className={`transform transition-transform ${
                  openIndices.includes(index) ? 'rotate-90' : '-rotate-90'
                }`}
              >
                <ChevronIconRounded width={12} height={24} color="#374957" />
              </span>
            </div>
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className="transition-[max-height] duration-300 ease-in-out overflow-hidden"
            style={{
              maxHeight: openIndices.includes(index)
                ? contentRefs.current[index]?.scrollHeight + 'px'
                : '0px',
            }}
          >
            <div>{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
