import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import Dropdown from '../Dropdown/Dropdown';

import CheckIcon from '../../assets/icons/CheckIcon';

const PropertyRoomsSelector = ({
  options = [] as any[],
  isBedRooms = false,
}) => {
  const { t } = useTranslation();

  const dropdownRef = useRef<any>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const { rooms, setRooms, bedRooms, setBedRooms } = usePropertySearchStore();

  const currentType = isBedRooms ? bedRooms : rooms;
  const setCurrentType = isBedRooms ? setBedRooms : setRooms;

  const labelsString = options
    .filter((option) => currentType.includes(option.id))
    .map((option) => option.label)
    .join(', ');

  const placeholder = isBedRooms ? t('select_bedrooms') : t('select_rooms');

  const onChange = (value: string) => {
    const newRooms = currentType.includes(value)
      ? currentType.filter((room) => room !== value)
      : [...currentType, value];

    setCurrentType(newRooms);
  };

  const optionsMap = options.map((option) => {
    const { label, id } = option;
    const isActive = currentType.includes(id);

    return (
      <div
        key={id}
        onClick={() => onChange(id)}
        className="flex items-center justify-between gap-[20px] w-full cursor-pointer"
      >
        <div className="whitespace-nowrap">{label}</div>
        <div className="relative flex-center w-[24px] h-[24px]">
          <input
            type="checkbox"
            className="appearance-none w-[24px] h-[24px] border-2 border-primary rounded-full bg-transparent shrink-0 cursor-pointer checked:bg-primary"
            checked={isActive}
            readOnly
          />
          <CheckIcon
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
            height={10}
            width={10}
            color="white"
            strokeWidth={2.5}
          />
        </div>
      </div>
    );
  });

  return (
    <div
      className="cursor-pointer"
      ref={parentRef}
      onClick={() => dropdownRef.current?.toggleDropdown()}
    >
      <h5 className="text-heading-5 text-heading capitalize">
        {isBedRooms ? t('bedRooms') : t('rooms')}
      </h5>
      <Dropdown
        ref={dropdownRef}
        className="w-[176px] h-[19px]"
        buttonClass={`text-body-smaller pl-0 text-placeholder`}
        menuClass="-ml-[25px] mt-[38px]"
        title={labelsString || placeholder}
        parentRef={parentRef}
      >
        <div className="flex flex-col gap-[15px] p-[25px] min-w-[224px]">
          {optionsMap}
        </div>
      </Dropdown>
    </div>
  );
};

export default PropertyRoomsSelector;
