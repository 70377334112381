import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'axios';

import axios from '../../../api/axios';

import useGlobalLoaderStore from '../../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../stores/useGlobalErrorStore';

import usePropertyFiltersStore from './stores/usePropertyFiltersStore';

import AsyncSelect from '../AsyncSelect/AsyncSelect';

export interface IAddressSuggestion {
  place_id: number;
  licence?: string;
  osm_type?: string;
  osm_id?: number;
  lat?: string;
  lon?: string;
  class?: string;
  type?: string;
  place_rank?: number;
  importance?: number;
  addresstype?: string;
  name?: string;
  display_name?: string;
  address?: any; // IAddressSuggestionAddress;
  boundingbox?: string[];
}

interface IOption {
  label?: string;
  value: number;
  metaData: {
    display_name?: string;
    address?: any;
    osm_type?: string;
    osm_id?: number;
    lat?: string;
    lon?: string;
  };
}

const FiltersAddressInput: React.FC = () => {
  const { t } = useTranslation();

  const { address, setAddress, setOsmId, setCoordinates } =
    usePropertyFiltersStore();

  const [allSuggestions, setAllSuggestions] = useState<IAddressSuggestion[]>(
    [],
  );

  const abortControllerRef = useRef<AbortController | null>(null);

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const onChange = (option: IOption) => {
    const { metaData } = option;
    if (!metaData) return;

    const { display_name = '', address, osm_type, osm_id, lat, lon } = metaData;

    const matchingOsmIds = allSuggestions
      .filter((suggestion) => suggestion.address?.road === address?.road)
      .filter((suggestion) => suggestion.osm_type === osm_type)
      .map((suggestion) =>
        suggestion.osm_id && suggestion.osm_type
          ? `${suggestion.osm_type[0].toString().toUpperCase()}${suggestion.osm_id.toString()}`
          : undefined,
      )
      .filter((id) => id !== undefined);

    const singleOsmId = [
      !!osm_type && !!osm_id
        ? `${osm_type[0].toString().toUpperCase()}${osm_id.toString()}`
        : '',
    ];

    setAddress({ formattedAddress: display_name });
    setOsmId(osm_type === 'way' ? matchingOsmIds : singleOsmId);

    if (!lat || !lon) return;
    setCoordinates({
      lat: parseFloat(lat),
      lng: parseFloat(lon),
    });
  };

  const handleChange = (option: IOption | null) => {
    if (option) {
      onChange(option);
    } else {
      setAddress({ formattedAddress: '' });
      setOsmId([]);
    }
  };

  const loadOptions = async (inputValue: string): Promise<IOption[]> => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    setLoader(true);

    try {
      const params = {
        q: inputValue,
        limit: 40,
        countrycodes: 'cz',
        language: 'cz',
        format: 'json',
        addressdetails: 1,
        dedupe: 0,
        'accept-language': 'cz',
      };

      const responsePart1 = await axios.get(
        'https://nominatim.reelty.cz/search',
        {
          params: {
            ...params,
            featureType: 'settlement',
          },
          signal,
        },
      );

      const responsePart2 = await axios.get(
        'https://nominatim.reelty.cz/search',
        { params, signal },
      );

      const response: any[] = [].concat(responsePart1.data, responsePart2.data);

      const uniqueOsmIds = new Set<number | undefined>();
      const uniqueAddresses: { [key: string]: boolean } = {};

      const options: IOption[] = response
        .filter((suggestion: IAddressSuggestion) => {
          if (uniqueOsmIds.has(suggestion.osm_id)) {
            return false;
          }
          uniqueOsmIds.add(suggestion.osm_id);

          if (suggestion.osm_type !== 'way') return true;

          const { city_district, road } = suggestion.address;
          if (uniqueAddresses[`${city_district}-${road}`]) {
            return false;
          }

          uniqueAddresses[`${city_district}-${road}`] = true;
          return true;
        })
        .map((s: IAddressSuggestion) => ({
          label: s.display_name,
          value: s.place_id,
          metaData: {
            display_name: s.display_name,
            address: s.address,
            osm_type: s.osm_type,
            osm_id: s.osm_id,
            lat: s.lat,
            lon: s.lon,
            addresstype: s.addresstype,
          },
        }));

      setAllSuggestions(response);

      return options;
    } catch (error: any) {
      if (isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        setError(error?.message || 'Error');
      }
    } finally {
      setLoader(false);
    }

    return [];
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <h5 className="text-heading-5 text-heading capitalize">
        {t('location')}
      </h5>
      <AsyncSelect
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder={t('placeholder-address')}
        defaultValue={address?.formattedAddress}
        inner
      />
    </div>
  );
};

export default FiltersAddressInput;
