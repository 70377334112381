import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import classNames from 'classnames';

import EyePasswordIcon from '../../assets/icons/EyePasswordIcon';
import EyeClosedPasswordIcon from '../../assets/icons/EyeClosedPasswordIcon';

export interface IInputProps<TFormData extends Record<string, any>>
  extends Pick<
    ControllerProps<TFormData>,
    'control' | 'name' | 'rules' | 'defaultValue' | 'disabled'
  > {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string | React.ReactElement;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  errorText?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  classnames?: string;
  endUnit?: string;
}

function Input<TFormData extends Record<string, any>>({
  control,
  name,
  rules,
  label,
  defaultValue,
  type,
  classnames,
  error,
  errorText,
  onChange,
  onKeyUp,
  onKeyDown,
  onFocus,
  onBlur,
  endUnit,
  required,
  ...props
}: IInputProps<TFormData>): React.ReactElement {
  const [inputType, setInputType] = useState(type || 'text');

  const handleToggleType = () => {
    setInputType(inputType == 'password' ? 'text' : 'password');
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className="relative w-full flex flex-col gap-[10px]">
          {label && (
            <label className="text-body-small text-heading" htmlFor={name}>
              {label}
              {!!required && <span className="text-red-400">*</span>}
            </label>
          )}
          <div className="relative">
            <input
              {...props}
              id={name}
              value={field.value}
              step={1}
              type={inputType}
              ref={field.ref}
              onWheel={
                type === 'number' ? numberInputOnWheelPreventChange : undefined
              }
              onKeyDown={(e) => {
                onKeyDown?.(e);
              }}
              onChange={(e) => {
                let value;
                if (type === 'number') {
                  value =
                    type === 'number' ? e.target.valueAsNumber : e.target.value;
                } else {
                  const newValue = e.target.value;
                  const trimmedValue = newValue.replace(/^\s+/g, '');
                  value = trimmedValue.replace(/^0(?=[1-9])/, '');
                }
                field.onChange(value);
                onChange?.(e);
              }}
              onFocus={(e) => {
                onFocus?.(e);
              }}
              onBlur={(e) => {
                field.onBlur();
                onBlur?.(e);
              }}
              onKeyUp={onKeyUp}
              className={classNames(
                'outline-none w-full flex-center h-[66px] px-[16px] border border-secondary rounded-[20px] text-body-small placeholder-placeholder disabled:text-placeholder',
                {
                  [classnames || '']: classnames,
                  'border-red-400': error ?? !!fieldState.error,
                },
              )}
            />
            {endUnit && (
              <div className="absolute top-[15px] right-[12px] text-body-smaller text-heading select-none ">
                {endUnit}
              </div>
            )}
            {type == 'password' && (
              <div
                className="cursor-pointer absolute top-1/2 right-[23px] transform -translate-y-1/2"
                onClick={handleToggleType}
              >
                {inputType == 'password' ? (
                  <EyePasswordIcon />
                ) : (
                  <EyeClosedPasswordIcon />
                )}
              </div>
            )}
            {(errorText ?? fieldState.error?.message) && (
              <span className="absolute top-full left-0 mt-[5px] text-red-400 text-[14px] leading-[19.6px] max-w-full">
                {errorText ?? fieldState.error?.message}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default Input;

export const InputUncontrolled = forwardRef(
  (props: InputHTMLAttributes<any>, ref: React.ForwardedRef<any>) => {
    return (
      <input
        {...props}
        className={classNames(
          'w-full text-[14px] rounded-[15px] px-[16px] py-[15px] border outline-none border-secondary',
          props.className,
        )}
        ref={ref}
      />
    );
  },
);

InputUncontrolled.displayName = 'InputUncontrolled';
