import React from 'react';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import usePropertyFiltersStore from './stores/usePropertyFiltersStore';

import { countUniqueKeys } from './FilterControls';

import FiltersModal from './modals/FiltersModal';

import FiltersIcon from '../../assets/icons/FiltersIcon';

const PropertyFilters = ({ groups = [] }) => {
  const { setChildren, setIsOpen, onClose } = useGlobalModalStore();

  const { filters, byOwner, osmId } = usePropertyFiltersStore();

  const filtersCount =
    countUniqueKeys(filters) + (byOwner ? 1 : 0) + (osmId.length ? 1 : 0);

  const openModal = () => {
    setChildren(<FiltersModal groups={groups} onClose={onClose} />);
    setIsOpen(true);
  };

  return (
    <div>
      <button
        onClick={openModal}
        className="relative flex-center w-[24px] h-[24px]"
      >
        <FiltersIcon />
        {filtersCount > 0 && (
          <div className="absolute -top-[9px] -right-[6px] flex-center w-[15px] h-[15px] bg-primary rounded-full text-[10px] text-white font-bold">
            {filtersCount}
          </div>
        )}
      </button>
    </div>
  );
};

export default PropertyFilters;

// const NumberField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <div>
//         <input
//           type="number"
//           value={filters[`FIELD_${field.name}From`] || ''}
//           onChange={(e) => handleChange(`FIELD_${field.name}From`, e.target.value)}
//           placeholder="From"
//         />
//         <input
//           type="number"
//           value={filters[`FIELD_${field.name}To`] || ''}
//           onChange={(e) => handleChange(`FIELD_${field.name}To`, e.target.value)}
//           placeholder="To"
//         />
//       </div>
//     </div>
//   );
// };

// Компонент для поля SELECT
// const SelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для поля TAG_SELECT
// const TagSelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         multiple
//         value={filters[`FIELD_${field.name}`] || []}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, Array.from(e.target.selectedOptions, (option) => option.value))}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для поля TAG_MULTI_SELECT (множество тегов)
// const TagMultiSelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         multiple
//         value={filters[`FIELD_${field.name}`] || []}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, Array.from(e.target.selectedOptions, (option) => option.value))}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для текстовых полей
// const StringField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <input
//         type="text"
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       />
//     </div>
//   );
// };

// Компонент для поля DATE
// const DateField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <input
//         type="date"
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       />
//     </div>
//   );
// };

// const RadioField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <div>
//         {field.options?.map((option) => (
//           <label key={option.value}>
//             <input
//               type="radio"
//               name={`FIELD_${field.name}`}
//               value={option.value}
//               checked={filters[`FIELD_${field.name}`] === option.value}
//               onChange={() => handleChange(`FIELD_${field.name}`, option.value)}
//             />
//             {option.label}
//           </label>
//         ))}
//       </div>
//     </div>
//   );
// };
