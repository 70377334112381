import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getPropertySearchList } from '../../../api/services/property/requests';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';

import usePropertyCategories from '../../../common/hooks/usePropertyCategories';
import usePropertyCategoriesSchemes from '../../../common/hooks/usePropertyCategoriesSchemes';
import usePropertySearchSave from '../../../common/hooks/usePropertySearchSave';

import PropertySearchesListItem from './PropertySearchesListItem';

import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

const PropertySearchesList = () => {
  const navigate = useNavigate();
  const { lang } = useParams<{ lang: string }>();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    setPrice,
    setArea,
    setRooms,
    setBedRooms,
    setFilters,
    setPropertyTypes,
    setByOwner,
  } = usePropertySearchStore();

  const { flatCategories } = usePropertyCategories();
  const { processFields } = usePropertyCategoriesSchemes();

  const { data = [] as any, refetch } = useQuery(
    ['propertiesSearches'],
    async () => {
      setLoader(true);

      const data = await getPropertySearchList();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const { handleEditSearch } = usePropertySearchSave(refetch);

  const handlePressSearch = useCallback(
    (item: any) => {
      const filters = item.filters;
      const newFilters: any = {};

      if (filters.categories && filters.categories.length > 0) {
        newFilters.categories = filters.categories;
      }

      if (filters.fields) {
        const fields = filters.fields;
        const processedFields: any = {};

        Object.keys(fields).forEach((key: string) => {
          const value = fields[key];

          if (typeof value === 'object' && value !== null) {
            if ('from' in value || 'to' in value) {
              if (value.from !== undefined) {
                processedFields[`FIELD_${key}From`] = value.from;
              }
              if (value.to !== undefined) {
                processedFields[`FIELD_${key}To`] = value.to;
              }
            } else {
              processedFields[`FIELD_${key}`] = value;
            }
          } else {
            processedFields[`FIELD_${key}`] = value;
          }
        });

        newFilters.fields = processedFields;
      }

      const {
        FIELD_totalAreaFrom,
        FIELD_totalAreaTo,
        FIELD_roomsCountFlat,
        FIELD_roomsCountHouse,
        ...filteredFilters
      } = newFilters.fields || {};

      const entity = flatCategories.find(
        (entity) => entity.id === filters?.categories[0],
      );

      const path = entity?.name?.split('.').slice(0, -1) || [];

      setPrice({
        from: String(filters.priceFrom || ''),
        to: String(filters.priceTo || ''),
      });

      setArea({
        from: String(FIELD_totalAreaFrom || ''),
        to: String(FIELD_totalAreaTo || ''),
      });

      setRooms(FIELD_roomsCountFlat || []);

      setBedRooms(FIELD_roomsCountHouse || []);

      setFilters(filteredFilters);

      setByOwner(filters.byOwner || false);

      if (entity && path?.length) {
        setPropertyTypes({
          path,
          entities: [entity],
        });
      }

      const type = path[0] === 'rent' ? 'rent' : 'buy';

      navigate(`/${lang}/search/${type}`);
    },
    [flatCategories, navigate, lang],
  );

  return data?.length ? (
    <div className="grid grid-cols-2 gap-[50px]">
      {data.map((searchItem: any) => (
        <div
          key={searchItem.id}
          onClick={() => handlePressSearch(searchItem)}
          className="cursor-pointer hover:opacity-60"
        >
          <PropertySearchesListItem
            searchItem={searchItem}
            flatCategories={flatCategories}
            processFields={processFields}
            handleEditSearch={handleEditSearch}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className={'flex flex-col items-center gap-[17px] py-[35px]'}>
      <EmptyListImage />
      <div className="fade-in">{t('empty_searches_list')}</div>
    </div>
  );
};

export default PropertySearchesList;
