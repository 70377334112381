import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ShieldCheckIcon: FC<ISvgProps> = ({
  height = 45,
  width = 45,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_79_7578)">
        <path
          d="M34.8394 4.01393L23.0925 0.0970531C22.7079 -0.0310489 22.2921 -0.0310489 21.9075 0.0970531L10.1606 4.01393C8.29295 4.63433 6.66828 5.82754 5.51746 7.42401C4.36664 9.02049 3.7482 10.939 3.75 12.9071V22.5014C3.75 36.6821 21 44.5139 21.7388 44.8402C21.9784 44.9467 22.2378 45.0017 22.5 45.0017C22.7623 45.0017 23.0216 44.9467 23.2613 44.8402C24 44.5139 41.25 36.6821 41.25 22.5014V12.9071C41.2518 10.939 40.6334 9.02049 39.4825 7.42401C38.3317 5.82754 36.7071 4.63433 34.8394 4.01393ZM37.5 22.5014C37.5 32.7296 25.6519 39.4383 22.5 41.0433C19.3444 39.4439 7.5 32.7558 7.5 22.5014V12.9071C7.50011 11.7263 7.87177 10.5756 8.56231 9.61782C9.25286 8.66008 10.2273 7.94392 11.3475 7.57081L22.5 3.85268L33.6525 7.57081C34.7727 7.94392 35.7472 8.66008 36.4377 9.61782C37.1282 10.5756 37.4999 11.7263 37.5 12.9071V22.5014Z"
          fill={color}
        />
        <path
          d="M28.6872 15.5635L20.8347 23.4385L16.6272 19.051C16.4584 18.8664 16.2543 18.7174 16.027 18.6129C15.7997 18.5084 15.5537 18.4504 15.3037 18.4424C15.0536 18.4344 14.8045 18.4765 14.5709 18.5663C14.3374 18.656 14.1242 18.7916 13.9439 18.9651C13.7635 19.1385 13.6198 19.3463 13.521 19.5762C13.4222 19.806 13.3704 20.0533 13.3687 20.3035C13.367 20.5537 13.4153 20.8017 13.5109 21.0329C13.6065 21.2641 13.7474 21.4739 13.9254 21.6498L18.2491 26.1498C18.5716 26.4981 18.9613 26.7773 19.3948 26.9707C19.8282 27.1641 20.2964 27.2675 20.771 27.2748H20.8329C21.298 27.2763 21.7587 27.1854 22.1884 27.0074C22.6181 26.8294 23.0082 26.5679 23.336 26.2379L31.346 18.2279C31.5209 18.0533 31.6598 17.846 31.7546 17.6177C31.8494 17.3895 31.8984 17.1448 31.8986 16.8977C31.8989 16.6505 31.8505 16.4057 31.7561 16.1773C31.6618 15.9489 31.5234 15.7413 31.3488 15.5663C31.1742 15.3914 30.9669 15.2525 30.7387 15.1577C30.5104 15.0629 30.2657 15.0139 30.0186 15.0137C29.7715 15.0134 29.5267 15.0618 29.2982 15.1562C29.0698 15.2505 28.8622 15.3889 28.6872 15.5635Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_79_7578">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldCheckIcon;
