import React from 'react';

import { capitalizeFirstLetter } from '../../utils/helpers';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import CrossIcon from '../../assets/icons/CrossIcon';

interface IModalTemplateProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  body?: React.ReactNode;
  width?: number | string;
}

const ModalTemplate: React.FC<IModalTemplateProps> = ({
  icon,
  title,
  description,
  body,
  width = 670,
}) => {
  const { onClose } = useGlobalModalStore();

  return (
    <div
      className="flex flex-col gap-[15px] pt-[25px] pb-[50px] px-[50px]"
      style={{ width }}
    >
      <button onClick={onClose} className="flex justify-end">
        <CrossIcon width={18} height={18} color="#D4D4D4" strokeWidth={2} />
      </button>
      <div className="flex flex-col gap-[20px]">
        {!!icon && <div className="flex-center w-[45px] h-[45px]">{icon}</div>}
        {!!title && (
          <div className="flex flex-col gap-[10px]">
            <h5 className="text-heading-5 text-heading">
              {capitalizeFirstLetter(title)}
            </h5>
            {!!description && <div>{capitalizeFirstLetter(description)}</div>}
          </div>
        )}
        {!!body && <div>{body}</div>}
      </div>
    </div>
  );
};

export default ModalTemplate;
