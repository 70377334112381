import { useTranslation } from 'react-i18next';

import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import Input from '../../../common/components/Input/Input';
import Button from '../../../common/components/Button/Button';

import useFormLogin from '../hooks/useFormLogin';
// import ExternalAuth from './ExternalAuth';

import ForgotPasswordEmailModal from './modals/ForgotPasswordEmailModal';

const LoginForm = () => {
  const { t } = useTranslation();

  const { setChildren, setIsOpen } = useGlobalModalStore();

  const { control, handleSubmit, isValid } = useFormLogin();

  const openForgotPasswordModal = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    setChildren(<ForgotPasswordEmailModal />);
    setIsOpen(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-[30px] fade-in">
        <Input
          label={t('email_label')}
          name="email"
          control={control}
          placeholder={t('email_placeholder')}
        />
        <Input
          type="password"
          label={
            <div className="flex justify-between items-center">
              <div>{t('password_label')}</div>
              <button
                type="button"
                onClick={openForgotPasswordModal}
                className="text-[12px] text-[#9997A8] font-semibold"
              >
                {t('forgot_password')}
              </button>
            </div>
          }
          name="password"
          control={control}
          placeholder={t('password_placeholder')}
        />
        <div>
          <Button
            className="w-full"
            withCooldown
            type="submit"
            disabled={!isValid}
          >
            {t('sign_in')}
          </Button>
        </div>
        {/*<ExternalAuth />*/}
      </div>
    </form>
  );
};

export default LoginForm;
