import React from 'react';

interface IContainerProps {
  children: React.ReactNode;
  size?: 'large' | 'small';
  className?: string;
}

const Container: React.FC<IContainerProps> = ({
  children,
  size,
  className = '',
}) => {
  const classes =
    size === 'large'
      ? 'sm:max-w-[723px] md:max-w-[868px] lg:max-w-[1157px] xl:max-w-[1446px] 2xl:max-w-[1604px]'
      : 'sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1428px]';

  return (
    <div
      className={`w-full max-w-full ${classes} px-[16px] sm:px-[24px] lg:px-[32px] mx-auto ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
