import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const EmailIcon: FC<ISvgProps> = ({
  height = 41,
  width = 39,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6161 24.0607L14.4297 24.9456L15.313 18.7576L31.2223 2.84831C31.9255 2.14507 32.8793 1.75 33.8738 1.75C34.8683 1.75 35.8221 2.14507 36.5254 2.84831C37.2286 3.55154 37.6237 4.50533 37.6237 5.49985C37.6237 6.49438 37.2286 7.44817 36.5254 8.1514L20.6161 24.0607Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3746 24.2503V36.7497C31.3746 37.4127 31.1112 38.0486 30.6424 38.5174C30.1736 38.9862 29.5377 39.2496 28.8747 39.2496H3.87586C3.21285 39.2496 2.577 38.9862 2.10818 38.5174C1.63936 38.0486 1.37598 37.4127 1.37598 36.7497V11.7509C1.37598 11.0879 1.63936 10.452 2.10818 9.98318C2.577 9.51436 3.21285 9.25098 3.87586 9.25098H16.3753"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
