import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const MakeChatIcon: FC<ISvgProps> = ({
  height = 20,
  width = 20,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8028 1.1978C18.519 0.910514 18.1658 0.701293 17.7774 0.590347C17.3891 0.479401 16.9787 0.47048 16.5859 0.564446L3.91754 3.23243C3.18799 3.33247 2.50085 3.63419 1.93352 4.10361C1.36619 4.57303 0.941205 5.1915 0.706414 5.88938C0.471624 6.58726 0.436359 7.33682 0.60459 8.05365C0.772822 8.77049 1.13787 9.42612 1.65862 9.94672L3.01888 11.306C3.0925 11.3796 3.15089 11.467 3.1907 11.5632C3.2305 11.6594 3.25095 11.7625 3.25087 11.8666V14.3746C3.25262 14.7273 3.33381 15.075 3.4884 15.3919L3.48207 15.3975L3.50265 15.4181C3.73465 15.8845 4.11357 16.2617 4.58105 16.4916L4.60163 16.5122L4.60717 16.5058C4.92417 16.6604 5.27192 16.7416 5.6246 16.7433H8.13293C8.34279 16.7432 8.54413 16.8263 8.69271 16.9745L10.0522 18.3338C10.4168 18.7025 10.8508 18.9953 11.3291 19.1954C11.8075 19.3956 12.3207 19.4991 12.8392 19.5C13.2713 19.4995 13.7005 19.4289 14.11 19.291C14.8016 19.0639 15.4159 18.6482 15.8838 18.0906C16.3516 17.533 16.6543 16.8558 16.7577 16.1353L19.4299 3.44064C19.5288 3.04452 19.523 2.6295 19.413 2.2363C19.3031 1.8431 19.0928 1.48523 18.8028 1.1978ZM4.14003 10.1882L2.77898 8.82886C2.46204 8.5196 2.23992 8.12635 2.13866 7.69529C2.0374 7.26422 2.06122 6.81322 2.20732 6.39522C2.34896 5.96638 2.61081 5.58724 2.96171 5.3029C3.31262 5.01855 3.73782 4.84097 4.18674 4.79126L16.7292 2.15099L4.83283 14.0477V11.8666C4.83403 11.5549 4.77342 11.2461 4.6545 10.958C4.53558 10.6699 4.36072 10.4083 4.14003 10.1882ZM15.2019 15.8646C15.1411 16.3019 14.9596 16.7135 14.6778 17.0534C14.396 17.3933 14.025 17.6479 13.6066 17.7887C13.1881 17.9294 12.7386 17.9508 12.3087 17.8503C11.8787 17.7499 11.4853 17.5316 11.1725 17.2199L9.8107 15.8582C9.59088 15.6372 9.32941 15.462 9.04143 15.3427C8.75344 15.2234 8.44466 15.1624 8.13293 15.1631H5.9516L17.8496 3.27043L15.2019 15.8646Z"
        fill={color}
      />
    </svg>
  );
};

export default MakeChatIcon;
