import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const EmailIcon: FC<ISvgProps> = ({
  height = 31,
  width = 41,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.25 6.38672V25.1367C39.25 26.3799 38.7561 27.5722 37.8771 28.4513C36.998 29.3304 35.8057 29.8242 34.5625 29.8242H6.4375C5.1943 29.8242 4.00201 29.3304 3.12294 28.4513C2.24386 27.5722 1.75 26.3799 1.75 25.1367V6.38672"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.25 6.38672C39.25 5.14352 38.7561 3.95123 37.8771 3.07216C36.998 2.19308 35.8057 1.69922 34.5625 1.69922H6.4375C5.1943 1.69922 4.00201 2.19308 3.12294 3.07216C2.24386 3.95123 1.75 5.14352 1.75 6.38672L18.0156 16.543C18.7606 17.0086 19.6215 17.2555 20.5 17.2555C21.3785 17.2555 22.2394 17.0086 22.9844 16.543L39.25 6.38672Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
