import React, { useState } from 'react';

import { capitalizeFirstLetter } from '../../utils/helpers';

export interface ITabOption {
  title: string;
  action: () => void;
}

interface ITabSwitchProps {
  options: ITabOption[];
  isLarge?: boolean;
}

const TabSwitch: React.FC<ITabSwitchProps> = ({ options, isLarge = false }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
    options[index].action();
  };

  return (
    <div className="grid grid-cols-2 w-full">
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => handleTabChange(index)}
          className={`flex-center ${isLarge ? 'h-[52px] text-200' : 'h-[42px] text-[12px]'} border border-primary ${index === 0 ? 'rounded-[15px_0_0_15px]' : 'rounded-[0_15px_15px_0]'} font-bold cursor-pointer ${activeTabIndex === index ? 'bg-primary text-white' : 'bg-white text-primary'}`}
        >
          {capitalizeFirstLetter(option.title)}
        </div>
      ))}
    </div>
  );
};

export default TabSwitch;
