import React from 'react';

import { capitalizeFirstLetter } from '../../../utils/helpers';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

import CheckIcon from '../../../assets/icons/CheckIcon';

interface ITagOption {
  id: string;
  label: string;
}

interface IField {
  name: string;
  label: string;
  tags: ITagOption[];
}

interface ITagSelectFieldProps {
  field: IField;
  multi?: boolean;
}

const TagSelectField: React.FC<ITagSelectFieldProps> = ({
  field,
  multi = true,
}) => {
  const { filters, setFilters } = usePropertyFiltersStore();

  const handleChange = (value: any) => {
    setFilters({ [`FIELD_${field.name}`]: value });
  };

  return (
    <div className="pb-[25px] border-b border-[#D9D9D9]">
      <div className="text-heading-5 text-heading mb-[10px]">{field.label}</div>
      <div className="flex gap-[25px] flex-wrap">
        {field.tags?.map((option) => {
          const isActive = multi
            ? filters[`FIELD_${field.name}`]?.includes(option.id)
            : filters[`FIELD_${field.name}`] === option.id;

          return (
            <label
              key={option.id}
              className="flex items-center h-[25px] gap-[7px] fade-in animation-fast"
            >
              <div className="relative flex-center w-[24px] h-[24px]">
                <input
                  type="checkbox"
                  className="appearance-none w-[24px] h-[24px] border border-[#006CFB] rounded-[6px] bg-transparent shrink-0 cursor-pointer checked:bg-[#006CFB]"
                  checked={isActive}
                  onChange={() => {
                    if (multi) {
                      if (filters[`FIELD_${field.name}`]?.includes(option.id))
                        handleChange(
                          filters[`FIELD_${field.name}`].filter(
                            (v: string) => v !== option.id,
                          ),
                        );
                      else {
                        const currentFieldValue =
                          filters[`FIELD_${field.name}`] || [];
                        handleChange([...currentFieldValue, option.id]);
                      }
                    } else handleChange(option.id);
                  }}
                />
                <CheckIcon
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  height={10}
                  width={15}
                  color="white"
                  strokeWidth={1}
                />
              </div>
              <div>{capitalizeFirstLetter(option.label)}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default TagSelectField;
