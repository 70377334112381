import React from 'react';
import { useTranslation } from 'react-i18next';

import PropertyMapList from './PropertyMapList';

import MapIcon from '../../../common/assets/icons/MapIcon';

interface IPropertyPanelProps {
  showPanel: boolean;
  togglePanel: () => void;
}

const PropertyPanel: React.FC<IPropertyPanelProps> = ({
  showPanel,
  togglePanel,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`relative h-[calc(100vh-85px)] flex-center bg-[#fff] py-[45px] z-[5] fade-in transition-width duration-500 ${showPanel ? 'w-[600px]' : 'w-[0]'}`}
    >
      <div
        className={`w-full ${showPanel ? 'opacity-100 duration-500' : 'opacity-0 duration-200'} transition-opacity`}
      >
        <PropertyMapList />
      </div>
      <button
        onClick={togglePanel}
        className="absolute top-[23px] -right-[22px] z-[50] transform translate-x-full flex-center gap-[8px] w-[196px] h-[68px] bg-[#fff] rounded-[20px] cursor-pointer"
      >
        <div>
          <MapIcon width={18} height={18} color="#152242" />
        </div>
        <div>{showPanel ? t('expand_map') : t('show_panel')}</div>
      </button>
    </div>
  );
};

export default PropertyPanel;
