import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface IField {
  name: string;
  label: string;
  meta?: {
    unit?: string;
  };
}

interface INumberFieldProps {
  field: IField;
}

const NumberField: React.FC<INumberFieldProps> = ({ field }) => {
  const { t } = useTranslation();

  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (values: { value: string }) => {
    setFilters({ [`FIELD_${field.name}From`]: values.value });
  };

  const handleToChange = (values: { value: string }) => {
    setFilters({ [`FIELD_${field.name}To`]: values.value });
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <h5 className="text-heading-5 text-heading">{field.label}</h5>
      <div className="grid grid-cols-2 gap-[20px]">
        <div>
          <div className="text-body-small text-[#9997A8] mb-[6px]">
            {t('from')}
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleFromChange}
              value={filters[`FIELD_${field.name}From`] || ''}
              placeholder="0"
              className="w-full h-[66px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
              allowNegative={false}
            />
            {field.meta?.unit && (
              <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
                {field.meta?.unit}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="text-body-small text-[#9997A8] mb-[6px]">
            {t('to')}
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleToChange}
              value={filters[`FIELD_${field.name}To`] || ''}
              placeholder="10 000"
              className="w-full h-[66px] border border-secondary rounded-[20px] outline-none pl-[16px] pr-[38px] text-body-small placeholder-placeholder"
              allowNegative={false}
            />
            {field.meta?.unit && (
              <div className="absolute top-1/2 right-[16px] transform -translate-y-1/2 text-body-small text-[#9997A8]">
                {field.meta?.unit}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberField;
