import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getCategoryList } from '../../api/services/category/requests';

import { ICategory } from '../types/dto/category';

import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';

export interface IConvertedCategory {
  id: string;
  title: string;
  name: string;
  fullName?: string;
  description?: string;
  entities?: IConvertedCategory[];
  children?: IConvertedCategory[];
}

const flattenCategories = (
  categories: IConvertedCategory[],
): IConvertedCategory[] => {
  const result: IConvertedCategory[] = [];

  const traverse = (category: IConvertedCategory) => {
    result.push(category);

    if (category.children) {
      category.children.forEach(traverse);
    }

    if (category.entities) {
      category.entities.forEach(traverse);
    }
  };

  categories.forEach(traverse);

  return result;
};

const convertCategoryTree = (nodes: ICategory[]): IConvertedCategory[] => {
  return nodes.map((node) => {
    const childrenWithChildren: IConvertedCategory[] = [];
    const entities: IConvertedCategory[] = [];

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        if (!child.children || child.children.length === 0) {
          entities.push({
            id: child.id,
            title: child.title,
            name: child.name,
          });
        } else {
          childrenWithChildren.push(convertCategoryTree([child])[0]);
        }
      });
    }

    const nameStringArr = node.name.split('.');
    const result: IConvertedCategory = {
      id: node.id,
      title: node.title,
      fullName: node.name,
      name: nameStringArr[nameStringArr.length - 1],
      description: node.description,
    };

    if (entities.length > 0) {
      result.entities = entities;
    }

    if (childrenWithChildren.length > 0) {
      result.children = childrenWithChildren;
    }

    return result;
  });
};

const usePropertyCategories = () => {
  const { lang } = useParams();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data: categories = [] } = useQuery(
    ['categories', lang],
    async () => {
      setLoader(true);

      const data = await getCategoryList();

      setLoader(false);
      return data;
    },
    {
      staleTime: Infinity,
      keepPreviousData: true,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const convertedCategories = useMemo(
    () => convertCategoryTree(categories),
    [categories],
  );

  const flatCategories = useMemo(
    () => flattenCategories(convertedCategories),
    [convertedCategories],
  );

  return {
    categories: convertedCategories,
    flatCategories,
  };
};

export default usePropertyCategories;
