import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const FullScreenIcon: FC<ISvgProps> = ({
  height = 24,
  width = 24,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_14814)">
        <path
          d="M18.9998 24H16.9998C16.7345 24 16.4802 23.8946 16.2926 23.7071C16.1051 23.5196 15.9998 23.2652 15.9998 23C15.9998 22.7348 16.1051 22.4804 16.2926 22.2929C16.4802 22.1053 16.7345 22 16.9998 22H18.9998C19.7954 22 20.5585 21.6839 21.1211 21.1213C21.6837 20.5587 21.9997 19.7956 21.9997 19V17C21.9997 16.7348 22.1051 16.4804 22.2926 16.2929C22.4802 16.1054 22.7345 16 22.9997 16C23.265 16 23.5193 16.1054 23.7068 16.2929C23.8944 16.4804 23.9997 16.7348 23.9997 17V19C23.9982 20.3256 23.4709 21.5964 22.5335 22.5338C21.5962 23.4711 20.3253 23.9984 18.9998 24Z"
          fill={color}
        />
        <path
          d="M1 8C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7V5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1C8 1.26522 7.89464 1.51957 7.70711 1.70711C7.51957 1.89464 7.26522 2 7 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8Z"
          fill={color}
        />
        <path
          d="M7 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 17C0 16.7348 0.105357 16.4804 0.292893 16.2929C0.48043 16.1054 0.734784 16 1 16C1.26522 16 1.51957 16.1054 1.70711 16.2929C1.89464 16.4804 2 16.7348 2 17V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H7C7.26522 22 7.51957 22.1053 7.70711 22.2929C7.89464 22.4804 8 22.7348 8 23C8 23.2652 7.89464 23.5196 7.70711 23.7071C7.51957 23.8946 7.26522 24 7 24Z"
          fill={color}
        />
        <path
          d="M22.9997 8C22.7345 8 22.4802 7.89464 22.2926 7.70711C22.1051 7.51957 21.9997 7.26522 21.9997 7V5C21.9997 4.20435 21.6837 3.44129 21.1211 2.87868C20.5585 2.31607 19.7954 2 18.9998 2H16.9998C16.7345 2 16.4802 1.89464 16.2926 1.70711C16.1051 1.51957 15.9998 1.26522 15.9998 1C15.9998 0.734784 16.1051 0.48043 16.2926 0.292893C16.4802 0.105357 16.7345 0 16.9998 0L18.9998 0C20.3253 0.00158786 21.5962 0.528882 22.5335 1.46622C23.4709 2.40356 23.9982 3.67441 23.9997 5V7C23.9997 7.26522 23.8944 7.51957 23.7068 7.70711C23.5193 7.89464 23.265 8 22.9997 8Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_25_14814">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FullScreenIcon;
