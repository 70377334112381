import { IImage } from '../media';

export enum EUserRole {
  Admin = 'admin',
  Agent = 'agent',
}

export interface IUser {
  id: string;
  code: number;
  role: EUserRole;
  name: string;
  avatar: IImage;
  email: string;
  phone: string;
  isEmailConfirmed: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  agentProfile: IAgentProfile;
  salePropertiesCount: number;
  rentPropertiesCount: number;
  balance: number;
  googleAccountId?: string;
  facebookAccountId?: string;
  appleAccountId?: string;
}

interface IAgentProfile {
  id: string;
  verified: boolean;
  rejectReason: string;
  businessType: 'enterpreter';
  user: string;
  legalName: string;
  address: string;
  city: string;
  postalCode: number;
  ic: string;
  dic: string;
  email: string;
  phone: string;
  description: string;
  web: string;
  instagram: string;
  twitter: string;
  facebook: string;
  tikTok: string;
}
