import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../common/utils/helpers';

import { ReactComponent as EditIcon } from '../../../common/assets/icons/editIcon.svg';
import { ReactComponent as BellIcon } from '../../../common/assets/icons/bellIcon.svg';

interface IPropertySearchesListItemProps {
  searchItem: any;
  flatCategories: any;
  processFields: any;
  handleEditSearch: any;
}

const PropertySearchesListItem: React.FC<IPropertySearchesListItemProps> = ({
  searchItem,
  flatCategories,
  processFields,
  handleEditSearch,
}) => {
  const {
    id,
    name,
    filters = {},
    searchResultCount,
    notificationInterval,
  } = searchItem;
  const { categories, fields, priceFrom, priceTo } = filters;

  const { t } = useTranslation();

  const { description } = processFields(fields);

  const getStringNotificationInterval = useCallback(
    (notificationInterval: number) => {
      switch (notificationInterval) {
        case 1:
          return t('once_an_hour');
        case 24:
          return t('once_a_day');
        case 168:
          return t('once_a_week');
        case 0 || null:
          return t('dont_notify_me');
        default:
          return t('once_every_x_hours', {
            notificationInterval: notificationInterval,
          });
      }
    },
    [notificationInterval],
  );

  const category = flatCategories.find((cat: any) => cat.id === categories[0]);
  const categoryNameArray = category?.name?.split('.').slice(0, -1) || [];
  const parts = [
    t('for'),
    t(categoryNameArray[0]),
    capitalizeFirstLetter(t(categoryNameArray[1])),
    capitalizeFirstLetter(t(categoryNameArray[2])),
    category?.title,
  ];

  const categoryString = parts.filter(Boolean).join(' ');

  const priceParts = (priceFrom || priceTo) && [
    `${t('price')}:`,
    `${priceFrom || 0} Kc`,
    '-',
    priceTo && `${priceTo || '∞'} Kc`,
  ];

  const priceString = priceParts?.filter(Boolean).join(' ');

  const dataParts = [
    priceString,
    priceString && description && '. ',
    description,
  ];

  const dataString = dataParts.filter(Boolean).join('');

  const handleEditSearchClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    handleEditSearch(id, name, notificationInterval);
  };

  return (
    <div key={id}>
      <div className="flex items-center gap-[15px] mb-[5px]">
        <div className="text-heading-2 text-heading">
          {capitalizeFirstLetter(name)}
        </div>
        <button onClick={handleEditSearchClick}>
          <EditIcon />
        </button>
      </div>
      <div className="flex flex-col gap-[10px]">
        <div className="text-accent">{categoryString}</div>
        <div className="flex items-center gap-[10px]">
          <div>
            <BellIcon />
          </div>
          <div>
            {getStringNotificationInterval(notificationInterval) || t('never')}
          </div>
        </div>
        <div>
          {searchResultCount} {t('listings_found')}
        </div>
        <div>{dataString}</div>
      </div>
    </div>
  );
};

export default PropertySearchesListItem;
