import React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { profileMenuItems } from '../../common/constants/menu';

import Header from '../../common/components/Header/Header';
import Footer from '../../common/components/Footer/Footer';
import Container from '../../common/components/Container/Container';

const LayoutProfile = () => {
  const { lang } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isActivePath = (href: string): boolean => {
    if (href === '') {
      return pathname === `/${lang}/` || pathname === `/${lang}`;
    }

    const regex = new RegExp(
      `^/${lang}${href.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}/?$`,
    );
    return regex.test(pathname);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Container className="flex-grow flex flex-col min-h-[750px]">
        <div className="flex-grow grid grid-cols-[209px_1fr] pt-[45px]">
          <div className="flex flex-col gap-[15px] w-full h-full border-r border-[#D9D9D9] pb-[45px]">
            {profileMenuItems.map((item) => {
              const { label, href } = item;
              return (
                <Link key={label} to={`/${lang}${href}`}>
                  <div
                    className={`relative inline-block ${isActivePath(href) ? 'text-accent' : ''} hover:text-accent`}
                    title={t(label)}
                  >
                    {t(label)}
                  </div>
                </Link>
              );
            })}
          </div>
          <Outlet />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default LayoutProfile;
