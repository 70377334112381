import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface IField {
  name: string;
  label: string;
}

interface ICounterFieldProps {
  field: IField;
}

const CounterField: React.FC<ICounterFieldProps> = ({ field }) => {
  const { t } = useTranslation();
  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (value: number) => {
    setFilters({ [`FIELD_${field.name}From`]: value });
  };

  return (
    <div
      className="
        grid
        grid-cols-1
        sm:grid-cols-[1fr_auto]
        items-center
        gap-y-2 sm:gap-y-0
        gap-x-0 sm:gap-x-[20px]
      "
    >
      <div>{field.label}</div>
      <div className="flex items-center gap-[10px]">
        <div className="text-body-small text-[#9997A8]">
          {t('placeholder-from')}
        </div>
        <div className="relative flex-center w-[102px] h-[66px]">
          <div
            className={classNames(
              'absolute top-1/2 transform -translate-y-1/2 left-[10px] select-none leading-[0]',
              {
                'text-accent cursor-pointer':
                  +filters[`FIELD_${field.name}From`] > 0,
                'text-[#9997A8]':
                  +filters[`FIELD_${field.name}From`] === 0 ||
                  filters[`FIELD_${field.name}From`] === undefined,
              },
            )}
            onClick={() => {
              const oldValue = filters[`FIELD_${field.name}From`] || 0;
              const newValue = +oldValue > 0 ? +oldValue - 1 : 0;
              handleFromChange(newValue);
            }}
          >
            -
          </div>
          <input
            className="w-full h-full rounded-[20px] px-[16px] border outline-none border-secondary text-heading text-center"
            value={filters[`FIELD_${field.name}From`] || 0}
            onChange={(e) => handleFromChange(Number(e.target.value))}
            placeholder={t('placeholder-from')}
          />
          <div
            className="absolute top-1/2 transform -translate-y-1/2 right-[10px] text-accent cursor-pointer select-none"
            onClick={() => {
              const oldValue = filters[`FIELD_${field.name}From`] || 0;
              const newValue = +oldValue >= 0 ? +oldValue + 1 : 0;
              handleFromChange(newValue);
            }}
          >
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterField;
