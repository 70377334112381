import React, { useCallback, useRef, useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';

import { useGoogleMaps } from '../../../api/google/GoogleMapsProvider';

import { mapStyle } from '../../../common/constants/map';

import { addPriceDelimiters } from '../../../common/utils/helpers';

import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';
import usePropertyStore from '../../../common/stores/usePropertyStore';

const mapContainerStyle = { width: '100%', height: '100%' };
const defaultZoom = 12;

const getPriceSvg = (price: number): string => {
  const priceText = `${addPriceDelimiters(price)} Kč`;
  const textLength = priceText.length;
  const width = Math.max(70, textLength * 8); // Минимальная ширина - 70, увеличивается в зависимости от длины текста.

  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="20" viewBox="0 0 ${width} 20">
      <rect x="0.5" y="0.5" width="${width - 1}" height="19" fill="white" stroke="#006CFB" rx="7.5" />
      <text x="${width / 2}" y="12" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="12px" font-weight="normal" font-family="sans-serif">
        ${priceText}
      </text>
    </svg>
  `;
};

const PropertyMap: React.FC = () => {
  const { coordinates, setCoordinates } = usePropertySearchStore();
  const { properties, polygon } = usePropertyStore();

  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState(coordinates);

  const googleMapsContext = useGoogleMaps();

  const mapRef = useRef<google.maps.Map | null>(null);
  const clusterRef = useRef<MarkerClusterer | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const polyRefs = useRef<google.maps.Polygon[]>([]);
  const prevPolygonsRef = useRef<any>();
  const didMount = useRef(false);
  const mapLoaded = useRef(false);

  const handleLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    mapLoaded.current = true;
  }, []);

  const handleBoundsChanged = useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      const mapCenter = mapRef.current.getCenter()?.toJSON();
      const mapZoom = mapRef.current.getZoom() || defaultZoom;

      if (
        mapCenter &&
        (center.lat !== mapCenter.lat || center.lng !== mapCenter.lng)
      ) {
        setCenter(mapCenter);
      }

      if (zoom !== mapZoom) {
        setZoom(mapZoom);
      }

      if (bounds && mapCenter) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        // const topDistance = window.google?.maps.geometry.spherical.computeDistanceBetween(
        //   new window.google.maps.LatLng(mapCenter.lat, mapCenter.lng),
        //   new window.google.maps.LatLng(northEast.lat(), mapCenter.lng)
        // );
        //
        // const leftDistance = window.google?.maps.geometry.spherical.computeDistanceBetween(
        //   new window.google.maps.LatLng(mapCenter.lat, mapCenter.lng),
        //   new window.google.maps.LatLng(mapCenter.lat, southWest.lng())
        // );
        //
        // const radius = Math.min(topDistance, leftDistance) / 1000;

        const diameter =
          window.google?.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(northEast),
            new window.google.maps.LatLng(southWest),
          );

        const radius = diameter / 2000;

        if (
          coordinates.lat !== mapCenter.lat ||
          coordinates.lng !== mapCenter.lng ||
          coordinates.radius !== radius
        ) {
          setCoordinates({
            lat: mapCenter.lat,
            lng: mapCenter.lng,
            radius,
          });
        }
      }
    }
  }, [
    center.lat,
    center.lng,
    coordinates.lat,
    coordinates.lng,
    coordinates.radius,
    setCoordinates,
    zoom,
  ]);

  const handleMarkers = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (mapRef.current && properties) {
        if (clusterRef.current) {
          clusterRef.current.clearMarkers();
        }

        const markers = properties
          .filter(
            (property) =>
              property.address?.coordinates?.lat &&
              property.address?.coordinates?.lon,
          )
          .map((property) => {
            if (!property.address?.coordinates) return null;

            const priceRect = getPriceSvg(property.price);

            const circleIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30">
              <circle cx="15" cy="15" r="15" fill="#006CFB" />
            </svg>
          `;

            const icon = zoom < 15 ? circleIcon : priceRect;

            const marker = new window.google.maps.Marker({
              position: {
                lat: property.address.coordinates.lat as number,
                lng: property.address.coordinates.lon as number,
              },
              icon: {
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(icon),
                // scaledSize: new window.google.maps.Size(30, 30),
                // anchor: new window.google.maps.Point(15, 15),
              },
            });

            marker.addListener('click', () => {
              setZoom(18);
              setCenter({
                lat: property.address?.coordinates?.lat as number,
                lng: property.address?.coordinates?.lon as number,
              });
            });

            return marker;
          });

        const customRenderer = {
          render({
            count,
            position,
          }: {
            count: number;
            position: google.maps.LatLng;
          }) {
            // const iconSize = 22;
            const clusterIcon = `
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <circle cx="11" cy="11" r="11" fill="#006CFB" />
                <text x="11" y="12" dominant-baseline="middle" text-anchor="middle" font-size="12px" font-size-adjust="0.5" fill="white" font-family="sans-serif" font-weight="bold">
                  ${count > 4 ? '4+' : count}
                </text>
              </svg>
            `;

            return new window.google.maps.Marker({
              position,
              icon: {
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(clusterIcon),
                // anchor: new window.google.maps.Point(iconSize / 2, iconSize / 2),
                // scaledSize: new window.google.maps.Size(iconSize, iconSize),
              },
              zIndex: count,
            });
          },
        };

        if (!clusterRef.current) {
          clusterRef.current = new MarkerClusterer({
            markers: markers as google.maps.Marker[],
            map: mapRef.current,
            renderer: customRenderer,
            algorithm: new SuperClusterAlgorithm({ maxZoom: 16 }),
          });
        } else {
          clusterRef.current.addMarkers(markers as google.maps.Marker[]);
        }
      }
    }, 500);
  }, [properties, zoom]);

  const zoomIn = () => {
    if (mapRef.current) {
      const newZoom = Math.min(20, zoom + 1);
      setZoom(newZoom);
      mapRef.current.setZoom(newZoom);
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      const newZoom = Math.max(0, zoom - 1);
      setZoom(newZoom);
      mapRef.current.setZoom(newZoom);
    }
  };

  const handleAddPolygons = useCallback((polygonsCoordinates: number[][][]) => {
    polyRefs.current?.forEach((polygon) => polygon.setMap(null));

    const newPolygons = polygonsCoordinates.map((polygonCoordinates) => {
      return new window.google.maps.Polygon({
        paths: polygonCoordinates.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        })),
        strokeColor: '#006CFB',
        fillColor: '#006CFB',
        fillOpacity: 0.2,
        strokeOpacity: 1,
        strokeWeight: 1,
      });
    });

    newPolygons.forEach((polygon) => polygon.setMap(mapRef.current));

    polyRefs.current = newPolygons;

    if (!window.google?.maps?.LatLngBounds || !polygonsCoordinates?.length)
      return;

    const bounds = new window.google.maps.LatLngBounds();
    polygonsCoordinates.forEach((polygonCoordinates) => {
      polygonCoordinates.forEach(([lng, lat]) => {
        bounds.extend({ lat, lng });
      });
    });

    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const expandFactor = 1.1;

    const latDiff =
      ((northEast.lat() - southWest.lat()) * (expandFactor - 1)) / 2;
    const lngDiff =
      ((northEast.lng() - southWest.lng()) * (expandFactor - 1)) / 2;

    const expandedBounds = new window.google.maps.LatLngBounds(
      { lat: southWest.lat() - latDiff, lng: southWest.lng() - lngDiff },
      { lat: northEast.lat() + latDiff, lng: northEast.lng() + lngDiff },
    );

    if (mapRef.current) {
      mapRef.current.fitBounds(expandedBounds);
    }
  }, []);

  useEffect(() => {
    handleMarkers();
  }, [handleMarkers]);

  useEffect(() => {
    if (mapRef.current && coordinates) {
      mapRef.current.panTo({ lat: coordinates.lat, lng: coordinates.lng });
    }
  }, [coordinates]);

  useEffect(() => {
    const polygons = polygon?.coordinates || [];

    const shallUpdatePolygons =
      !didMount.current ||
      !polyRefs.current?.length ||
      JSON.stringify(prevPolygonsRef.current) !== JSON.stringify(polygons);

    if (mapLoaded.current && shallUpdatePolygons) {
      handleAddPolygons(polygons);
      prevPolygonsRef.current = polygons;
      didMount.current = true;
    }
  }, [polygon, handleAddPolygons]);

  useEffect(() => {
    return () => {
      didMount.current = false;
    };
  }, []);

  if (!googleMapsContext) {
    return <div>Loading Map...</div>;
  }

  const { isApiLoaded, loadError } = googleMapsContext;

  if (loadError) return <div>Loading Map Failed!</div>;

  return isApiLoaded ? (
    <div className="relative h-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        onLoad={handleLoad}
        onIdle={handleBoundsChanged}
        onUnmount={() => {
          if (clusterRef.current) {
            clusterRef.current.clearMarkers();
          }
        }}
        options={{
          // streetViewControl: false,
          // rotateControl: true,
          // mapTypeControl: false,
          // fullscreenControl: false,
          disableDefaultUI: true,
          clickableIcons: false,
          backgroundColor: '#e9e9e9',
          draggableCursor: '"", default;',
          draggingCursor: '"", default;',
          styles: mapStyle,
        }}
      >
        <React.Fragment></React.Fragment>
      </GoogleMap>
      <div className="absolute bottom-[30px] right-[25px] flex flex-col items-center w-[46px] bg-[#006CFB] rounded-[15px]">
        <button
          className="flex-center w-[46px] h-[60px] text-[#ffffff]"
          onClick={zoomIn}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="15"
              y1="5"
              x2="15"
              y2="25"
              stroke="#fff"
              strokeWidth="2"
            />
            <line
              x1="5"
              y1="15"
              x2="25"
              y2="15"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </button>
        <div className="w-[35px] h-[1px] bg-[#ffffff]" />
        <button
          className="flex-center w-[46px] h-[60px] text-[#ffffff]"
          onClick={zoomOut}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="5"
              y1="15"
              x2="25"
              y2="15"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </div>
  ) : (
    <div>Loading Map...</div>
  );
};

export default PropertyMap;
