import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';
import { IProperty, IPropertySearch } from '../../../common/types/dto/property';
import {
  IGetPropertySearchFullResponse,
  IGetPropertySearchPayload,
  IPostPropertyComplaintPayload,
  IPostPropertyComplaintResponse,
} from './types';

export const getPropertySearch = async (payload: IGetPropertySearchPayload) => {
  const response: AxiosResponse<
    IResponse<IGetPropertySearchFullResponse<IPropertySearch>>
  > = await axiosInstance.get('/property/search', {
    params: payload,
  });
  return response.data.data;
};

export const getPropertyById = async (id: string) => {
  const response: AxiosResponse<IResponse<IProperty>> = await axiosInstance.get(
    `/property/${id}`,
  );
  return response.data.data;
};

export const getPropertyFavorite = async () => {
  const response: AxiosResponse<IResponse<any>> =
    await axiosInstance.get('/property/favorite');
  return response.data.data;
};

export const getPropertySearchList = async () => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get(
    '/property/search/list',
  );
  return response.data.data;
};

export const postPropertySearchSave = async (payload: any) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    `/property/search/save`,
    payload,
  );
  return response.data.data;
};

export const putPropertySearchUpdate = async (id: string, payload: any) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.put(
    `/property/search/${id}/update`,
    payload,
  );
  return response.data.data;
};

export const postPropertyComplaint = async (
  id: string,
  payload: IPostPropertyComplaintPayload,
) => {
  const response: AxiosResponse<IResponse<IPostPropertyComplaintResponse>> =
    await axiosInstance.post(`/property/${id}/complaint`, payload);
  return response.data.data;
};

export const postPropertyFavoriteAddById = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    `/property/${id}/favorite/add`,
  );
  return response.data.data;
};

export const deletePropertyFavoriteRemoveById = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.delete(
    `/property/${id}/favorite/remove`,
  );
  return response.data.data;
};
