import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';

import {
  IGetUserMeResponse,
  IPatchUpdateAgentPayload,
  IGetAgentsList,
  IUserCreditsDeductPayload,
  IUserCreditsAddPayload,
  IPatchUpdateClientPayload,
} from './types';

export const getUserMe = async () => {
  const response: AxiosResponse<IResponse<IGetUserMeResponse>> =
    await axiosInstance.get('/user/me');
  return response.data.data;
};

export const patchUpdateAgent = async (payload: IPatchUpdateAgentPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch(
    '/user/agent/profile',
    payload,
  );
  return response.data.data;
};

export const patchUpdateClient = async (payload: IPatchUpdateClientPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch(
    '/user/client/profile',
    payload,
  );
  return response.data.data;
};

export const getUsersList = async (payload: IGetAgentsList) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get(
    '/user/list',
    {
      params: {
        ...payload,
      },
    },
  );
  return response.data.data;
};

export const postUserCreditsDeduct = async (
  payload: IUserCreditsDeductPayload,
) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    '/user/credits/deduct',
    payload,
  );
  return response.data.data;
};

export const postUserCreditsAdd = async (payload: IUserCreditsAddPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    '/user/credits/add',
    payload,
  );
  return response.data.data;
};
