import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const LikeIcon: FC<ISvgProps> = ({
  height = 20,
  width = 22,
  color = '#006CFB',
  fill = 'none',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0004 18.7493L2.47805 9.86076C1.72905 9.11234 1.23469 8.1471 1.0651 7.10193C0.8955 6.05676 1.05928 4.98472 1.5332 4.03787V4.03787C1.89057 3.32334 2.41261 2.70394 3.05629 2.23073C3.69998 1.75751 4.4469 1.44401 5.23551 1.31606C6.02411 1.18812 6.83184 1.24938 7.59212 1.49481C8.35241 1.74024 9.0435 2.16281 9.60845 2.7277L11.0004 4.11875L12.3923 2.7277C12.9573 2.16281 13.6484 1.74024 14.4087 1.49481C15.1689 1.24938 15.9767 1.18812 16.7653 1.31606C17.5539 1.44401 18.3008 1.75751 18.9445 2.23073C19.5882 2.70394 20.1102 3.32334 20.4676 4.03787C20.9409 4.98436 21.1044 6.05576 20.935 7.10033C20.7655 8.1449 20.2718 9.1097 19.5236 9.85809L11.0004 18.7493Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikeIcon;
