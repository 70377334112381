import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../../common/utils/helpers';
import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';
import usePropertyStore from '../../../common/stores/usePropertyStore';

import usePropertyCategories from '../../../common/hooks/usePropertyCategories';
import usePropertySearchSave from '../../../common/hooks/usePropertySearchSave';

import PropertyList from '../../../common/components/PropertyList/PropertyList';
import PropertySort from '../../../common/components/PropertySort/PropertySort';

import ListIcon from '../../../common/assets/icons/ListIcon';
import TilesIcon from '../../../common/assets/icons/TilesIcon';

import { ReactComponent as MapIcon } from '../../../common/assets/icons/mapIcon.svg';
import { ReactComponent as SaveSearchIcon } from '../../../common/assets/icons/saveSearch.svg';
import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

const PropertySearchList = () => {
  const [listTiles, setListTiles] = useState(false);

  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { propertyTypes, address } = usePropertySearchStore();
  const { properties, total, setLimit, refetch } = usePropertyStore();

  const { handleSaveSearch } = usePropertySearchSave();
  const { categories } = usePropertyCategories();

  const title = useMemo(() => {
    const currentType = propertyTypes.path[0];
    const entities = getSubTree(categories, propertyTypes.path)?.entities || [];
    const entityName =
      entities.find((ent) => ent.id === propertyTypes.entities[0]?.id)?.title ||
      '';

    const parts = [
      currentType === 'rent' ? t('rent') : t('sale'),
      entityName ? `of ${entityName}s` : '',
      address.city ? `in ${address.city}` : '',
    ];

    return parts.filter(Boolean).join(' ');
  }, [propertyTypes.path, propertyTypes.entities, categories, address.city, t]);

  const handleMapRedirect = () => {
    navigate(`/${lang}/map`);
  };

  const setMode = (isTiles: boolean) => {
    setListTiles(isTiles);
    setLimit(isTiles ? 40 : 15);
  };

  return (
    <div>
      {/* Верхний блок с заголовком, кол-вом найденных и кнопками */}
      <div className="mb-[35px]">
        {/*
          flex-col для мобильных (до sm)
          flex-row с justify-between — от sm и выше
        */}
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
          {/* Левая часть: заголовок, кол-во и кнопки (sort, map, save) */}
          <div>
            <h2 className="text-display-4 text-heading mb-[10px]">{title}</h2>

            {/* flex-wrap, чтобы при нехватке места кнопки переносились на новую строку */}
            <div className="flex items-center flex-wrap gap-[17px]">
              <div>
                {total} {t('listings_found')}
              </div>
              <PropertySort />
              <div className="hidden sm:flex">
                <button
                  onClick={handleMapRedirect}
                  className="flex-center gap-[5px] h-[25px] text-accent font-medium underline"
                >
                  <MapIcon />
                  <span>{t('map_view')}</span>
                </button>
              </div>
              <div className="hidden sm:flex">
                <button
                  onClick={handleSaveSearch}
                  className="flex-center gap-[5px] text-accent h-[25px] font-medium underline"
                >
                  <SaveSearchIcon />
                  <span>{t('save_search')}</span>
                </button>
              </div>
            </div>
          </div>

          {/* Правая часть: переключатель List/Tiles */}
          <div className="flex items-center gap-[15px] self-end sm:self-auto">
            <button onClick={() => setMode(false)}>
              <ListIcon {...(!listTiles && { color: '#006CFB' })} />
            </button>
            <button onClick={() => setMode(true)}>
              <TilesIcon {...(listTiles && { color: '#006CFB' })} />
            </button>
          </div>
        </div>
      </div>

      {/* Содержимое: либо пустой список, либо PropertyList */}
      {properties.length === 0 ? (
        <div className="flex flex-col items-center gap-[17px] py-[35px]">
          <EmptyListImage />
          <div className="fade-in">{t('empty_list')}</div>
        </div>
      ) : (
        <PropertyList
          items={properties}
          isTiles={listTiles}
          refetch={refetch as () => void}
        />
      )}
    </div>
  );
};

export default PropertySearchList;
