import { useTranslation } from 'react-i18next';

import ModalTemplate from '../../../../common/components/Modal/ModalTemplate';
import Input from '../../../../common/components/Input/Input';
import Button from '../../../../common/components/Button/Button';

import useFormResetPasswordEmail from '../../hooks/useFormResetPasswordEmail';

import { ReactComponent as EmailIcon } from '../../../../common/assets/icons/emailIcon.svg';

const ModalBody = () => {
  const { t } = useTranslation();

  const { handleSubmit, control, isValid } = useFormResetPasswordEmail();

  return (
    <form onSubmit={handleSubmit} className="flex items-end gap-[25px]">
      <div className="flex-1">
        <Input
          label={t('email_label')}
          name="email"
          control={control}
          placeholder={t('email_placeholder')}
        />
      </div>
      <div>
        <Button
          variant="primary-outlined"
          withCooldown
          type="submit"
          disabled={!isValid}
        >
          {t('send_code')}
        </Button>
      </div>
    </form>
  );
};

const ForgotPasswordEmailModal = () => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<EmailIcon />}
      title={t('reset_password')}
      description={t('reset_password_description')}
      body={<ModalBody />}
    />
  );
};

export default ForgotPasswordEmailModal;
