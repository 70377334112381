import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';

import { API_URL } from '../common/constants/env';

import useAuthStore from '../common/stores/useAuthStore';

interface IErrorResponse {
  message: string;
  data: any;
}

export const getLocaleFromUrl = (): string => {
  const path = window?.location.pathname || '';
  const match = path.match(/^\/([a-z]{2})(?:\/|$)/);
  return match ? match[1] : 'cz';
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

const refreshJWToken = (refreshToken?: string | null) => {
  return new Promise((resolve, reject) => {
    if (!refreshToken) {
      useAuthStore.getState().logout();
      window.location.href = '/';
      return reject(true);
    }
    axios
      .post(`${API_URL}/auth/refresh-token`, {
        refreshToken,
      })
      .then((response) => {
        const data = response.data.data;

        useAuthStore.getState().setRefreshToken(data.refreshToken);
        useAuthStore.getState().setAccessToken(data.accessToken);

        resolve(false);
      })
      .catch(() => {
        useAuthStore.getState().logout();
        window.location.href = '/';
        reject(true);
      });
  });
};

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = useAuthStore.getState().accessToken;
    const impersonationToken = useAuthStore.getState().impersonationToken;

    config.headers = config.headers || {};
    config.headers['locale'] = getLocaleFromUrl();

    if (impersonationToken) {
      config.headers['Authorization'] = `Bearer ${impersonationToken}`;
    } else if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<IErrorResponse>) => {
    const originalRequest: any = error.config;

    if (error?.response?.status === 401) {
      if (error.response.data?.data?.name === 'refreshTokenIsExpired') {
        if (originalRequest) {
          originalRequest._retry = false;
        }
        useAuthStore.getState().logout();
        return Promise.reject(error);
      }

      const refreshToken = useAuthStore.getState().refreshToken;
      if (refreshToken) {
        const isError = await refreshJWToken(refreshToken);

        if (!isError) {
          if (originalRequest) {
            originalRequest._retry = true;
            originalRequest.headers.Authorization = `Bearer ${useAuthStore.getState().accessToken}`;

            return axios(originalRequest);
          }
        }
      }

      useAuthStore.getState().logout();

      // if (window.location.pathname !== '/cz/') {
      //   window.location.href = '/cz/';
      // }
    }

    throw error;
  },
);

export default axiosInstance;
