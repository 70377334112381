import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CheckIcon: FC<ISvgProps> = ({
  height = 16,
  width = 16,
  color = '#006CFB',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_414_10582)">
        <path
          d="M4.66667 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 4.66667C0 5.37391 0.280952 6.05219 0.781049 6.55229C1.28115 7.05238 1.95942 7.33333 2.66667 7.33333H4.66667C5.37391 7.33333 6.05219 7.05238 6.55229 6.55229C7.05238 6.05219 7.33333 5.37391 7.33333 4.66667V2.66667C7.33333 1.95942 7.05238 1.28115 6.55229 0.781049C6.05219 0.280952 5.37391 0 4.66667 0V0ZM6 4.66667C6 5.02029 5.85952 5.35943 5.60948 5.60948C5.35943 5.85952 5.02029 6 4.66667 6H2.66667C2.31304 6 1.97391 5.85952 1.72386 5.60948C1.47381 5.35943 1.33333 5.02029 1.33333 4.66667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H4.66667C5.02029 1.33333 5.35943 1.47381 5.60948 1.72386C5.85952 1.97391 6 2.31304 6 2.66667V4.66667Z"
          fill={color}
        />
        <path
          d="M4.66667 8.66663H2.66667C1.95942 8.66663 1.28115 8.94758 0.781049 9.44768C0.280952 9.94777 0 10.6261 0 11.3333L0 13.3333C0 14.0405 0.280952 14.7188 0.781049 15.2189C1.28115 15.719 1.95942 16 2.66667 16H4.66667C5.37391 16 6.05219 15.719 6.55229 15.2189C7.05238 14.7188 7.33333 14.0405 7.33333 13.3333V11.3333C7.33333 10.6261 7.05238 9.94777 6.55229 9.44768C6.05219 8.94758 5.37391 8.66663 4.66667 8.66663ZM6 13.3333C6 13.6869 5.85952 14.0261 5.60948 14.2761C5.35943 14.5262 5.02029 14.6666 4.66667 14.6666H2.66667C2.31304 14.6666 1.97391 14.5262 1.72386 14.2761C1.47381 14.0261 1.33333 13.6869 1.33333 13.3333V11.3333C1.33333 10.9797 1.47381 10.6405 1.72386 10.3905C1.97391 10.1404 2.31304 9.99996 2.66667 9.99996H4.66667C5.02029 9.99996 5.35943 10.1404 5.60948 10.3905C5.85952 10.6405 6 10.9797 6 11.3333V13.3333Z"
          fill={color}
        />
        <path
          d="M14.862 12.6993L13.3334 14.226V1.75531L14.862 3.28198C14.924 3.34388 14.9975 3.39297 15.0784 3.42645C15.1593 3.45993 15.246 3.47715 15.3336 3.47712C15.4212 3.47708 15.5079 3.45981 15.5888 3.42627C15.6697 3.39273 15.7431 3.34359 15.805 3.28165C15.8669 3.21971 15.916 3.14618 15.9495 3.06527C15.983 2.98436 16.0002 2.89764 16.0002 2.81008C16.0001 2.72251 15.9829 2.63581 15.9493 2.55492C15.9158 2.47403 15.8667 2.40054 15.8047 2.33865L14.08 0.616645C13.705 0.242111 13.1967 0.0317383 12.6667 0.0317383C12.1367 0.0317383 11.6284 0.242111 11.2534 0.616645L9.52872 2.33865C9.46678 2.40054 9.41764 2.47403 9.3841 2.55492C9.35056 2.63581 9.33328 2.72251 9.33325 2.81008C9.33322 2.89764 9.35044 2.98436 9.38392 3.06527C9.4174 3.14618 9.46649 3.21971 9.52839 3.28165C9.59028 3.34359 9.66377 3.39273 9.74466 3.42627C9.82555 3.45981 9.91225 3.47708 9.99982 3.47712C10.0874 3.47715 10.1741 3.45993 10.255 3.42645C10.3359 3.39297 10.4094 3.34388 10.4714 3.28198L12 1.75531V14.226L10.4714 12.6993C10.4094 12.6374 10.3359 12.5883 10.255 12.5549C10.1741 12.5214 10.0874 12.5042 9.99982 12.5042C9.91225 12.5042 9.82555 12.5215 9.74466 12.555C9.66377 12.5886 9.59028 12.6377 9.52839 12.6997C9.46649 12.7616 9.4174 12.8351 9.38392 12.916C9.35044 12.9969 9.33322 13.0837 9.33325 13.1712C9.33328 13.2588 9.35056 13.3455 9.3841 13.4264C9.41764 13.5073 9.46678 13.5808 9.52872 13.6427L11.2534 15.3647C11.6284 15.7392 12.1367 15.9496 12.6667 15.9496C13.1967 15.9496 13.705 15.7392 14.08 15.3647L15.8047 13.6427C15.8667 13.5808 15.9158 13.5073 15.9493 13.4264C15.9829 13.3455 16.0001 13.2588 16.0002 13.1712C16.0002 13.0837 15.983 12.9969 15.9495 12.916C15.916 12.8351 15.8669 12.7616 15.805 12.6997C15.7431 12.6377 15.6697 12.5886 15.5888 12.555C15.5079 12.5215 15.4212 12.5042 15.3336 12.5042C15.246 12.5042 15.1593 12.5214 15.0784 12.5549C14.9975 12.5883 14.924 12.6374 14.862 12.6993Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_414_10582">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
