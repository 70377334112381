import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CheckIcon: FC<ISvgProps> = ({
  height = 24,
  width = 24,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.75026H3.736C3.95064 5.53999 4.41917 6.23715 5.06933 6.73419C5.71948 7.23123 6.51512 7.50052 7.3335 7.50052C8.15188 7.50052 8.94752 7.23123 9.59767 6.73419C10.2478 6.23715 10.7164 5.53999 10.931 4.75026H23C23.2652 4.75026 23.5196 4.6449 23.7071 4.45737C23.8946 4.26983 24 4.01548 24 3.75026C24 3.48504 23.8946 3.23069 23.7071 3.04315C23.5196 2.85562 23.2652 2.75026 23 2.75026H10.931C10.7164 1.96053 10.2478 1.26336 9.59767 0.766328C8.94752 0.269291 8.15188 0 7.3335 0C6.51512 0 5.71948 0.269291 5.06933 0.766328C4.41917 1.26336 3.95064 1.96053 3.736 2.75026H1C0.734784 2.75026 0.48043 2.85562 0.292893 3.04315C0.105357 3.23069 0 3.48504 0 3.75026C0 4.01548 0.105357 4.26983 0.292893 4.45737C0.48043 4.6449 0.734784 4.75026 1 4.75026ZM7.333 2.00026C7.67912 2.00026 8.01746 2.10289 8.30525 2.29519C8.59303 2.48748 8.81734 2.76079 8.94979 3.08056C9.08224 3.40033 9.1169 3.7522 9.04937 4.09167C8.98185 4.43113 8.81518 4.74295 8.57044 4.9877C8.3257 5.23244 8.01388 5.39911 7.67441 5.46663C7.33494 5.53416 6.98307 5.4995 6.6633 5.36705C6.34353 5.23459 6.07022 5.01029 5.87793 4.72251C5.68564 4.43472 5.583 4.09638 5.583 3.75026C5.58353 3.28629 5.76807 2.84148 6.09615 2.51341C6.42422 2.18533 6.86903 2.00079 7.333 2.00026Z"
        fill={color}
      />
      <path
        d="M23 10.999H20.264C20.0497 10.2091 19.5814 9.5117 18.9313 9.01446C18.2812 8.51722 17.4855 8.2478 16.667 8.2478C15.8485 8.2478 15.0528 8.51722 14.4027 9.01446C13.7526 9.5117 13.2843 10.2091 13.07 10.999H1C0.734784 10.999 0.48043 11.1044 0.292893 11.2919C0.105357 11.4795 0 11.7338 0 11.999C0 12.2642 0.105357 12.5186 0.292893 12.7061C0.48043 12.8937 0.734784 12.999 1 12.999H13.07C13.2843 13.7889 13.7526 14.4864 14.4027 14.9836C15.0528 15.4808 15.8485 15.7503 16.667 15.7503C17.4855 15.7503 18.2812 15.4808 18.9313 14.9836C19.5814 14.4864 20.0497 13.7889 20.264 12.999H23C23.2652 12.999 23.5196 12.8937 23.7071 12.7061C23.8946 12.5186 24 12.2642 24 11.999C24 11.7338 23.8946 11.4795 23.7071 11.2919C23.5196 11.1044 23.2652 10.999 23 10.999ZM16.667 13.749C16.3209 13.749 15.9825 13.6464 15.6948 13.4541C15.407 13.2618 15.1827 12.9885 15.0502 12.6687C14.9178 12.349 14.8831 11.9971 14.9506 11.6576C15.0181 11.3182 15.1848 11.0063 15.4296 10.7616C15.6743 10.5169 15.9861 10.3502 16.3256 10.2827C16.6651 10.2151 17.0169 10.2498 17.3367 10.3822C17.6565 10.5147 17.9298 10.739 18.1221 11.0268C18.3144 11.3146 18.417 11.6529 18.417 11.999C18.4165 12.463 18.2319 12.9078 17.9039 13.2359C17.5758 13.564 17.131 13.7485 16.667 13.749Z"
        fill={color}
      />
      <path
        d="M23 19.2502H10.931C10.7164 18.4605 10.2478 17.7634 9.59767 17.2663C8.94752 16.7693 8.15188 16.5 7.3335 16.5C6.51512 16.5 5.71948 16.7693 5.06933 17.2663C4.41917 17.7634 3.95064 18.4605 3.736 19.2502H1C0.734784 19.2502 0.48043 19.3556 0.292893 19.5431C0.105357 19.7307 0 19.985 0 20.2502C0 20.5155 0.105357 20.7698 0.292893 20.9573C0.48043 21.1449 0.734784 21.2502 1 21.2502H3.736C3.95064 22.04 4.41917 22.7371 5.06933 23.2342C5.71948 23.7312 6.51512 24.0005 7.3335 24.0005C8.15188 24.0005 8.94752 23.7312 9.59767 23.2342C10.2478 22.7371 10.7164 22.04 10.931 21.2502H23C23.2652 21.2502 23.5196 21.1449 23.7071 20.9573C23.8946 20.7698 24 20.5155 24 20.2502C24 19.985 23.8946 19.7307 23.7071 19.5431C23.5196 19.3556 23.2652 19.2502 23 19.2502ZM7.333 22.0002C6.98688 22.0002 6.64854 21.8976 6.36075 21.7053C6.07297 21.513 5.84866 21.2397 5.71621 20.9199C5.58376 20.6002 5.5491 20.2483 5.61663 19.9088C5.68415 19.5694 5.85082 19.2576 6.09556 19.0128C6.3403 18.7681 6.65213 18.6014 6.99159 18.5339C7.33106 18.4664 7.68293 18.501 8.0027 18.6335C8.32247 18.7659 8.59578 18.9902 8.78807 19.278C8.98036 19.5658 9.083 19.9041 9.083 20.2502C9.08221 20.7141 8.89758 21.1588 8.56956 21.4868C8.24154 21.8148 7.79689 21.9994 7.333 22.0002Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
