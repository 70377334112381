import { IImage } from '../../types/media';

import PropertyCarousel from '../PropertyCarousel/PropertyCarousel';

interface IPropertyListCarouselProps {
  items: IImage[];
}

const PropertyListCarousel: React.FC<IPropertyListCarouselProps> = ({
  items,
}) => {
  return (
    <PropertyCarousel
      items={items}
      wrapperClass="aspect-[1.34]"
      imageClass="rounded-[15px]"
    />
  );
};

export default PropertyListCarousel;
