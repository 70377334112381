import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getChatRooms } from '../../../api/services/messages/requests';

import { TChatList } from '../types/chat';

import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

import useWebSocketChat from '../hooks/useWebsocketChat';

import ChatsList from './ChatsList';
import ChatRoom from './ChatRoom';

const Messages = () => {
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();

  const {
    rooms,
    setRooms,
    setRoomId,
    resetStore,
    refresh,
    setRefresh,
    openSupport,
    setOpenSupport,
    newChat,
    setNewChat,
  } = useWebsocketChatStore();

  const { connect, disconnect, sendMessage, handleMessageRead } =
    useWebSocketChat();

  useQuery(
    ['chatRooms', refresh],
    async () => {
      setRefresh(false);
      setLoader(true);
      const data: TChatList = await getChatRooms();
      setLoader(false);
      setRooms(data);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      },
      enabled: refresh,
    },
  );

  useEffect(() => {
    connect();

    return () => {
      disconnect();
      resetStore();
    };
  }, []);

  useEffect(() => {
    if (openSupport && rooms.length > 0) {
      setOpenSupport(false);
      const supports = rooms.filter((room) => room.isSupport);
      if (supports.length > 0) {
        setRoomId(supports[0].id);
      }
    }
  }, [openSupport, rooms]);

  useEffect(() => {
    if (newChat?.id && rooms.length > 0) {
      setNewChat(null);
      const isChatExists = rooms.find((room) => room.id === newChat.id);

      if (isChatExists) {
        setRoomId(newChat.id);
      } else {
        setRooms([newChat].concat(rooms));
        setRoomId(newChat.id);
      }
    }
  }, [newChat, rooms]);

  return (
    <div className="h-[80vh]">
      {rooms.length ? (
        <div className="grid grid-cols-[auto_1fr] gap-[25px] overflow-x-auto bg-transparent p-0 h-[80vh] overflow-hidden">
          <ChatsList />
          <ChatRoom
            sendMessage={sendMessage}
            handleMessageRead={handleMessageRead}
          />
        </div>
      ) : (
        !loader && (
          <div className="flex-center w-full h-full">{t('no_chat_rooms')}</div>
        )
      )}
    </div>
  );
};

export default Messages;
