import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../utils/helpers';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

import AccordionSimple from '../../Accordion/AccordionSimple';

import FiltersTypeSelector from '../FiltersTypeSelector';
import FiltersAddressInput from '../FiltersAddressInput';
import FilterControls from '../FilterControls';

import PriceHardcodedField from '../fields/PriceHardcodedField';
import AreaHardcodedField from '../fields/AreaHardcodedField';
import RadioField from '../fields/RadioField';
import TagSelectField from '../fields/TagSelectField';
import CounterField from '../fields/CounterField';
import NumberField from '../fields/NumberField';

import CrossIcon from '../../../assets/icons/CrossIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';

interface ITagOption {
  id: string;
  label: string;
}

interface IField {
  id: string;
  type: string;
  name: string;
  label: string;
  tags: ITagOption[];
  meta?: {
    isCounter?: boolean;
    unit?: string;
  };
  [key: string]: any;
}

interface IGroup {
  name: string;
  fields: IField[];
}

interface IFiltersModalProps {
  groups: IGroup[];
  onClose: () => void;
}

const FieldInput: React.FC<{ field: IField }> = ({ field }) => {
  switch (field.type) {
    case 'RADIO':
      return <RadioField field={field} />;
    case 'SELECT':
      return <RadioField field={field} />;
    case 'TAG_SELECT':
      return <TagSelectField field={field} />;
    case 'TAG_MULTI_SELECT':
      return <TagSelectField field={field} multi />;
    case 'NUMBER':
      return field.meta?.isCounter ? (
        <CounterField field={field} />
      ) : (
        <NumberField field={field} />
      );
    default:
      return null;
  }
};

const FiltersModal: React.FC<IFiltersModalProps> = ({ groups, onClose }) => {
  const { t } = useTranslation();

  const { byOwner, setByOwner } = usePropertyFiltersStore();

  const parametersSchema = groups.find((g) => g.name === 'parameters');
  const featuresSchema = groups.find((g) => g.name === 'features');
  const priceAndRentalTermsSchema = groups.find(
    (g) => g.name === 'priceAndRentalTerms',
  );

  const items = [
    {
      title: (
        <div className="text-[28px] md:text-[36px] leading-[38px] md:leading-[46px] text-heading">
          {t('parameters')}
        </div>
      ),
      content: (
        <div className="flex flex-col gap-[20px] md:gap-[25px] pt-[20px] md:pt-[25px]">
          {parametersSchema?.fields
            ?.filter((field) => !field.meta?.isCounter)
            ?.filter((field) => field.name !== 'totalArea')
            .sort((o1: any, o2: any) => o1.order - o2.order)
            .map((field) => <FieldInput key={field.id} field={field} />)}
          <div className="grid grid-cols-2 gap-x-[15px] md:gap-x-[20px] gap-y-[20px] md:gap-y-[25px]">
            {parametersSchema?.fields
              ?.filter((field) => field.meta?.isCounter)
              .sort((o1: any, o2: any) => o1.order - o2.order)
              .map((field) => <FieldInput key={field.id} field={field} />)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-[28px] md:text-[36px] leading-[38px] md:leading-[46px] text-heading">
          {t('features')}
        </div>
      ),
      content: (
        <div className="flex flex-col gap-[20px] md:gap-[25px] pt-[20px] md:pt-[25px]">
          {featuresSchema?.fields
            ?.filter((field) => !field.meta?.isCounter)
            .map((field) => <FieldInput key={field.id} field={field} />)}
          <div className="grid grid-cols-2 gap-x-[15px] md:gap-x-[20px] gap-y-[20px] md:gap-y-[25px]">
            {featuresSchema?.fields
              ?.filter((field) => field.meta?.isCounter)
              .map((field) => <FieldInput key={field.id} field={field} />)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="text-[28px] md:text-[36px] leading-[38px] md:leading-[46px] text-heading">
          {t('price_and_rental_terms')}
        </div>
      ),
      content: (
        <div className="flex flex-col gap-[20px] md:gap-[25px] pt-[20px] md:pt-[25px]">
          {priceAndRentalTermsSchema?.fields
            ?.filter((field) => !field.meta?.isCounter)
            .map((field) => <FieldInput key={field.id} field={field} />)}
          <div className="grid grid-cols-2 gap-x-[15px] md:gap-x-[20px] gap-y-[20px] md:gap-y-[25px]">
            {priceAndRentalTermsSchema?.fields
              ?.filter((field) => field.meta?.isCounter)
              .map((field) => <FieldInput key={field.id} field={field} />)}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div
      className={`z-[1000] fixed inset-0 md:relative md:max-w-[90vw] md:max-h-[100vh] w-screen h-screen bg-white py-[25px] px-[25px] md:px-[50px] rounded-none md:rounded-[25px] overflow-hidden border-0 shadow`}
    >
      <div className="flex flex-col justify-between h-full gap-[15px]">
        <button onClick={onClose} className="flex justify-end">
          <CrossIcon width={18} height={18} color="#D4D4D4" strokeWidth={2} />
        </button>
        <div className="overflow-auto flex-1 pr-2 flex flex-col gap-[25px] md:gap-[35px]">
          <FiltersTypeSelector />

          <div className="flex flex-col gap-[10px] w-full">
            <h5 className="text-heading-5 text-heading">
              {t('additional_options')}
            </h5>

            {/* Контейнер для чекбоксов: flex-wrap, чтобы переносились,
        gap-2 (или другой размер) задаёт отступы по горизонтали/вертикали */}
            <div className="flex flex-wrap gap-2">
              {/* Пример одного чекбокса */}
              <label className="flex items-center h-[25px] gap-[7px] fade-in animation-fast">
                <div className="relative flex-center w-[24px] h-[24px]">
                  <input
                    type="checkbox"
                    className="appearance-none w-[24px] h-[24px] border border-[#006CFB] rounded-[6px] bg-transparent shrink-0 cursor-pointer checked:bg-[#006CFB]"
                    checked={byOwner}
                    onChange={() => setByOwner(!byOwner)}
                  />
                  <CheckIcon
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                    height={10}
                    width={15}
                    color="white"
                    strokeWidth={1}
                  />
                </div>
                <div>{capitalizeFirstLetter(t('by_owner_label'))}</div>
              </label>

              {/* Если появятся другие чекбоксы,
          оборачивайте их таким же label внутри этого flex-контейнера */}
            </div>
          </div>
          <FiltersAddressInput />
          <PriceHardcodedField />
          <AreaHardcodedField />
          <AccordionSimple items={items} />
        </div>
        <div className="mt-auto">
          <FilterControls onSubmit={onClose} />
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
