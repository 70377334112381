import { create } from 'zustand';

interface ICoordinates {
  lat: number;
  lng: number;
  radius?: number;
}

interface IAddress {
  formattedAddress: string;
  city?: string;
}

interface IPropertyTypes {
  path: string[];
  entities: any[];
}

interface IDefaultValues {
  filters: any;
  filtersTypes: IPropertyTypes;
  byOwner: boolean;
  address: IAddress;
  osmId: string[];
  coordinates: ICoordinates;
}

const defaultValues: IDefaultValues = {
  filters: {} as any,
  filtersTypes: { path: [], entities: [] },
  byOwner: false,
  address: { formattedAddress: '' },
  osmId: [],
  coordinates: {
    lat: 50.0755,
    lng: 14.4378,
  },
};

const usePropertyFiltersStore = create<any>((set) => ({
  ...defaultValues,
  setFilters: (newFilters: any) =>
    set((prevFilters: any) => {
      const updatedFilters = {
        ...prevFilters.filters,
        ...newFilters,
      };

      Object.keys(updatedFilters).forEach((key) => {
        const value = updatedFilters[key];
        if (
          value === '' ||
          (Array.isArray(value) && value.length === 0) ||
          value === undefined
        ) {
          delete updatedFilters[key];
        }
      });

      return { filters: updatedFilters };
    }),
  setFiltersTypes: (filtersTypes: IPropertyTypes) => set({ filtersTypes }),
  setByOwner: (byOwner: boolean) => set({ byOwner }),
  setAddress: (address: IAddress) => set({ address }),
  setOsmId: (osmId: string[]) => set({ osmId }),
  setCoordinates: (coordinates: ICoordinates) => set({ coordinates }),
  // setFilters: (newFilters) => set((prevFilters) => ({
  //   filters: {
  //     ...prevFilters.filters,
  //     ...newFilters,
  //   },
  // })),
  reset: () => set(defaultValues),
}));

export default usePropertyFiltersStore;
