import { FC } from 'react';

import { ISvgProps } from '../../../types/media';

const ParametersIcon: FC<ISvgProps> = ({
  height = 18,
  width = 20,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 10.25H20V17.75H0V0.25H17.5V5.25H18.75V10.25ZM1.25 16.5H6.25V5.25H16.25V1.5H1.25V16.5ZM7.5 16.5H12.5V10.25H17.5V6.5H7.5V16.5ZM18.75 16.5V11.5H13.75V16.5H18.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ParametersIcon;
