import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { changeEmailFormSchema } from '../validations/schema';

import { postOtpEmailConfirmSend } from '../../../api/services/otp/requests';

import { IConfirmEmail } from '../types/form';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import useConfirmEmailStore from '../stores/useConfirmEmailStore';

const useFormConfirmEmail = (openOTPModal: () => void) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { setEmail } = useConfirmEmailStore();

  const defaultValues = {
    email: '',
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<IConfirmEmail>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver(changeEmailFormSchema),
  });

  const onSubmit = async (value: IConfirmEmail) => {
    try {
      setLoader(true);
      setEmail(value.email);
      await postOtpEmailConfirmSend({ email: value.email });
      openOTPModal();
    } catch (error: any) {
      setError(error?.response?.data?.message || '');
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
};

export default useFormConfirmEmail;
