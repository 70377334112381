import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { postOtpPasswordResetCheck } from '../../../api/services/otp/requests';

import { forgotPasswordPinFormSchema } from '../validations/schema';

import { IForgotPasswordPin } from '../types/form';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import useForgotPasswordStore from '../stores/useForgotPasswordStore';

import ForgotPasswordChangeModal from '../components/modals/ForgotPasswordChangeModal';

const useFormResetPasswordPin = () => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setChildren } = useGlobalModalStore();

  const { email, setPin, setPinError } = useForgotPasswordStore();

  const { control, handleSubmit, watch } = useForm<IForgotPasswordPin>({
    mode: 'onTouched',
    defaultValues: {
      pin: '',
    },
    resolver: zodResolver(forgotPasswordPinFormSchema),
  });

  const pin = watch('pin');

  const onSubmit = async ({ pin }: IForgotPasswordPin) => {
    try {
      setLoader(true);
      const { data } = await postOtpPasswordResetCheck({
        otp: pin,
        email,
      });

      if (data.valid) {
        setPin(pin);
        setChildren(React.createElement(ForgotPasswordChangeModal));
      } else {
        setPinError(true);
        setError(t('pin_is_not_valid'));
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);

  return {
    control,
    pin,
  };
};

export default useFormResetPasswordPin;
