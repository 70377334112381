import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EUserRole } from '../../../common/types/dto/user';
import { IChat, IChatParticipant } from '../types/chat';

import useAuthStore from '../../../common/stores/useAuthStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

import { InputUncontrolled } from '../../../common/components/Input/Input';

import ChatListButton from './ChatListButton';

import MagnifyingGlass from '../../../common/assets/icons/MagnifyingGlass';

const ChatsList: React.FC = () => {
  const [searchFilter, setSearchFilter] = useState('');

  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { roomId, setRoomId, rooms } = useWebsocketChatStore();

  const chatListData = rooms.map((chat: IChat) => {
    const {
      id,
      unreadMessages,
      property,
      participants,
      isSupport,
      lastMessage,
    } = chat;
    const opponent = participants.find(
      (participant) => participant.id !== user?.id,
    ) as IChatParticipant;
    const name =
      opponent?.role === EUserRole.Admin
        ? t('chat_support_name')
        : opponent?.name;
    const avatar = opponent?.avatar;

    return {
      id,
      name,
      avatar,
      address: property?.address,
      unreadMessages,
      isSupport,
      lastMessage,
    };
  });

  const buttonsMap = useMemo(
    () =>
      chatListData
        .filter(({ name }) =>
          name?.toLowerCase().includes(searchFilter.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.isSupport !== b.isSupport) {
            return a.isSupport ? -1 : 1;
          }

          return (
            new Date(b.lastMessage?.createdAt || 0).getTime() -
            new Date(a.lastMessage?.createdAt || 0).getTime()
          );
        })
        .map(({ name, avatar, unreadMessages, lastMessage, id, isSupport }) => (
          <ChatListButton
            key={id}
            onClick={() => setRoomId(id)}
            name={name}
            avatar={avatar}
            isActive={id === roomId}
            unreadMessages={unreadMessages}
            lastMessage={lastMessage}
            isSupport={isSupport}
          />
        )),
    [chatListData, searchFilter, roomId],
  );

  return (
    <div className="w-[307px] bg-white p-0 h-[80vh] overflow-hidden">
      <div className="relative">
        <InputUncontrolled
          type="text"
          className="flex items-center w-full h-[50px] pl-[50px] pr-[10px] mb-[25px]"
          placeholder={t('search_placeholder')}
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" />
      </div>
      <div className="flex flex-col gap-[24px] h-[calc(80vh-75px)] overflow-y-auto">
        {buttonsMap}
      </div>
    </div>
  );
};

export default ChatsList;
