import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ArrowBackIcon: FC<ISvgProps> = ({
  height = 16,
  width = 16,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4128 0.587602C15.1738 0.345687 14.8765 0.169506 14.5495 0.07608C14.2225 -0.0173459 13.8769 -0.0248582 13.5462 0.054269L2.8795 2.30094C2.26522 2.38518 1.68666 2.63925 1.20897 3.03454C0.731284 3.42983 0.373445 3.95064 0.175753 4.53831C-0.0219392 5.12598 -0.0516323 5.75718 0.0900176 6.36081C0.231668 6.96445 0.539034 7.51655 0.977503 7.95494L2.12284 9.0996C2.18483 9.16157 2.23399 9.23516 2.2675 9.31615C2.30102 9.39714 2.31824 9.48395 2.31817 9.5716V11.6836C2.31964 11.9806 2.388 12.2734 2.51817 12.5403L2.51284 12.5449L2.53017 12.5623C2.72551 12.955 3.04456 13.2727 3.43817 13.4663L3.4555 13.4836L3.46017 13.4783C3.72708 13.6084 4.01988 13.6768 4.31684 13.6783H6.42884C6.60554 13.6781 6.77507 13.7481 6.90017 13.8729L8.04484 15.0176C8.35185 15.328 8.71727 15.5746 9.12002 15.7431C9.52278 15.9117 9.95491 15.9988 10.3915 15.9996C10.7553 15.9992 11.1167 15.9397 11.4615 15.8236C12.0438 15.6324 12.5611 15.2823 12.955 14.8127C13.3489 14.3432 13.6038 13.7729 13.6908 13.1663L15.9408 2.47627C16.0241 2.1427 16.0192 1.79322 15.9266 1.46211C15.834 1.13101 15.657 0.829649 15.4128 0.587602ZM3.06684 8.15827L1.92084 7.0136C1.65398 6.75318 1.46695 6.42203 1.38169 6.05904C1.29644 5.69605 1.31649 5.31626 1.4395 4.96427C1.55877 4.60315 1.77924 4.28389 2.0747 4.04444C2.37016 3.805 2.72818 3.65546 3.10617 3.6136L13.6668 1.39027L3.65017 11.4083V9.5716C3.65118 9.30916 3.60014 9.04912 3.50002 8.80653C3.39989 8.56393 3.25266 8.3436 3.06684 8.15827ZM12.3808 12.9383C12.3296 13.3065 12.1768 13.6532 11.9396 13.9394C11.7023 14.2256 11.39 14.44 11.0376 14.5585C10.6852 14.677 10.3068 14.695 9.94479 14.6104C9.58278 14.5258 9.25151 14.342 8.98817 14.0796L7.8415 12.9329C7.65642 12.7468 7.43626 12.5993 7.19378 12.4988C6.9513 12.3983 6.69131 12.3469 6.42884 12.3476H4.59217L14.6102 2.33294L12.3808 12.9383Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBackIcon;
