import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EUserRole } from '../../../common/types/dto/user';
import { IMessage } from '../types/chat';

import useAuthStore from '../../../common/stores/useAuthStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

import ChatMessage from './ChatMessage';

function groupMessagesByDate(messages: IMessage[]): Record<string, IMessage[]> {
  return messages.reduce(
    (acc: Record<string, IMessage[]>, message: IMessage) => {
      const date = new Date(message.createdAt);
      const formattedDate = date.toISOString().split('T')[0];

      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }

      acc[formattedDate].push(message);
      return acc;
    },
    {},
  );
}

function formatDateForDisplay(dateString: string): string {
  const [year, month, day] = dateString.split('-').map(Number);
  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
}

interface MessagesListProps {
  handleMessageRead: (messageId: string) => void;
}

const MessagesList: React.FC<MessagesListProps> = ({ handleMessageRead }) => {
  const { t } = useTranslation();

  const { user } = useAuthStore();

  const { messages } = useWebsocketChatStore();

  const today = new Date().toISOString().split('T')[0];
  const messagesMap = useMemo(() => {
    if (!messages.length) return [];

    const groupedMessages = groupMessagesByDate(messages);
    const sortedDays = Object.entries(groupedMessages).sort(
      ([dateA], [dateB]) => {
        const dateAObj = new Date(dateA);
        const dateBObj = new Date(dateB);

        if (isNaN(dateAObj.getTime()) || isNaN(dateBObj.getTime())) {
          console.warn(
            `Invalid date: ${dateA} (${dateAObj.getTime()}), ${dateB} (${dateBObj.getTime()})`,
          );
        }

        return dateAObj.getTime() - dateBObj.getTime();
      },
    );

    return sortedDays.map(([date, msgs]) => {
      const sortedMessages = msgs.sort(
        (msgA, msgB) =>
          new Date(msgA.createdAt).getTime() -
          new Date(msgB.createdAt).getTime(),
      );
      return (
        <React.Fragment key={date}>
          <div className="text-[12px] text-[#9997A8] font-semibold text-center">
            {date === today ? t('today') : formatDateForDisplay(date)}
          </div>
          {sortedMessages.map((message) => {
            const { id, sender, body, createdAt, read } = message;
            const isOwner = sender?.id === user?.id;
            const name =
              !isOwner && sender?.role === EUserRole.Admin
                ? t('chat_support_name')
                : sender?.name;
            const markRead = () => {
              handleMessageRead(id);
            };

            return (
              <ChatMessage
                key={id}
                avatar={sender?.avatar}
                name={name}
                body={body}
                createdAt={createdAt}
                read={read}
                isOwner={isOwner}
                markRead={markRead}
              />
            );
          })}
        </React.Fragment>
      );
    });
  }, [user, messages]);

  return <div className="grid gap-[15px]">{messagesMap}</div>;
};

export default MessagesList;
