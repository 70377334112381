import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addPriceDelimiters,
  generatePropertyDataObject,
  getAddressString,
} from '../../utils/helpers';

import { EPriceType } from '../../../api/services/property/types';
import { IPropertySearch } from '../../types/dto/property';
import { IImage } from '../../types/media';

import PropertyTileCarousel from './PropertyTileCarousel';
import FavoriteButton from '../FavoriteButton/FavoriteButton';

import NoImageIcon from '../../assets/icons/NoImageIcon';
import LocationPin from '../../assets/icons/LocationPin';

import { ReactComponent as RoomsIcon } from '../../assets/icons/roomsIcon.svg';
import { ReactComponent as AreaIcon } from '../../assets/icons/areaIcon.svg';
import { ReactComponent as FloorsIcon } from '../../assets/icons/floorsIcon.svg';

interface IPropertyTileProps {
  item: IPropertySearch;
  refetch?: () => void;
  isLoggedIn?: boolean;
}

const PropertyTile: React.FC<IPropertyTileProps> = ({
  item: {
    id,
    mainImage,
    gallery = [],
    price,
    address,
    fields,
    category,
    isFavorite,
    priceType,
  },
  refetch,
  isLoggedIn = false,
}) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  const addressString = getAddressString(address);

  const dataObject = generatePropertyDataObject(category, fields);

  const allImages: IImage[] = useMemo(() => {
    const collateralImages = gallery;

    return mainImage ? [...[mainImage], ...collateralImages] : collateralImages;
  }, [gallery, mainImage]);

  return (
    <Link
      className="relative group flex flex-col gap-[10px] rounded-[15px] cursor-pointer fade-in"
      to={`/${lang}/property/${id}`}
    >
      {allImages && allImages.length > 0 ? (
        <PropertyTileCarousel items={allImages} />
      ) : (
        <div className="flex-center aspect-[1.34] bg-[#F8F8F8] rounded-[15px]">
          <NoImageIcon width={40} height={40} />
        </div>
      )}
      {isLoggedIn && (
        <FavoriteButton
          id={id}
          className="top-[9px] right-[5px]"
          isFavorite={isFavorite}
          refetch={refetch}
        />
      )}
      <div className="flex-1 flex flex-col gap-[10px]">
        <div className="flex flex-wrap items-center gap-[15px]">
          {dataObject.rooms && (
            <div className="flex items-center gap-[5px]">
              <div className="flex-center w-[14px] h-[14px]">
                <RoomsIcon width={14} height={14} />
              </div>
              <div className="text-[12px] leading-[16.4px]">
                {dataObject.rooms}
              </div>
            </div>
          )}
          {dataObject.area && (
            <div className="flex items-center gap-[5px]">
              <div className="flex-center w-[14px] h-[14px]">
                <AreaIcon width={14} height={14} />
              </div>
              <div className="text-[12px] leading-[16.4px]">
                {dataObject.area}
              </div>
            </div>
          )}
          {dataObject.floors && (
            <div className="flex items-center gap-[5px]">
              <div className="flex-center w-[14px] h-[14px]">
                <FloorsIcon width={14} height={14} />
              </div>
              <div className="text-[12px] leading-[16.4px]">
                {dataObject.floors}
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-[5px]">
          <LocationPin width={14} height={14} />
          <div className="text-body-smaller">{addressString}</div>
        </div>

        <h6 className="text-heading-6 text-primary">
          {addPriceDelimiters(price)} Kč
          {priceType === EPriceType.PerMonth
            ? `/${t('month')}`
            : priceType === EPriceType.PerDay
              ? `/${t('day')}`
              : ''}
        </h6>
      </div>
    </Link>
  );
};

export default PropertyTile;
