import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const SearchIcon: FC<ISvgProps> = ({
  height = 14,
  width = 13,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_269_1831)">
        <path
          d="M5.89807 11.3269C8.55722 11.3269 10.7129 9.00543 10.7129 6.14173C10.7129 3.27803 8.55722 0.956543 5.89807 0.956543C3.23892 0.956543 1.08325 3.27803 1.08325 6.14173C1.08325 9.00543 3.23892 11.3269 5.89807 11.3269Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9166 12.6232L9.29858 9.80371"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_269_1831">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
