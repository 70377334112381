import React from 'react';
import { useTranslation } from 'react-i18next';

import { getAddressString } from '../../../common/utils/helpers';
import { addPriceDelimiters } from '../../../common/utils/helpers';

import { IProperty } from '../../../common/types/dto/property';

import ImageWithFallback from '../../../common/components/Image/ImageWithFallback';

import LocationPin from '../../../common/assets/icons/LocationPin';
import NoImageIcon from '../../../common/assets/icons/NoImageIcon';

interface ChatPropertyProps {
  currentRoomProperty: IProperty;
}

const ChatProperty: React.FC<ChatPropertyProps> = ({ currentRoomProperty }) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex items-center justify-end gap-[10px]">
      {currentRoomProperty?.mainImage?.url ? (
        <div>
          <ImageWithFallback
            className="object-cover w-[64px] h-[64px] rounded-[12px]"
            src={currentRoomProperty.mainImage.url}
            alt="property photo"
            width={64}
            height={64}
            withSkeleton
          />
        </div>
      ) : (
        <div className="flex-center w-[64px] h-[64px] bg-[#f8f8f8] rounded-[12px]">
          <NoImageIcon width={20} height={20} />
        </div>
      )}
      <div className="flex flex-col justify-between gap-1">
        {/*<div>...</div>*/}
        <div className="flex items-center gap-[5px]">
          <div className="w-[14px]">
            <LocationPin width={14} height={14} />
          </div>
          <div className="truncate text-body-smaller">
            {(currentRoomProperty?.address &&
              getAddressString(currentRoomProperty?.address)) ||
              t('unknown')}
          </div>
        </div>
        <div className="whitespace-nowrap text-[14px] font-semibold">
          {currentRoomProperty?.price
            ? `Kč ${addPriceDelimiters(currentRoomProperty.price)}`
            : 0}
          <span className="text-[8px]">
            {currentRoomProperty?.priceType === 'perMonth'
              ? `/${t('mo')}`
              : currentRoomProperty?.priceType === 'perDay'
                ? `/${t('day')}`
                : ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatProperty;
