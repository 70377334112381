import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalTemplate from '../../../../common/components/Modal/ModalTemplate';
import Input from '../../../../common/components/Input/Input';
import Button from '../../../../common/components/Button/Button';

import useFormForgotPasswordChange from '../../hooks/useFormForgotPasswordChange';

import PasswordStrength from '../PasswordStrength';

import { ReactComponent as LockIcon } from '../../../../common/assets/icons/lockIcon.svg';

const ModalBody = () => {
  const [validPassword, setValidPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const { t } = useTranslation();

  const { control, isValid, password, handleSubmit } =
    useFormForgotPasswordChange();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-[30px] fade-in">
        <Input
          type="password"
          label={t('new_password_label')}
          name="password"
          control={control}
          placeholder={t('new_password_placeholder')}
          onFocus={() => setIsPasswordFocused(true)}
          onBlur={() => setIsPasswordFocused(false)}
        />
        {isPasswordFocused && (
          <PasswordStrength
            password={password}
            setValidPassword={setValidPassword}
            className="scale-fade-in"
          />
        )}
        <Input
          type="password"
          label={t('confirm_password_label')}
          name="confirmPassword"
          control={control}
          placeholder={t('confirm_password_placeholder')}
        />
        <div>
          <Button
            className="w-full"
            withCooldown
            type="submit"
            disabled={!isValid || !validPassword}
          >
            {t('change_password')}
          </Button>
        </div>
      </div>
    </form>
  );
};

const ForgotPasswordChangeModal = () => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<LockIcon />}
      title={t('change_password')}
      description={t('change_password_description')}
      body={<ModalBody />}
    />
  );
};

export default ForgotPasswordChangeModal;
