import React from 'react';
import { Controller } from 'react-hook-form';

const Switch = ({
  control,
  name,
  onChange,
}: {
  control: any;
  name: string;
  onChange: any;
}) => {
  const handleFieldChange = async (field: any, value: any) => {
    field.onChange(value);
    !!onChange && onChange();
  };

  return (
    <label className="relative inline-block w-[56px] h-[30px] cursor-pointer">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            type="checkbox"
            checked={field.value}
            onChange={(e) => handleFieldChange(field, e.target.checked)}
            className="sr-only peer"
          />
        )}
      />
      <span className="block w-full h-full bg-secondary rounded-[25px] peer-checked:bg-primary transition-colors"></span>
      <span className="absolute left-[2px] top-[2px] w-[26px] h-[26px] bg-white rounded-full transition-transform transform peer-checked:translate-x-[26px] shadow-1"></span>
    </label>
  );
};

export default Switch;
