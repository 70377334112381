import { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';
// import useAuthStore from '../../stores/useAuthStore';
import useGlobalModalStore from '../../stores/useGlobalModalStore';

import usePropertyFiltersStore from './stores/usePropertyFiltersStore';

import Button from '../Button/Button';
import usePropertySearchSave from '../../hooks/usePropertySearchSave';

interface IFilters {
  FIELD_totalAreaFrom?: string;
  FIELD_totalAreaTo?: string;
  priceFrom?: string;
  priceTo?: string;
  FIELD_roomsCountFlat?: string[];
  FIELD_roomsCountHouse?: string[];
  [key: string]: any;
}

interface IFilterControlsProps {
  onSubmit?: () => void;
}

export function countUniqueKeys(obj: IFilters) {
  const uniqueKeys = new Set();

  Object.keys(obj).forEach((key) => {
    const baseKey = key.replace(/(From|To)$/, '');
    uniqueKeys.add(baseKey);
  });

  return uniqueKeys.size;
}

const FilterControls: FC<IFilterControlsProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();
  const {
    area,
    setArea,
    price,
    setPrice,
    rooms,
    setRooms,
    bedRooms,
    setBedRooms,
    propertyTypes,
    setPropertyTypes,
    osmId: searchOsmId,
    address: searchAddress,
    coordinates: searchCoordinates,
    setByOwner: setSearchByOwner,
    setFilters: setSearchFilters,
    setAddress: setSearchAddress,
    setOsmId: setSearchOsmId,
    setCoordinates: setSearchCoordinates,
  } = usePropertySearchStore();

  const {
    filters,
    filtersTypes,
    byOwner,
    address,
    osmId,
    coordinates,
    setFilters,
    setFiltersTypes,
    setAddress,
    setOsmId,
    setCoordinates,
    reset,
  } = usePropertyFiltersStore();

  const { handleSaveSearch } = usePropertySearchSave();

  const filtersCount =
    countUniqueKeys(filters) + (byOwner ? 1 : 0) + (osmId.length ? 1 : 0);

  const handleApply = () => {
    const {
      FIELD_totalAreaFrom,
      FIELD_totalAreaTo,
      priceFrom,
      priceTo,
      FIELD_roomsCountFlat,
      FIELD_roomsCountHouse,
      ...filteredFilters
    } = filters;

    setPrice({
      from: priceFrom || '',
      to: priceTo || '',
    });

    setArea({
      from: FIELD_totalAreaFrom || '',
      to: FIELD_totalAreaTo || '',
    });

    setRooms(FIELD_roomsCountFlat || []);

    setBedRooms(FIELD_roomsCountHouse || []);

    setSearchFilters(filteredFilters);

    setPropertyTypes(filtersTypes);

    setSearchByOwner(byOwner);

    setSearchAddress(address);
    setSearchOsmId(osmId);
    setSearchCoordinates(coordinates);

    !!onSubmit && onSubmit();
  };

  const handleSaveSearchClose = () => {
    handleApply();
    onClose();
    handleSaveSearch();
  };

  const handleReset = () => {
    reset();

    setPrice({
      from: '',
      to: '',
    });

    setArea({
      from: '',
      to: '',
    });

    setRooms([]);

    setBedRooms([]);

    setFiltersTypes(propertyTypes);

    setSearchByOwner(false);

    setSearchAddress({ formattedAddress: '' });
    setSearchOsmId([]);
    setSearchCoordinates({
      lat: 50.0755,
      lng: 14.4378,
    });

    !!onSubmit && onSubmit();
  };

  useEffect(() => {
    if (
      area.to !== filters.FIELD_totalAreaTo ||
      area.from !== filters.FIELD_totalAreaFrom
    ) {
      setFilters({
        FIELD_totalAreaFrom: area.from,
        FIELD_totalAreaTo: area.to,
      });
    }
  }, [area]);

  useEffect(() => {
    if (price.to !== filters.priceTo || price.from !== filters.priceFrom) {
      setFilters({
        priceFrom: price.from,
        priceTo: price.to,
      });
    }
  }, [price]);

  useEffect(() => {
    if (rooms.length) {
      setFilters({
        FIELD_roomsCountFlat: rooms,
      });
    }

    if (bedRooms.length) {
      setFilters({
        FIELD_roomsCountHouse: bedRooms,
      });
    }
  }, [rooms.length, bedRooms.length]);

  useEffect(() => {
    if (osmId !== searchOsmId) {
      setOsmId(searchOsmId);
      setAddress(searchAddress);
      setCoordinates(searchCoordinates);
    }
  }, [searchOsmId]);

  return (
    <div className="w-full">
      {/*
        -- БЛОК ДЛЯ МОБИЛЬНЫХ (до sm):
        показываем reset и apply на одной строке (слева/справа),
        а при желании можно скрыть saveSearch
      */}
      {/* Блок для мобильных */}
      <div
        className="flex sm:hidden items-center justify-between mb-0"
        style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} // Добавляем отступ в Safari
      >
        <button
          onClick={handleReset}
          className="text-[16px] text-[#006CFB] font-semibold"
        >
          {t('reset_all')}
        </button>
        <Button onClick={handleApply} className="w-auto">
          {t('apply')} {filtersCount > 0 && <span>({filtersCount})</span>}
        </Button>
      </div>

      {/*
        -- БЛОК ДЛЯ ДЕСКТОПА (от sm):
        как было изначально: save_search слева, reset + apply справа
      */}
      <div className="hidden sm:flex items-center justify-between">
        <div>
          <button
            onClick={handleSaveSearchClose}
            className="text-[16px] text-accent font-semibold"
          >
            {t('save_search')}
          </button>
        </div>
        <div className="flex items-center gap-5">
          <button
            onClick={handleReset}
            className="text-[16px] text-[#006CFB] font-semibold"
          >
            {t('reset_all')}
          </button>
          <Button onClick={handleApply} className="w-[336px]">
            {t('apply')} (<span>{filtersCount}</span>)
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterControls;
