import React from 'react';
import ReactPhoneInput from 'react-phone-number-input/input';
import { E164Number } from 'libphonenumber-js';
import { Controller, ControllerProps } from 'react-hook-form';

interface IPhoneInputProps<TFormData extends Record<string, any>>
  extends Pick<ControllerProps<TFormData>, 'control' | 'name'> {
  onChange?: (value?: E164Number) => void;
  label?: string;
  placeholder?: string;
  // defaultCountry?: 'CZ';
  disabled?: boolean;
  required?: boolean;
}

function PhoneInput<TFormData extends Record<string, any>>({
  name,
  control,
  onChange,
  label,
  placeholder,
  // defaultCountry,
  disabled,
  required,
  ...props
}: IPhoneInputProps<TFormData>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="relative flex flex-col phone-input-wrapper">
          {label && (
            <label
              htmlFor={name}
              className="text-body-small text-heading mb-[10px]"
            >
              {label}
              {!!required && <span className="text-red-400">*</span>}
            </label>
          )}
          <ReactPhoneInput
            {...props}
            disabled={disabled}
            // defaultCountry={defaultCountry}
            useNationalFormatForDefaultCountryValue={false}
            placeholder={placeholder}
            value={field.value}
            withCountryCallingCode
            onChange={(value: any) => {
              field.onChange(value || '');
              onChange?.(value);
            }}
          />
        </div>
      )}
    />
  );
}

export default PhoneInput;
