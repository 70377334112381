import React, { useEffect } from 'react';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import useSelectedCategorySchema from '../../hooks/useSelectedCategorySchema';

import PropertyTypeSelector from './PropertyTypeSelector';
import PropertyFilters from './PropertyFilters';

const PropertySearchBar: React.FC = () => {
  const { propertyTypes, setRooms, setBedRooms } = usePropertySearchStore();

  const { schema, roomsOptions, bedRoomsOptions } = useSelectedCategorySchema();

  const entityName = propertyTypes.entities[0]?.name || '';

  const showRooms = roomsOptions.length > 0 && entityName.includes('apartment');

  const showBedrooms =
    bedRoomsOptions.length > 0 && entityName.includes('house');

  useEffect(() => {
    if (!showRooms) {
      setRooms([]);
    }
  }, [showRooms]);

  useEffect(() => {
    if (!showBedrooms) {
      setBedRooms([]);
    }
  }, [showBedrooms]);

  return (
    <div className="flex flex-1 items-center justify-between gap-[50px] h-[88px] bg-white border border-primary rounded-[15px] px-[25px]">
      <div className="flex items-center gap-[24px] w-full">
        <PropertyTypeSelector withTopType />
      </div>
      <div>
        <PropertyFilters groups={schema.groups} />
      </div>
    </div>
  );
};

export default PropertySearchBar;
