import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter, getSubTree } from '../../utils/helpers';

import usePropertyFiltersStore from './stores/usePropertyFiltersStore';
import usePropertyCategories, {
  IConvertedCategory,
} from '../../hooks/usePropertyCategories';

import CheckIcon from '../../assets/icons/CheckIcon';

const FiltersTypeSelector = () => {
  const { type } = useParams();
  const { t } = useTranslation();

  const { filtersTypes, setFiltersTypes } = usePropertyFiltersStore();
  const { categories, flatCategories } = usePropertyCategories();

  const handleRadioChange = useCallback(
    (level: number, selectedValue: string, path = [...filtersTypes.path]) => {
      path[level] = selectedValue;
      path.splice(level + 1);

      const currentSubTree = getSubTree(categories, path);
      const subTreeChildren = currentSubTree?.children;
      if (subTreeChildren?.length) {
        const firstChildKey = subTreeChildren[0].name;
        if (firstChildKey) {
          path.push(firstChildKey);
          handleRadioChange(level + 1, firstChildKey, path);
        }
      }

      const newSubTree = getSubTree(categories, path);
      const finalEntities = newSubTree?.entities || [];
      const newValue = {
        path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      setFiltersTypes(newValue);
    },
    [filtersTypes, categories, setFiltersTypes],
  );

  const handleCheckboxChange = useCallback(
    (entity: any) => {
      if (
        filtersTypes.entities.find((ent: any) => ent.id === entity.id) &&
        filtersTypes.entities.length === 1
      )
        return;

      const pathString = filtersTypes.path.join('.');
      const isRightPath = entity?.name?.includes(pathString);
      const updatedPath = !isRightPath && entity?.name.split('.').slice(0, -1);
      const updatedEntities = [entity];
      const updatedValue = {
        ...filtersTypes,
        ...(updatedPath && {
          path: updatedPath,
        }),
        entities: updatedEntities,
      };

      setFiltersTypes(updatedValue);
    },
    [filtersTypes, setFiltersTypes],
  );

  const forceHardcodedPath = (code: string) => {
    const path =
      code === 'rent'
        ? ['rent', 'longTerm', 'residental']
        : ['sale', 'residental'];

    const currentSubTree = getSubTree(categories, path);
    const finalEntities = currentSubTree?.entities || [];
    const neededEntity = finalEntities.find((e: any) =>
      code === 'rent'
        ? e.name === 'rent.longTerm.residental.apartment'
        : e.name === 'sale.residental.apartment',
    );

    const updatedValue = {
      path,
      entities: neededEntity ? [neededEntity] : [],
    };

    if (JSON.stringify(filtersTypes.path) !== JSON.stringify(path)) {
      setFiltersTypes(updatedValue);
    }
  };

  // --- РЕНДЕР чекбоксов (entities) с адаптивной версткой ---
  const renderEntities = (entities: IConvertedCategory[]) => (
    <div
      className="
        flex
        flex-wrap             /* Разрешаем перенос на новую строку */
        gap-2 sm:gap-[25px]  /* Меньше отступ на мобильных, больше на больших экранах */
        w-full
      "
    >
      {entities?.map((entity) => (
        <label
          key={entity.id}
          className="flex items-center gap-[7px] h-[24px] fade-in animation-fast"
        >
          <div className="relative flex-center w-[24px] h-[24px]">
            <input
              type="checkbox"
              className="
                appearance-none w-[24px] h-[24px]
                border border-primary rounded-[6px]
                bg-transparent shrink-0 cursor-pointer
                checked:bg-primary
              "
              checked={
                !!filtersTypes.entities.find((ent: any) => ent.id === entity.id)
              }
              onChange={() => handleCheckboxChange(entity)}
            />
            <CheckIcon
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              height={10}
              width={15}
              color="white"
              strokeWidth={1}
            />
          </div>
          <div>{entity.title}</div>
        </label>
      ))}
    </div>
  );

  // --- РЕНДЕР подтипов (коммерческое/жилое и т.д.) ---
  const renderTree = () => {
    if (filtersTypes.path[1] === 'daily') {
      const entities = flatCategories.filter((c) =>
        c.name.startsWith('rent.daily'),
      );
      return renderEntities(entities);
    }

    if (filtersTypes.path[1] === 'longTerm') {
      const entitiesR = flatCategories.filter((c) =>
        c.name.startsWith('rent.longTerm.residental'),
      );
      const entitiesC = flatCategories.filter((c) =>
        c.name.startsWith('rent.longTerm.commercial'),
      );
      return (
        <div className="flex flex-col gap-[10px]">
          <h5 className="text-heading-5 text-heading">{t('property_type')}</h5>
          <div className="flex flex-col gap-[25px]">
            <div className="flex flex-col gap-[10px]">
              <div className="text-[#9997A8] capitalize">
                {t('residential')}
              </div>
              {renderEntities(entitiesR)}
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="text-[#9997A8] capitalize">{t('commercial')}</div>
              {renderEntities(entitiesC)}
            </div>
          </div>
        </div>
      );
    }

    if (filtersTypes.path[0] === 'sale') {
      const entitiesR = flatCategories.filter((c) =>
        c.name.startsWith('sale.residental'),
      );
      const entitiesC = flatCategories.filter((c) =>
        c.name.startsWith('sale.commercial'),
      );
      return (
        <div className="flex flex-col gap-[10px]">
          <h5 className="text-heading-5 text-heading">{t('property_type')}</h5>
          <div className="flex flex-col gap-[25px]">
            <div className="flex flex-col gap-[10px]">
              <div className="text-[#9997A8] capitalize">
                {t('residential')}
              </div>
              {renderEntities(entitiesR)}
            </div>
            <div className="flex flex-col gap-[10px]">
              <div className="text-[#9997A8] capitalize">{t('commercial')}</div>
              {renderEntities(entitiesC)}
            </div>
          </div>
        </div>
      );
    }
  };

  // --- Установка дефолтов при 1й загрузке ---
  useEffect(() => {
    if (!filtersTypes.path.length && categories.length) {
      const path =
        !type || type === 'rent'
          ? ['rent', 'longTerm', 'residental']
          : ['sale', 'residental'];

      const currentSubTree = getSubTree(categories, path);
      const finalEntities = currentSubTree?.entities || [];

      const updatedValue = {
        path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      if (JSON.stringify(filtersTypes.path) !== JSON.stringify(path)) {
        setFiltersTypes(updatedValue);
      }
    }
  }, [categories, type, filtersTypes, setFiltersTypes]);

  return (
    <div className="flex flex-col gap-[35px]">
      {/* Вкладки Rent / Buy */}
      <div className="grid grid-cols-2 border-b border-[#D9D9D9]">
        <div
          onClick={() => forceHardcodedPath('rent')}
          className={`
            flex-center w-full h-[50px] text-body-large pb-[14px] border-b-4 cursor-pointer
            ${
              filtersTypes.path[0] === 'rent'
                ? 'border-primary'
                : 'border-transparent opacity-60'
            }
          `}
        >
          {t('rent')}
        </div>
        <div
          onClick={() => forceHardcodedPath('sale')}
          className={`
            flex-center w-full h-[50px] text-body-large pb-[14px] border-b-4 cursor-pointer
            ${
              filtersTypes.path[0] === 'sale'
                ? 'border-primary'
                : 'border-transparent opacity-60'
            }
          `}
        >
          {t('buy')}
        </div>
      </div>

      {/* Выбор longTerm / daily при Rent */}
      {filtersTypes.path[0] === 'rent' && (
        <div className="flex flex-col gap-[10px]">
          <h5 className="text-heading-5 text-heading">{t('rental_period')}</h5>
          <div
            className="
              flex
              flex-wrap
              gap-2 sm:gap-[25px]
              w-full
            "
          >
            <label className="flex items-center h-[25px] gap-[7px] fade-in animation-fast">
              <div className="relative flex-center w-[24px] h-[24px]">
                <input
                  type="checkbox"
                  className="
                    appearance-none w-[24px] h-[24px]
                    border border-[#006CFB]
                    rounded-[6px] bg-transparent
                    shrink-0 cursor-pointer checked:bg-[#006CFB]
                  "
                  checked={filtersTypes.path[1] === 'longTerm'}
                  onChange={() => handleRadioChange(1, 'longTerm')}
                />
                <CheckIcon
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  height={10}
                  width={15}
                  color="white"
                  strokeWidth={1}
                />
              </div>
              <div>{capitalizeFirstLetter(t('long-term'))}</div>
            </label>

            <label className="flex items-center h-[25px] gap-[7px] fade-in animation-fast">
              <div className="relative flex-center w-[24px] h-[24px]">
                <input
                  type="checkbox"
                  className="
                    appearance-none w-[24px] h-[24px]
                    border border-[#006CFB]
                    rounded-[6px] bg-transparent
                    shrink-0 cursor-pointer checked:bg-[#006CFB]
                  "
                  checked={filtersTypes.path[1] === 'daily'}
                  onChange={() => handleRadioChange(1, 'daily')}
                />
                <CheckIcon
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                  height={10}
                  width={15}
                  color="white"
                  strokeWidth={1}
                />
              </div>
              <div>{capitalizeFirstLetter(t('daily'))}</div>
            </label>
          </div>
        </div>
      )}

      {/* Выбор типов (residential/commercial, и т.д.) */}
      {renderTree()}
    </div>
  );
};

export default FiltersTypeSelector;
