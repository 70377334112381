import React from 'react';
import { useTranslation } from 'react-i18next';

import { postMediaUploadImage } from '../../../../../api/services/media/requests';
import { patchUpdateClient } from '../../../../../api/services/user/requests';

import { capitalizeFirstLetter } from '../../../../../common/utils/helpers';

import useGlobalModalStore from '../../../../../common/stores/useGlobalModalStore';
import useGlobalErrorStore from '../../../../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../../../../common/stores/useAuthStore';

import AvatarUploader from '../../../../../common/components/AvatarUploader/AvatarUploader';

import CrossIcon from '../../../../../common/assets/icons/CrossIcon';

const AvatarModal: React.FC = () => {
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();
  const { setError } = useGlobalErrorStore();
  const { updateUserPartially } = useAuthStore();

  const handleUploadAvatar = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('avatar', file);

      const imagePayload = {
        image: file,
      };

      const imageData = await postMediaUploadImage(imagePayload);

      const avatarPayload = {
        avatar: imageData.id,
      };

      const userData = await patchUpdateClient(avatarPayload);

      updateUserPartially({
        avatar: userData.avatar,
      });

      onClose();
    } catch (error: any) {
      setError(error.response?.data?.message);
    }
  };

  return (
    <div className="w-[669px] flex flex-col gap-[15px] p-[25px]">
      <div className="flex items-center justify-between">
        <h5 className="text-heading-5 text-heading">
          {capitalizeFirstLetter(t('change_photo'))}
        </h5>
        <button onClick={onClose} className="flex justify-end">
          <CrossIcon width={18} height={18} color="#D4D4D4" strokeWidth={2} />
        </button>
      </div>
      <div>
        <AvatarUploader onUpload={handleUploadAvatar} />
      </div>
    </div>
  );
};

export default AvatarModal;
