import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getPropertySearch } from '../../../api/services/property/requests';
import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

import {
  IGetPropertySearchPayload,
  ESortField,
  ESortOrder,
} from '../../../api/services/property/types';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import PropertyList from '../../../common/components/PropertyList/PropertyList';
import Button from '../../../common/components/Button/Button';
import usePropertyCategories from '../../../common/hooks/usePropertyCategories';
import {
  RENT_LONGTERM_APARTMENT,
  RENT_LONGTERM_HOUSE,
  RENT_LONGTERM_OFFICE,
  RENT_LONGTERM_ROOM,
} from '../../../common/constants/entities';

const LIMIT = 8;

const SelectorIcon = ({
  title,
  onClick,
  isActive,
}: {
  title: string;
  onClick: () => void;
  isActive?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={`text-400 text-center pb-[38px] border-b-4 cursor-pointer ${
        isActive ? 'border-primary' : 'border-transparent'
      }`}
    >
      {title}
    </div>
  );
};

const BestOffers = () => {
  const [category, setCategory] = useState(RENT_LONGTERM_APARTMENT);

  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { flatCategories } = usePropertyCategories();
  const selectedCategory = flatCategories.find((c) => c.name === category)?.id;

  const { data: properties = { items: [], total: 0 }, refetch } = useQuery(
    ['propertiesBest', selectedCategory],
    async () => {
      setLoader(true);
      const payload: IGetPropertySearchPayload = {
        page: 1,
        limit: LIMIT,
        sortField: ESortField.CreatedAt,
        sortOrder: ESortOrder.Desc,
        ...(selectedCategory && { categories: [selectedCategory] }),
      };
      const data = await getPropertySearch(payload);
      setLoader(false);
      return data;
    },
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const handleSearchRent = () => {
    navigate(`/${lang}/search/rent?entity=${category}`);
  };

  const handleClick = (cat: string) => {
    if (cat === category) return;
    setCategory(cat);
  };

  return (
    <div className="flex flex-col items-center gap-[45px] pt-5 pb-[89px]">
      <div className="flex flex-col items-center text-center gap-4">
        {/* Заголовок: на мобилках text-lg, от sm -> text-display-3 */}
        <div className="text-display-3 text-heading fade-in">
          {t('main-section-1')}
        </div>
      </div>
      {/* Грядка категорий, занимающая полную ширину с равным делением на 4 элемента */}
      <div className="grid grid-cols-4 w-full border-b border-[#D9D9D9]">
        <SelectorIcon
          title={t('apartment')}
          onClick={() => handleClick(RENT_LONGTERM_APARTMENT)}
          isActive={category === RENT_LONGTERM_APARTMENT}
        />
        <SelectorIcon
          title={t('house')}
          onClick={() => handleClick(RENT_LONGTERM_HOUSE)}
          isActive={category === RENT_LONGTERM_HOUSE}
        />
        <SelectorIcon
          title={t('room')}
          onClick={() => handleClick(RENT_LONGTERM_ROOM)}
          isActive={category === RENT_LONGTERM_ROOM}
        />
        <SelectorIcon
          title={t('office')}
          onClick={() => handleClick(RENT_LONGTERM_OFFICE)}
          isActive={category === RENT_LONGTERM_OFFICE}
        />
      </div>
      {properties.items.length === 0 ? (
        <div className="w-full px-4 sm:w-auto sm:px-0 flex flex-col items-center gap-[17px] fade-in">
          <div>
            <EmptyListImage width={390} height={390} />
          </div>
          <div>{t('empty_list')}</div>
        </div>
      ) : (
        <PropertyList items={properties.items} refetch={refetch} isTiles />
      )}
      <div>
        <Button
          variant="primary-master"
          size="default"
          className="min-w-[318px]"
          onClick={handleSearchRent}
        >
          {t('show_more')}
        </Button>
      </div>
    </div>
  );
};

export default BestOffers;
