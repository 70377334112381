import React from 'react';

import { IMessageLast } from '../types/chat';

import ImageWithFallback from '../../../common/components/Image/ImageWithFallback';

interface ChatListButtonProps {
  name: string;
  avatar: any;
  isActive?: boolean;
  unreadMessages?: number;
  lastMessage: IMessageLast | null;
  onClick: (evt: any) => void;
  isSupport: boolean;
}

const ChatListButton = ({
  name,
  avatar,
  isActive,
  unreadMessages,
  lastMessage,
  onClick,
  isSupport,
}: ChatListButtonProps) => {
  return (
    <div
      className={`relative flex items-center gap-[10px] w-[307px] min-h-[58px] pl-[10px] pr-[50px] rounded-[10px] border hover:border-[#006CFB] cursor-pointer ${unreadMessages ? 'bg-[#006CFB0D]' : 'bg-[#F5F5F566]'} ${isActive ? 'border-[#006CFB]' : 'border-transparent'}`}
      onClick={onClick}
    >
      <div className="h-[42px] w-[42px]">
        {avatar?.url ? (
          <ImageWithFallback
            src={avatar.url}
            width={42}
            height={42}
            alt="user icon"
            className="h-[42px] min-w-[42px] rounded-[10px] object-cover"
          />
        ) : (
          <div className="flex-center rounded-[10px] w-[42px] h-[42px] bg-primary text-white">
            {name?.charAt(0)}
          </div>
        )}
      </div>
      <div className="w-full overflow-hidden">
        <div
          className={`text-100 font-bold mb-[6px] truncate ${isSupport ? 'text-accent' : 'text-heading'}`}
        >
          {name}
        </div>
        <div className="text-100 truncate">{lastMessage?.body || ''}</div>
      </div>
      {!!unreadMessages && (
        <div className="absolute top-1/2 right-[10px] transform -translate-y-1/2 flex-center w-[20px] h-[20px] bg-red-400 rounded-full text-white text-[10px]">
          {unreadMessages}
        </div>
      )}
    </div>
  );
};

export default ChatListButton;
