import { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import LogoIcon from '../assets/icons/logo-icon';

export default function MarketingBanner() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!(isIOS && isMobile)) return;

    const handleScroll = () => {
      if (window.scrollY > 75) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed top-0 left-0 w-full bg-accent caret-accent transition-opacity duration-300">
      <div className="max-w-7xl mx-auto py-1 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-1 rounded-lg bg-opacity-20">
              <LogoIcon />
            </span>
            <div className="flex flex-col">
              <p className="ml-3 font-medium text-white">
                <span className="block md:hidden">Reelty</span>
              </p>
              <p className="ml-3 text-body-smaller text-white">
                {t('marketing_banner_text')}
              </p>
            </div>
          </div>
          <div className="flex-shrink-0 ml-1">
            <a
              href="https://apps.apple.com/us/app/reelty-real-estate-rentals/id6602896278"
              className="bg-white text-accent px-3 py-1 rounded-lg text-sm font-semibold"
            >
              {t('marketing_banner_button')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
