import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const PhoneIcon: FC<ISvgProps> = ({
  height = 23,
  width = 22,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7019 20.8623L13.7128 20.87C14.6573 21.4713 15.7786 21.7325 16.8916 21.6102C18.0045 21.488 19.0425 20.9897 19.8339 20.1978L20.5215 19.5102C20.6738 19.358 20.7947 19.1772 20.8772 18.9782C20.9596 18.7792 21.0021 18.5659 21.0021 18.3505C21.0021 18.1351 20.9596 17.9218 20.8772 17.7228C20.7947 17.5238 20.6738 17.343 20.5215 17.1908L17.6216 14.2931C17.4693 14.1407 17.2885 14.0198 17.0895 13.9374C16.8906 13.8549 16.6773 13.8125 16.4619 13.8125C16.2465 13.8125 16.0332 13.8549 15.8342 13.9374C15.6352 14.0198 15.4544 14.1407 15.3021 14.2931C14.9947 14.6004 14.5777 14.7731 14.1429 14.7731C13.7082 14.7731 13.2912 14.6004 12.9838 14.2931L8.34701 9.65522C8.03964 9.34776 7.86696 8.9308 7.86696 8.49604C7.86696 8.06128 8.03964 7.64432 8.34701 7.33685C8.49937 7.18459 8.62024 7.0038 8.7027 6.8048C8.78516 6.60581 8.82761 6.39252 8.82761 6.17712C8.82761 5.96172 8.78516 5.74843 8.7027 5.54944C8.62024 5.35044 8.49937 5.16965 8.34701 5.01739L5.44823 2.1197C5.14076 1.81232 4.7238 1.63965 4.28904 1.63965C3.85428 1.63965 3.43732 1.81232 3.12986 2.1197L2.44123 2.80723C1.64941 3.5988 1.15134 4.63682 1.02931 5.74977C0.907282 6.86273 1.1686 7.98401 1.77009 8.92834L1.77665 8.93927C4.95327 13.6392 9.0014 17.6866 13.7019 20.8623V20.8623Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
