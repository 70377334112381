import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const EditSearchIcon: FC<ISvgProps> = ({
  height = 45,
  width = 45,
  color = '#152242',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_892_9465)">
        <path
          d="M34.98 1.74364L12.12 24.6036C11.2469 25.472 10.5547 26.505 10.0836 27.6427C9.61238 28.7804 9.37156 30.0003 9.37504 31.2318V33.7499C9.37504 34.2472 9.57258 34.7241 9.92421 35.0757C10.2758 35.4273 10.7528 35.6249 11.25 35.6249H13.7682C14.9996 35.6284 16.2195 35.3875 17.3572 34.9164C18.4949 34.4452 19.5279 33.753 20.3963 32.8799L43.2563 10.0199C44.352 8.92146 44.9674 7.43328 44.9674 5.88177C44.9674 4.33025 44.352 2.84207 43.2563 1.74364C42.1419 0.678442 40.6597 0.0839844 39.1182 0.0839844C37.5766 0.0839844 36.0944 0.678442 34.98 1.74364ZM40.605 7.36864L17.745 30.2286C16.6878 31.2795 15.2588 31.871 13.7682 31.8749H13.125V31.2318C13.1289 29.7411 13.7205 28.3122 14.7713 27.2549L37.6313 4.39489C38.0318 4.01231 38.5643 3.79882 39.1182 3.79882C39.672 3.79882 40.2046 4.01231 40.605 4.39489C40.9987 4.78961 41.2197 5.32431 41.2197 5.88177C41.2197 6.43922 40.9987 6.97393 40.605 7.36864Z"
          fill={color}
        />
        <path
          d="M43.125 16.8356C42.6277 16.8356 42.1508 17.0332 41.7992 17.3848C41.4475 17.7364 41.25 18.2133 41.25 18.7106V28.125H33.75C32.2582 28.125 30.8274 28.7176 29.7725 29.7725C28.7176 30.8274 28.125 32.2582 28.125 33.75V41.25H9.375C7.88316 41.25 6.45242 40.6574 5.39752 39.6025C4.34263 38.5476 3.75 37.1168 3.75 35.625V9.375C3.75 7.88316 4.34263 6.45242 5.39752 5.39752C6.45242 4.34263 7.88316 3.75 9.375 3.75H26.3288C26.826 3.75 27.3029 3.55246 27.6546 3.20083C28.0062 2.84919 28.2038 2.37228 28.2038 1.875C28.2038 1.37772 28.0062 0.900806 27.6546 0.549175C27.3029 0.197544 26.826 0 26.3288 0L9.375 0C6.88951 0.00297723 4.50667 0.991654 2.74916 2.74916C0.991654 4.50667 0.00297723 6.88951 0 9.375L0 35.625C0.00297723 38.1105 0.991654 40.4933 2.74916 42.2508C4.50667 44.0084 6.88951 44.997 9.375 45H30.6431C31.8748 45.0035 33.095 44.7627 34.2331 44.2915C35.3711 43.8204 36.4044 43.1282 37.2731 42.255L42.2531 37.2713C43.1264 36.4029 43.8188 35.37 44.2903 34.2323C44.7618 33.0946 45.003 31.8747 45 30.6431V18.7106C45 18.2133 44.8025 17.7364 44.4508 17.3848C44.0992 17.0332 43.6223 16.8356 43.125 16.8356ZM34.6219 39.6038C33.8682 40.3556 32.915 40.8761 31.875 41.1038V33.75C31.875 33.2527 32.0725 32.7758 32.4242 32.4242C32.7758 32.0725 33.2527 31.875 33.75 31.875H41.1094C40.8774 32.9128 40.3575 33.8642 39.6094 34.62L34.6219 39.6038Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_892_9465">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditSearchIcon;
