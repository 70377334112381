import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getChatRoomMessages } from '../../../api/services/messages/requests';

import { IChatParticipant, IChat, TMessages } from '../types/chat';

import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useAuthStore from '../../../common/stores/useAuthStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import useWebsocketChatStore from '../stores/useWebsocketChatStore';

import Loader from '../../../common/components/Loader/Loader';

import ChatRoomHeader from './ChatRoomHeader';
import ChatProperty from './ChatProperty';
import MessagesList from './MessagesList';

import ChatSendArrow from '../../../common/assets/icons/ChatSendArrow';

import ChatNoMessagesImage from '../../../common/assets/images/ChatNoMessagesImage';

interface ChatRoomProps {
  sendMessage: (message: string) => void;
  handleMessageRead: (messageId: string) => void;
}

const MESSAGES_PER_PAGE = 40;

const ChatRoom: React.FC<ChatRoomProps> = ({
  sendMessage,
  handleMessageRead,
}) => {
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const { lang } = useParams();
  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();
  const { user } = useAuthStore();
  const { onClose } = useGlobalModalStore();

  const {
    rooms,
    roomId,
    messages,
    loadMoreMessages,
    page,
    setPage,
    hasMore,
    setHasMore,
  } = useWebsocketChatStore();

  const shouldScrollDownRef = useRef(true);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const currentRoom = rooms.find((dataItem: IChat) => dataItem.id === roomId);
  const currentRoomOpponent = currentRoom?.participants.find(
    (participant: IChatParticipant) => participant.id !== user?.id,
  ) as IChatParticipant;
  const currentRoomProperty = currentRoom?.property;
  const currentRoomCreatedAt = currentRoom?.createdAt || 0;

  const startDate = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(currentRoomCreatedAt));

  const { data, isFetching } = useQuery(
    ['roomId', roomId, page],
    async () => {
      setLoader(true);
      const data: TMessages = await getChatRoomMessages(
        roomId,
        page,
        MESSAGES_PER_PAGE,
      );
      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      },
      enabled: !!roomId,
      keepPreviousData: true,
    },
  );

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    if (message.trim() === '') {
      setMessage('');
      return;
    }

    sendMessage(message);
    setMessage('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleScroll = async () => {
    if (
      scrollContainerRef?.current?.scrollTop === 0 &&
      hasMore &&
      !isFetching
    ) {
      scrollContainerRef.current.scrollTop += 1;
      shouldScrollDownRef.current = false;
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (data?.items && data.items.length > 0) {
      const { total, page, limit, items } = data;
      const hasMore = page * limit < total;
      loadMoreMessages(items, hasMore);
      setHasMore(hasMore);
    }
  }, [data]);

  useEffect(() => {
    if (messagesEndRef.current && shouldScrollDownRef.current) {
      messagesEndRef.current.scrollIntoView(true);
    } else {
      shouldScrollDownRef.current = true;
    }
  }, [messages]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight >= 266
          ? '266px'
          : `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.style.overflowY =
        textareaRef.current.scrollHeight > 266 ? 'auto' : 'hidden';
    }
  }, [message]);

  if (!roomId)
    return (
      <div className="flex-center bg-white h-[80vh] overflow-hidden">
        {t('select_chat_room')}
      </div>
    );

  return (
    <div className="flex flex-col gap-[15px] bg-white h-[80vh] overflow-hidden">
      <ChatRoomHeader currentRoomOpponent={currentRoomOpponent} />
      <div
        ref={scrollContainerRef}
        className="flex-1 overflow-y-auto pr-1 border-b border-[#D9D9D9]"
        onScroll={handleScroll}
        style={{ height: textareaRef.current?.scrollHeight || '50px' }}
      >
        {loader && <Loader />}
        {messages.length ? (
          <div className="grid gap-[15px]">
            {!!currentRoomProperty && (
              <>
                <div className="text-[14px] text-[#AAAAAA80] text-center">
                  {startDate}
                </div>
                <Link
                  to={`/${lang}/property/${currentRoomProperty?.id}`}
                  onClick={onClose}
                >
                  <ChatProperty currentRoomProperty={currentRoomProperty} />
                </Link>
              </>
            )}
            <MessagesList handleMessageRead={handleMessageRead} />
            <div ref={messagesEndRef} />
          </div>
        ) : (
          !loader &&
          data?.items &&
          !isFetching && (
            <div className="flex-center w-full h-full">
              <ChatNoMessagesImage />
            </div>
          )
        )}
      </div>
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={message}
          rows={1}
          className="resize-none w-full px-[16px] py-[12px] outline-none pr-[50px]"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('write_message')}
        />
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 flex-center w-[42px] h-[42px] bg-primary rounded-[10px]"
          onClick={handleSend}
        >
          <ChatSendArrow />
        </button>
      </div>
    </div>
  );
};

export default ChatRoom;
